import React, { useEffect, useState } from 'react';

const CustomTextarea = ({
  label,
  name,
  placeholder,
  onChange,
  value,
  maxLength,
  rowSize = 5,
  defaultValue,
}) => {
  return (
    <div className="form-group">
      <label className="textareaLabel">{label}</label>
      <textarea
        className="form-control"
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        maxLength={maxLength}
        rows={rowSize}
        defaultValue={defaultValue}
      ></textarea>
    </div>
  );
};

export default CustomTextarea;

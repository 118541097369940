import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Axios } from 'api/axios';
import { getSimplifiedError } from 'utils/error';
import {
  GET_ASSET_REQUEST,
  GET_ASSET_SUCCESS,
  GET_ASSET_ERROR,
  CREATE_ASSET_REQUEST,
  CREATE_ASSET_SUCCESS,
  CREATE_ASSET_ERROR,
  RESET_FLAGS_ASSET,
  DOWNLOAD_ASSET_SUCCESS,
  DOWNLOAD_ASSET_ERROR,
  DOWNLOAD_ASSET_REQUEST,
  UPLOAD_FILE_ERROR,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_REQUEST,
} from '../reducers/AssetsReducer';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

async function getAsset(payload) {
  return await Axios.get(`/assets/${payload}`);
}
function* handleGetAsset({ payload }) {
  try {
    const response = yield call(getAsset, payload);
    if (response) {
      yield put({
        type: GET_ASSET_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_ASSET,
        blockType: 'getAsset',
      });
    }
  } catch (error) {
    if (error) {
      // toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_ASSET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function downloadAsset(payload) {
  return await Axios.get(`/assets/${payload}`);
}
function* handleDownloadAsset({ payload }) {
  try {
    const response = yield call(downloadAsset, payload);
    if (response) {
      yield put({
        type: DOWNLOAD_ASSET_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_ASSET,
        blockType: 'downloadAsset',
      });
    }
  } catch (error) {
    if (error) {
      // toastError(getSimplifiedError(error));
    }
    yield put({
      type: DOWNLOAD_ASSET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createAsset(payload) {
  return await Axios.post('/assets', payload);
}
function* handleCreateAsset({ payload }) {
  try {
    const response = yield call(createAsset, payload);
    if (response) {
      yield put({
        type: CREATE_ASSET_SUCCESS,
        data: response.data,
      });
      // toastSuccess(response.message);
      yield put({
        type: RESET_FLAGS_ASSET,
        blockType: 'createAsset',
      });
      // yield put({
      //   type: GET_ASSET_REQUEST,
      // });
    }
  } catch (error) {
    if (error) {
      // toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_ASSET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

function* uploadFiles({ payload }) {
  const assetData = [];
  try {
    for (const file of payload) {
      const addNewFile = (file) => {
        const payload = {
          fileName: file.name,
          fileMime: file.type,
        };

        return Axios.post('/files/url/upload', payload);
      };

      const signUrl = ({ signedUrl, file }) => {
        return axios.put(signedUrl, file, {
          headers: { 'Content-Type': file?.type },
        });
      };

      const { data } = yield call(addNewFile, file);

      const { key, originalFilename, signedUrl, url } = data;

      yield call(signUrl, { signedUrl, file });

      const fileType = file?.type.split('/')[1];

      const assetPayload = {
        key: key,
        name: file?.name,
        type: fileType,
      };

      const getAsset = yield call(Axios.post, '/assets', assetPayload);

      const {
        data: { asset },
      } = getAsset;

      assetData.push({
        assetCode: asset.code,
        key,
        originalFilename,
        signedUrl,
        url,
        fileType: file?.type,
        fileName: file?.name,
      });
    }

    yield put({
      type: UPLOAD_FILE_SUCCESS,
      data: assetData,
    });

    yield put({
      type: RESET_FLAGS_ASSET,
      blockType: 'uploadFiles',
    });
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
      yield put({
        type: UPLOAD_FILE_ERROR,
        error: getSimplifiedError(error),
      });
    }
  }
}

export default all([
  takeLatest(GET_ASSET_REQUEST, handleGetAsset),
  takeLatest(DOWNLOAD_ASSET_REQUEST, handleDownloadAsset),
  takeLatest(CREATE_ASSET_REQUEST, handleCreateAsset),
  takeLatest(UPLOAD_FILE_REQUEST, uploadFiles),
]);

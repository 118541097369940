import Cookies from 'js-cookie';

export const isLoggedIn = () => {
  const token = Cookies.get('mrdr-token');
  return !!token;
};

export const getUserLandingPage = (user) => {
  return '/';
};

export const getFromAndTo = (date) => {
  const [{ startDate, endDate } = {}] = Array.isArray(date) ? date : [date];
  const finalStartDate = startDate || new Date();
  const finalEndDate = endDate || finalStartDate.addDays(30);
  const from = format(new Date(finalStartDate), 'yyyy-MM-dd') + ' 00:00:00';
  const to = format(new Date(finalEndDate), 'yyyy-MM-dd') + ' 23:59:59';
  return {
    from,
    to,
  };
};

import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Axios } from 'api/axios';
import { getSimplifiedError } from 'utils/error';
import {
  RESET_FLAGS_USER,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  GET_SINGLE_USER_REQUEST,
  GET_SINGLE_USER_SUCCESS,
  GET_SINGLE_USER_ERROR,
  
} from '../reducers/UsersReducer';
import { toast } from 'react-toastify';

async function getUser({ payload = {} }) {
  return await Axios.get(`/users`, { params: payload });
}
function* handleGetUser(payload) {
  try {
    const response = yield call(getUser, payload);
    if (response) {
      yield put({
        type: GET_USER_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_USER,
        blockType: 'getUser',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_USER_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSingleUser({ payload = {} }) {
  return await Axios.get(`/users/${payload}`);
}
function* handleGetSingleUser(payload) {
  try {
    const response = yield call(getSingleUser, payload);
    if (response) {
      yield put({
        type: GET_SINGLE_USER_SUCCESS,
        data: response.data?.user,
      });
      yield put({
        type: RESET_FLAGS_USER,
        blockType: 'getSingleUser',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_SINGLE_USER_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(GET_USER_REQUEST, handleGetUser),
  takeLatest(GET_SINGLE_USER_REQUEST, handleGetSingleUser),
]);

export const CREATE_PAYMENTS_REQUEST = 'CREATE_PAYMENT_REQUEST';
export const CREATE_PAYMENTS_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENTS_ERROR = 'CREATE_PAYMENT_ERROR';

export const RESET_BLOCK_PAYMENTS = 'RESET_BLOCK_PAYMENT';

export const RESET_FLAGS_PAYMENTS = 'RESET_FLAGS_PAYMENT';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  createPayment: { ...block },
};

export const PaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PAYMENTS_REQUEST:
      return { ...state, createPayment: { ...state.createPayment, loading: true } };
    case CREATE_PAYMENTS_SUCCESS:
      return {
        ...state,
        createPayment: {
          ...state.createPayment,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CREATE_PAYMENTS_ERROR:
      return {
        ...state,
        createPayment: { ...state.createPayment, loading: false, error: action.error },
      };

    //reset block with flag and data
    case RESET_BLOCK_PAYMENTS:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...initialState[action.blockType],
        },
      };

    //reset only flags(block)
    case RESET_FLAGS_PAYMENTS:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};

export const TOGGLE_REQUEST = 'TOGGLE_REQUEST';
export const TOGGLE_SUCCESS = 'TOGGLE_SUCCESS';


const initialState = {
  toggle: false,
};

export const ToggleReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SUCCESS:
      return { ...state, toggle: !state.toggle };
    default:
      return state;
  }
};

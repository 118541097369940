import { getColor } from 'utils/helper';
import { ReactComponent as RightArrow } from 'assets/icons/right-top.svg';
import { UpCircleFilled } from '@ant-design/icons';

export const columns = [
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string-bold',
    // withIcon: true,
  },
  {
    Header: 'Paid By',
    accessor: 'payer',
    type: 'string',
    // withIcon: true,
  },
  {
    Header: 'Company',
    accessor: 'company',
    type: 'string',
  },
  {
    Header: 'Budget',
    accessor: 'budget',
    type: 'string',
  },
  {
    Header: 'Payment Method',
    accessor: 'card',
    type: 'string',
    // withIcon: true,
    // link: true,
  },
  // {
  //   Header: 'Card type',
  //   accessor: 'card_type',
  //   type: 'badge',
  //   // withIcon: true,
  //   // link: true,
  // },
  {
    Header: 'Date ',
    accessor: 'date',
    type: 'string',
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
];

export const demoRequestColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    type: 'string-bold',
    // withIcon: true,
  },
  {
    Header: 'Business Name',
    accessor: 'businessName',
    type: 'string',
    // withIcon: true,
  },
  {
    Header: 'Email Address',
    accessor: 'emailAddress',
    type: 'string',
  },
  {
    Header: 'Company Website',
    accessor: 'companyWebsite',
    type: 'string',
    // withIcon: true,
    // link: true,
  },
  {
    Header: 'Phone Number',
    accessor: 'phoneNumber',
    type: 'string',
  },
  {
    Header: 'Company Size',
    accessor: 'companySize',
    type: 'string',
  },
  {
    Header: 'Date ',
    accessor: 'date',
    type: 'string',
  },
];

export const budgetsColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    type: 'string-bold',
    // withIcon: true,
  },
  {
    Header: 'Type',
    accessor: 'type',
    type: 'string',
    // withIcon: true,
  },
  {
    Header: 'Company',
    accessor: 'companyName',
    type: 'string',
    // withIcon: true,
  },
  {
    Header: 'Members',
    accessor: 'assignedUsers',
    type: 'string',
    // withIcon: true,
  },
  {
    Header: 'Created By',
    accessor: 'nameOfCreator',
    type: 'string',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string',
    // withIcon: true,
    // link: true,
  },
  {
    Header: 'Created On',
    accessor: 'creationDate',
    type: 'string',
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
];

export const tableData = [
  {
    paidto: 'Jumia Food',
    amount: '$12.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Adewale Akande',
      icon: <RightArrow />,
    },
    card: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••5549',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Office Maintenance',
      icon: <RightArrow />,
    },
    category: 'Restaurants',
    date: 'Today, 7.15 PM',
    status: { value: 'SUCCESS', color: getColor('success') },
  },
  {
    paidto: 'Netflix',
    amount: '$.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Chalanmadiya Solomon',
      icon: <RightArrow />,
    },
    card: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••5549',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Dubai Retreeat ‘22',
      icon: <RightArrow />,
    },
    category: 'Entertainment',
    date: 'Tuesday, 3.37 PM',
    status: { value: 'declined', color: getColor('declined') },
  },
  {
    paidto: 'Amplitude Inc.',
    amount: '$299.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Berenger Zantangni',
      icon: <RightArrow />,
    },
    card: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••8333',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Engineering Tools',
      icon: <RightArrow />,
    },
    category: 'SaaS / Software',
    date: 'Tuesday, 10.30 AM',
    status: { value: 'pending', color: getColor('pending') },
  },
  {
    paidto: 'Jumia Food',
    amount: '$12.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Adewale Akande',
      icon: <RightArrow />,
    },
    card: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••5549',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Office Maintenance',
      icon: <RightArrow />,
    },
    category: 'Restaurants',
    date: 'Today, 7.15 PM',
    status: { value: 'SUCCESS', color: getColor('success') },
  },
  {
    paidto: 'Netflix',
    amount: '$.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Chalanmadiya Solomon',
      icon: <RightArrow />,
    },
    card: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••5549',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Dubai Retreeat ‘22',
      icon: <RightArrow />,
    },
    category: 'Entertainment',
    date: 'Tuesday, 3.37 PM',
    status: { value: 'declined', color: getColor('declined') },
  },
  {
    paidto: 'Amplitude Inc.',
    amount: '$299.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Berenger Zantangni',
      icon: <RightArrow />,
    },
    card: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••8333',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Engineering Tools',
      icon: <RightArrow />,
    },
    category: 'SaaS / Software',
    date: 'Tuesday, 10.30 AM',
    status: { value: 'pending', color: getColor('pending') },
  },
  {
    paidto: 'Jumia Food',
    amount: '$12.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Adewale Akande',
      icon: <RightArrow />,
    },
    card: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••5549',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Office Maintenance',
      icon: <RightArrow />,
    },
    category: 'Restaurants',
    date: 'Today, 7.15 PM',
    status: { value: 'SUCCESS', color: getColor('SUCCESS') },
  },
  {
    paidto: 'Netflix',
    amount: '$.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Chalanmadiya Solomon',
      icon: <RightArrow />,
    },
    card: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••5549',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Dubai Retreeat ‘22',
      icon: <RightArrow />,
    },
    category: 'Entertainment',
    date: 'Tuesday, 3.37 PM',
    status: { value: 'declined', color: getColor('declined') },
  },
  {
    paidto: 'Amplitude Inc.',
    amount: '$299.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Berenger Zantangni',
      icon: <RightArrow />,
    },
    card: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••8333',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Engineering Tools',
      icon: <RightArrow />,
    },
    category: 'SaaS / Software',
    date: 'Tuesday, 10.30 AM',
    status: { value: 'pending', color: getColor('pending') },
  },
];

export const tableData2 = [
  {
    paidto: 'Netflix',
    amount: '$.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Chalanmadiya Solomon',
      icon: <RightArrow />,
    },
    card: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••5549',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Dubai Retreeat ‘22',
      icon: <RightArrow />,
    },
    category: 'Entertainment',
    date: 'Tuesday, 3.37 PM',
    status: { value: 'declined', color: getColor('declined') },
  },
  {
    paidto: 'Amplitude Inc.',
    amount: '$299.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Berenger Zantangni',
      icon: <RightArrow />,
    },
    card: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••8333',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Engineering Tools',
      icon: <RightArrow />,
    },
    category: 'SaaS / Software',
    date: 'Tuesday, 10.30 AM',
    status: { value: 'pending', color: getColor('pending') },
  },
  {
    paidto: 'Amplitude Inc.',
    amount: '$299.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Berenger Zantangni',
      icon: <RightArrow />,
    },
    card: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••8333',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Engineering Tools',
      icon: <RightArrow />,
    },
    category: 'SaaS / Software',
    date: 'Tuesday, 10.30 AM',
    status: { value: 'pending', color: getColor('pending') },
  },
  {
    paidto: 'Netflix',
    amount: '$.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Chalanmadiya Solomon',
      icon: <RightArrow />,
    },
    card: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••5549',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Dubai Retreeat ‘22',
      icon: <RightArrow />,
    },
    category: 'Entertainment',
    date: 'Tuesday, 3.37 PM',
    status: { value: 'declined', color: getColor('declined') },
  },
  {
    paidto: 'Amplitude Inc.',
    amount: '$299.99',
    by: {
      type: 'beneficiary',
      code: 'bnf_ifodfdofd',
      value: 'Berenger Zantangni',
      icon: <RightArrow />,
    },
    card: {
      type: 'card',
      code: 'crd_92kd203DMod02',
      value: 'Virtual ••8333',
      icon: <RightArrow />,
    },
    budget: {
      type: 'budget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Engineering Tools',
      icon: <RightArrow />,
    },
    category: 'SaaS / Software',
    date: 'Tuesday, 10.30 AM',
    status: { value: 'pending', color: getColor('pending') },
  },
];

export const columnsCards = [
  {
    Header: 'Type',
    accessor: 'type',
    type: 'badge',
  },
  {
    Header: 'Card Name',
    accessor: 'cardName',
    type: 'string-bold',
    withIcon: true,
  },
  {
    Header: 'Card Holder',
    accessor: 'cardHolder',
    type: 'string-bold',
    withIcon: true,
  },
  {
    Header: 'Card',
    accessor: 'card',
    type: 'string',
    withImg: true,
  },
  {
    Header: 'Expiry ',
    accessor: 'expiry',
    type: 'string',
  },
  {
    Header: 'Attached Budget',
    accessor: 'attachedBudget',
    type: 'string',
    withIcon: true,
    link: true,
  },
  {
    Header: 'Total Spent',
    accessor: 'totalSpent',
    type: 'string-bold',
  },
  {
    Header: 'Created On',
    accessor: 'dateCreated',
    type: 'string',
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
];
export const tableDataCards = [
  {
    type: { value: 'physical', color: getColor('physical') },
    cardHolder: {
      type: 'cardHolder',
      code: 'bnf_ifodfdofd',
      value: 'Chalanmadiya Solomon',
      icon: <RightArrow />,
    },
    card: {
      type: 'card',
      code: 'bnf_ifodfdofd',
      value: 'Mastercard ••• •••',
      icon: null,
    },
    expiry: '•• / ••',
    attachedBudget: {
      type: 'attachedBudget',
      code: 'bnf_ifodfdofd',
      value: 'Office Maintenance',
      icon: <RightArrow />,
    },
    totalSpent: '$0.00',
    dateCreated: '5 mins ago',
    status: { value: 'processing', color: getColor('processing') },
  },
  {
    type: { value: 'virtual', color: getColor('virtual') },
    cardHolder: {
      type: 'cardHolder',
      code: 'bnf_ifodfdofd',
      value: 'Adewale Akande',
      icon: <RightArrow />,
    },
    card: {
      type: 'card',
      code: 'bnf_ifodfdofd',
      value: 'VISA ••5549',
      icon: <RightArrow />,
    },
    expiry: '02 / 26',
    attachedBudget: {
      type: 'attachedBudget',
      code: 'bnf_ifodfdofd',
      value: 'Engineering Tools',
      icon: <RightArrow />,
    },
    totalSpent: '$1,254.93',
    dateCreated: '21 Mar, 12.15 PM',
    status: { value: 'active', color: getColor('active') },
  },

  {
    type: { value: 'virtual', color: getColor('virtual') },
    cardHolder: {
      type: 'cardHolder',
      code: 'bnf_ifodfdofd',
      value: 'Berenger Zantangni',
      icon: <RightArrow />,
    },
    card: {
      type: 'card',
      code: 'bnf_ifodfdofd',
      value: 'VISA ••0012',
      icon: <RightArrow />,
    },
    expiry: '09 / 25',
    attachedBudget: {
      type: 'attachedBudget',
      code: 'bnf_ifodfdofd',
      value: 'Dubai Retreeat ‘22',
      icon: <RightArrow />,
    },
    totalSpent: '$6,112.81',
    dateCreated: '29 Mar, 11.38 AM',
    status: { value: 'disabled', color: getColor('disabled') },
  },
  {
    type: { value: 'virtual', color: getColor('virtual') },
    cardHolder: {
      type: 'cardHolder',
      code: 'bnf_ifodfdofd',
      value: 'Adewale Akande',
      icon: <RightArrow />,
    },
    card: {
      type: 'card',
      code: 'bnf_ifodfdofd',
      value: 'VISA ••5549',
      icon: <RightArrow />,
    },
    expiry: '02 / 26',
    attachedBudget: {
      type: 'attachedBudget',
      code: 'bnf_ifodfdofd',
      value: 'Engineering Tools',
      icon: <RightArrow />,
    },
    totalSpent: '$164.99',
    dateCreated: '21 Mar, 12.15 PM',
    status: { value: 'active', color: getColor('active') },
  },
  {
    type: { value: 'physical', color: getColor('physical') },
    cardHolder: {
      type: 'cardHolder',
      code: 'bnf_ifodfdofd',
      value: 'Chalanmadiya Solomon',
      icon: <RightArrow />,
    },
    card: {
      type: 'card',
      code: 'bnf_ifodfdofd',
      value: 'Mastercard ••• •••',
      icon: null,
    },
    expiry: '05 / 22',
    attachedBudget: {
      type: 'attachedBudget',
      code: 'bnf_ifodfdofd',
      value: 'Office Maintenance',
      icon: <RightArrow />,
    },
    totalSpent: '$0.00',
    dateCreated: '5 mins ago',
    status: { value: 'processing', color: getColor('processing') },
  },
  {
    type: { value: 'virtual', color: getColor('virtual') },
    cardHolder: {
      type: 'cardHolder',
      code: 'bnf_ifodfdofd',
      value: 'Adewale Akande',
      icon: <RightArrow />,
    },
    card: {
      type: 'card',
      code: 'bnf_ifodfdofd',
      value: 'VISA ••5549',
      icon: <RightArrow />,
    },
    expiry: '02 / 26',
    attachedBudget: {
      type: 'attachedBudget',
      code: 'bnf_ifodfdofd',
      value: 'Engineering Tools',
      icon: <RightArrow />,
    },
    totalSpent: '$1,254.93',
    dateCreated: '21 Mar, 12.15 PM',
    status: { value: 'active', color: getColor('active') },
  },

  {
    type: { value: 'virtual', color: getColor('virtual') },
    cardHolder: {
      type: 'cardHolder',
      code: 'bnf_ifodfdofd',
      value: 'Berenger Zantangni',
      icon: <RightArrow />,
    },
    card: {
      type: 'card',
      code: 'bnf_ifodfdofd',
      value: 'VISA ••0012',
      icon: <RightArrow />,
    },
    expiry: '09 / 25',
    attachedBudget: {
      type: 'attachedBudget',
      code: 'bnf_ifodfdofd',
      value: 'Dubai Retreeat ‘22',
      icon: <RightArrow />,
    },
    totalSpent: '$6,112.81',
    dateCreated: '29 Mar, 11.38 AM',
    status: { value: 'disabled', color: getColor('disabled') },
  },
  {
    type: { value: 'virtual', color: getColor('virtual') },
    cardHolder: {
      type: 'cardHolder',
      code: 'bnf_ifodfdofd',
      value: 'Adewale Akande',
      icon: <RightArrow />,
    },
    card: {
      type: 'card',
      code: 'bnf_ifodfdofd',
      value: 'VISA ••5549',
      icon: <RightArrow />,
    },
    expiry: '02 / 26',
    attachedBudget: {
      type: 'attachedBudget',
      code: 'bnf_ifodfdofd',
      value: 'Engineering Tools',
      icon: <RightArrow />,
    },
    totalSpent: '$164.99',
    dateCreated: '21 Mar, 12.15 PM',
    status: { value: 'active', color: getColor('active') },
  },
];

export const columnsBeneficiaries = [
  {
    Header: 'Role',
    accessor: 'role',
    type: 'badge',
  },
  {
    Header: 'Name',
    accessor: 'name',
    type: 'string-bold',
  },
  {
    Header: 'Contact',
    accessor: 'contact',
    type: 'string',
  },
  {
    Header: 'Assigned Budget(s)',
    accessor: 'assignedBudget',
    type: 'string',
    withIcon: true,
    link: true,
    withNumber: true,
    numberList: true,
    isShow: true,
  },
  {
    Header: 'Total Spent',
    accessor: 'totalSpent',
    type: 'string-bold',
  },
  {
    Header: 'Date Added',
    accessor: 'dateAdded',
    type: 'string',
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
];
export const tableDataBeneficiaries = [
  {
    role: { value: 'Admin', color: getColor('admin') },
    name: 'Adewale Akande',
    contact: 'adewale@caurigram.co',
    assignedBudget: {
      type: 'assignedBudget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Office Maintenance',
      number: '+3',
      icon: <UpCircleFilled />,
    },
    totalSpent: '$11,254.93',
    dateAdded: '21 Mar, 12.15 PM',
    status: { value: 'Active', color: getColor('active') },
  },

  {
    role: { value: 'Member', color: getColor('MEMBER') },
    name: 'Chalanmadiya Solomon',
    contact: 'chalanmadiya@caurigram.co',
    assignedBudget: {
      type: 'assignedBudget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Engineering Tools',
      number: '+1',
      icon: <UpCircleFilled />,
    },
    totalSpent: '$7,192.50',
    dateAdded: '26 Mar, 7.42 PM',
    status: { value: 'Invited', color: getColor('INVITED') },
  },

  {
    role: { value: 'Member', color: getColor('MEMBER') },
    name: 'Berenger Zantangni',
    contact: 'berenger@caurigram.co',
    assignedBudget: {
      type: 'assignedBudget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Office Maintenance',
      number: '+5',
      icon: <UpCircleFilled />,
    },
    totalSpent: '$6,012.81',
    dateAdded: '29 Mar, 11.38 AM',
    status: { value: 'Active', color: getColor('active') },
  },
  {
    role: { value: 'Admin', color: getColor('admin') },
    name: 'Adewale Akande',
    contact: 'adewale@caurigram.co',
    assignedBudget: {
      type: 'assignedBudget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Office Maintenance',
      number: '+3',
      icon: <UpCircleFilled />,
    },
    totalSpent: '$11,254.93',
    dateAdded: '21 Mar, 12.15 PM',
    status: { value: 'Active', color: getColor('active') },
  },

  {
    role: { value: 'Admin', color: getColor('MEMBER') },
    name: 'Chalanmadiya Solomon',
    contact: 'chalanmadiya@caurigram.co',
    assignedBudget: {
      type: 'assignedBudget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Engineering Tools',
      number: '+1',
      icon: <UpCircleFilled />,
    },
    totalSpent: '$7,192.50',
    dateAdded: '26 Mar, 7.42 PM',
    status: { value: 'Invited', color: getColor('INVITED') },
  },

  {
    role: { value: 'Member', color: getColor('MEMBER') },
    name: 'Berenger Zantangni',
    contact: 'berenger@caurigram.co',
    assignedBudget: {
      type: 'assignedBudget',
      code: 'bdg_lf02DLpL2b29N',
      value: 'Office Maintenance',
      number: '+5',
      icon: <UpCircleFilled />,
    },
    totalSpent: '$6,012.81',
    dateAdded: '29 Mar, 11.38 AM',
    status: { value: 'Disabled', color: getColor('disabled') },
  },
];

export const companiesMockData = [
  {
    company: 'Shoprite',
    name: 'David J',
    email: 'mail@buj.com',
    phone: '09928273734',
    created_at: '2022-07-12T15:37:06.000Z',
    status: 'active',
  },
  {
    company: 'Shoprite',
    name: 'David J',
    email: 'mail@buj.com',
    phone: '09928273734',
    created_at: '2022-07-12T15:37:06.000Z',
    status: 'active',
  },
];

export const columnsCompanies = [
  {
    Header: 'Business Name',
    accessor: 'name',
    type: 'string-bold',
  },
  /*{
    Header: 'Owner Name',
    accessor: 'owner_name',
    type: 'string',
  },*/
  {
    Header: 'Size',
    accessor: 'size',
    type: 'string',
  },
  {
    Header: 'Email Address',
    accessor: 'contact_email',
    type: 'string',
  },
  {
    Header: 'Phone Number',
    accessor: 'contact_phone',
    type: 'string',
  },
  // {
  //   Header: 'Website',
  //   accessor: 'website',
  //   type: 'string',
  // },
  {
    Header: 'Signed-up On',
    accessor: 'creationDate',
    type: 'string',
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
];

export const companiesKybRequest = [
  {
    businessName: 'Chicken Republic',
    businessType: 'Sole Priopertorship',
    businessAddress: 'No 10,Adeniji',
    businessEmail: 'info@chickenrepublic.com',
    businessPhone: '09928273734',

    submissionDate: '2022-07-12T15:37:06.000Z',
    status: 'active',
    info: {
      size: 'Medium(5-10)',
      descriptio: 'We love food',
      website: 'wwww/',
      bvn: '00023456',
      idType: 'National ID',
      idNumber: '032434232',
      idCopy: 'file.pdf',
    },
  },
  {
    businessName: 'Chicken Republic',
    businessType: 'Sole Priopertorship',
    businessAddress: 'No 10,Adeniji',
    businessEmail: 'info@chickenrepublic.com',
    businessPhone: '09928273734',

    submissionDate: '2022-07-12T15:37:06.000Z',
    status: 'active',
    info: {
      size: 'Medium(5-10)',
      description: 'We love food',
      website: 'wwww/',
      bvn: '00023456',
      idType: 'National ID',
      idNumber: '032434232',
      idCopy: 'file.pdf',
    },
  },
];

export const columnsKybRequest = [
  {
    Header: 'Business Name',
    accessor: 'businessname',
    type: 'string-bold',
  },
  {
    Header: 'Business Type',
    accessor: 'bussinesstype',
    type: 'string',
  },
  {
    Header: 'Address',
    accessor: 'address',
    type: 'string',
  },
  {
    Header: 'Email Address',
    accessor: 'businessemail',
    type: 'string',
  },
  {
    Header: 'Phone Number',
    accessor: 'businessphone',
    type: 'string',
  },

  {
    Header: 'Sign-up Date',
    accessor: 'creationdate',
    type: 'string',
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
];

export const businesses = [
  {
    businessName: 'Chicken Republic',
    businessType: 'Mr Mendes',
    businessAddress: 'No 10,Adeniji',
    businessEmail: 'info@chickenrepublic.com',
    businessPhone: '09928273734',

    submissionDate: '2022-07-12T15:37:06.000Z',
    status: 'active',
    info: {
      size: 'Medium(5-10)',
      descriptio: 'We love food',
      website: 'wwww/',
      bvn: '00023456',
      idType: 'National ID',
      idNumber: '032434232',
      idCopy: 'file.pdf',
    },
  },
  {
    businessName: 'Chicken Republic',
    businessType: 'Paulo',
    businessAddress: 'No 10,Adeniji',
    businessEmail: 'info@chickenrepublic.com',
    businessPhone: '09928273734',

    submissionDate: '2022-07-12T15:37:06.000Z',
    status: 'active',
    info: {
      size: 'Medium(5-10)',
      description: 'We love food',
      website: 'wwww/',
      bvn: '00023456',
      idType: 'National ID',
      idNumber: '032434232',
      idCopy: 'file.pdf',
    },
  },
];

export const columnsBusinesses = [
  {
    Header: 'Business Name',
    accessor: 'businessname',
    type: 'string-bold',
  },
  {
    Header: 'Owner Name',
    accessor: 'ownername',
    type: 'string',
  },

  {
    Header: 'Email Address',
    accessor: 'businessemail',
    type: 'string',
  },
  {
    Header: 'Phone number',
    accessor: 'businessphone',
    type: 'string',
  },

  {
    Header: 'Creation Date',
    accessor: 'submissiondate',
    type: 'string',
  },
  // {
  //   Header: 'Status',
  //   accessor: 'status',
  //   type: 'badge',
  // },
];

export const transactionsMockData = [
  {
    amount: 135800.0,
    by: 'Adewale Akande',
    business: 'Robotics Labs',
    card_bank: 'Virtual ••5549',
    type: 'Admin',
    created_at: '2022-07-12T15:37:06.000Z',
    status: 'active',
  },
  {
    amount: 135800.0,
    by: 'Adewale Akande',
    business: 'Robotics Labs',
    card_bank: 'Virtual ••5549',
    type: 'Admin',
    created_at: '2022-07-12T15:37:06.000Z',
    status: 'active',
  },
];

export const usersMockData = [
  {
    company: 'Shoprite',
    name: 'David J',
    email: 'mail@buj.com',
    phone: '09928273734',
    role: 'Admin',
    budget: ['Food', 'Bolt'],
    card: '09928273734',
    created_at: '2022-07-12T15:37:06.000Z',
    status: 'active',
  },
  {
    company: 'Shoprite',
    name: 'David J',
    email: 'mail@buj.com',
    phone: '09928273734',
    role: 'Employee',
    budget: ['Food', 'Bolt'],
    card: '09928273734',
    created_at: '2022-07-12T15:37:06.000Z',
    status: 'active',
  },
];

export const columnsUsers = [
  // {
  //   Header: 'Role',
  //   accessor: 'role',
  //   type: 'string',
  // },
  {
    Header: 'Role',
    accessor: 'role',
    type: 'badge',
  },
  {
    Header: 'Name',
    accessor: 'name',
    type: 'string-bold',
  },
  {
    Header: 'Company',
    accessor: 'companyname',
    type: 'string-bold',
  },

  {
    Header: 'Email Address',
    accessor: 'email',
    type: 'string',
  },
  {
    Header: 'Budgets',
    accessor: 'budget',
    type: 'string',
  },
  // {
  //   Header: 'Assigned Budget',
  //   accessor: 'budget',
  //   type: 'string',
  //   withIcon: true,
  // },
  {
    Header: 'Registered On',
    accessor: 'creationdate',
    type: 'string',
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
];

export const columnsReimbursement = [
  {
    Header: 'Creation Date',
    accessor: 'creationDate',
    type: 'string',
  },
  {
    Header: 'By',
    accessor: 'by',
    type: 'string-bold',
    withIcon: true,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string-bold',
  },
  {
    Header: 'Budget',
    accessor: 'budget',
    type: 'string',
    withIcon: true,
  },
  {
    Header: 'Vendor',
    accessor: 'vendor',
    type: 'string',
  },
  {
    Header: 'Description',
    accessor: 'description',
    type: 'string',
  },
  {
    Header: 'Receipt ',
    accessor: 'receipt',
    type: 'string',
    withIcon: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
];

export const employeeColumnsReimbursement = [
  {
    Header: 'Creation Date',
    accessor: 'creationDate',
    type: 'string',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    type: 'string-bold',
  },
  {
    Header: 'Vendor',
    accessor: 'vendor',
    type: 'string',
  },
  {
    Header: 'Description',
    accessor: 'description',
    type: 'string',
  },
  {
    Header: 'Receipt ',
    accessor: 'receipt',
    type: 'string',
    withIcon: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: 'badge',
  },
  // {
  //   Header: 'By',
  //   accessor: 'by',
  //   type: 'string-bold',
  //   withIcon: true,
  // },
  // {
  //   Header: 'Budget',
  //   accessor: 'budget',
  //   type: 'string',
  //   withIcon: true,
  // },
];

export const directorsColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    type: 'string',
  },
  {
    Header: 'Email Address',
    accessor: 'email',
    type: 'string-bold',
  },
  {
    Header: 'Percentage Owned',
    accessor: 'percentage',
    type: 'string',
  },
  {
    Header: 'File',
    accessor: 'evidence',
    type: 'string',
  },
  // {
  //   Header: 'Status',
  //   accessor: 'status',
  //   type: 'badge',
  // },
  {
    Header: '',
    accessor: 'modify',
    withPopover: true,
    type: 'modify',
  },
];


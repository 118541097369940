import React from 'react';
import { AuthFormHeader, AuthFormWrapper } from 'components/Auth';
import { ReactComponent as AppLOgo } from 'assets/logos/appLogo.svg';
import LoginForm from './Form';
import '../style.less';

const PasswordRequest = () => {
  //

  return (
    <div className="page-wrapper grey">
      <div className="page-content">
        <div className="auth-header">
          <AppLOgo className="logo" />
        </div>
        <AuthFormWrapper>
          <AuthFormHeader titlle="Log in to your account" />
          <LoginForm />
        </AuthFormWrapper>
      </div>
    </div>
  );
};

export default PasswordRequest;

import {
    CurrencyType,
    ReimbursementStatusType
} from 'components/FilterModal/FilterHelper';
import NoData from 'components/NoData';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import Loading from 'components/UI/Loading';
import UsersModal from 'components/usersModal';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
// import { getUser } from 'stores/user/actions';
import { getUsers } from 'redux/actions/UsersAction';
// import { getReimbursements } from 'redux/actions/KybRequestsAction';
import { buildUsersTableData } from 'utils/helper';
import { columnsUsers, usersMockData } from 'utils/mockData';

import './style.less';

const Users = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectUsers, setSelectUsers] = useState(null);
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const payerCode = query.get('search');
  const [filterData, setFilterData] = useState([
    ...ReimbursementStatusType,
    ...CurrencyType,
  ]);
  const [isFillData, setIsFillData] = useState(false);
  const [isBuild, setIsBuild] = useState(false);
  const {
    getUser: { data: { meta = {} } = {}, data = {}, loading, success },
  } = useSelector(({ user }) => user);
  const { users = [] } = data;

  // const meta = {}
  // const { page = 1, total = 10, hasMore = true, perPage = 10 } = meta;
  const { page, total, hasMore, perPage } = meta;

  const toggleHandler = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const handleRowClick = (row) => {
    setSelectUsers(row);
  };

  useEffect(() => {
    setTableData(buildUsersTableData(users));
  }, [users]);

  const handleFilter = (query) => {
    const { status: s, currency: c, beneficiary: b } = query;
    const status = s ? s.toString() : undefined;
    const currency = c ? c.toString() : undefined;
    const user = b ? b.toString() : undefined;
  };

  const handleNextPage = () => {
    if (!hasMore) return;
    dispatch(getUsers({ page: parseInt(page, 10) + 1, ...query }));
  }
  const handlePreviousPage = () => {
    dispatch(getUsers({ page: parseInt(page, 10) - 1, ...query }));
  }

  // if (loading) return <Loading isPage color="#D28B28" />;
  return (
    <div className="Users-wrapper">
      <TopBar
        headerText={'Employees'}
        number={total}
        addOnClick={toggleHandler}
        addIcon={false}
        inputPlaceholder="Search"
        filterData={filterData}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        handleFilterApply={handleFilter}
        withOutSearch={true}
      />
      {!tableData.length ? (
        <div className="innerWrapper">
          <NoData
            headerText="No User yet"
            bodyText="Users data will be listed here"
            onClickHandler={toggleHandler}
            withButton={false}
          />
        </div>
      ) : (
        <>
          <Container>
            <Row>
              <Col xs={12}>
                <Table
                  columns={columnsUsers}
                  data={tableData}
                  pagination
                  onRowClick={handleRowClick}
                  hasMore={hasMore}
                  currentPage={page}
                  totalPage={Math.ceil(total / perPage)}
                  nextPage={handleNextPage}
                  previousPage={handlePreviousPage}
                />
              </Col>
            </Row>
          </Container>
          <UsersModal selectUsers={selectUsers} setSelectUsers={setSelectUsers} />
        </>
      )}
    </div>
  );
};

export default Users;

import React, { useEffect, useState } from 'react';
import { ArrowUpOutlined, BarChartOutlined, LineChartOutlined } from '@ant-design/icons';
import { ArrowRightOutlined } from '@ant-design/icons';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  AreaChart,
  Area,
} from 'recharts';
import './styles.scss';
import cs from 'classnames';
import TotalAmount from './TotalAmount';
import { data, noData } from '../mock';
import { getCurrency } from 'utils/helper';
import CurrencyFormat from 'react-currency-format';


const LineChartView = ({
  hideXAxis = false,
  chart = [],
  size,
  totalPercentage,
  totalSpent,
  amountType = 'Total Spent',
  widthFilter,
  chartWrapperClass,
  currency = 'USD',
  noDataPlaceholder,
}) => {
  const [activeChart, setActiveChart] = useState(1);
  const data = Object.entries(chart).map(([key, { amount, currency }]) => {
    return {
    name: key,
    total: amount/100,
    amt: amount,
  }});

  const t = () => {
    return <ArrowRightOutlined style={{marginTop: '5px', fontSize: '12px'}} />
  }
  
  return (
    <div className={cs('chartWrapper', { [chartWrapperClass]: chartWrapperClass })}>
      <div className="chart-header">
        <TotalAmount
          // type={amountType}
          type={
            <span>
              {amountType}
              <ArrowRightOutlined style={{marginLeft: '5px', fontSize: '12px'}} />
            </span>
          }
          title={
            totalSpent > 0 && (
              <>
                <CurrencyFormat prefix={getCurrency(currency)}
                                value={totalSpent/100}
                                displayType="text"
                                thousandSeparator={true}/>
              </>
            )
          }
        />
        <div className="chartActon">
          {widthFilter && widthFilter}
          {`${totalPercentage}% increase`}
        </div>
      </div>

      {activeChart === 1 && (
        <div className={cs('relative', { ['light']: hideXAxis })}>
          {!data.length && (
            <p className="center-data">
              <span>No data for this period</span>
            </p>
          )}
          <ResponsiveContainer width="100%" height={size === 'small' ? 100 : 200}>
            <AreaChart
              width={500}
              height={300}
              data={data.length > 0 ? data : noData}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#BFFF0B" stopOpacity={0.5}/>
                  <stop offset="95%" stopColor="#BFFF0B" stopOpacity={0}/>
                </linearGradient>
              </defs>

              <CartesianGrid strokeDasharray="1" />
              {!hideXAxis && <XAxis dataKey="name" />}
              <Tooltip />
              <Area type="monotone" dataKey="total" stroke="" fillOpacity={1} fill="url(#colorUv)" />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      )}

      {activeChart === 2 && (
        <div className={cs({ ['light']: hideXAxis })}>
          <ResponsiveContainer width="100%" height={size === 'small' ? 100 : 200}>
            <BarChart width={150} height={40} data={chart.length > 0 ? chart : noData}>
              {!hideXAxis && <XAxis dataKey="name" />}
              <Bar dataKey="total" fill="#D28B28" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};
export default LineChartView;

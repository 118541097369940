import { Axios } from 'api/axios';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { RESET_BLOCK_ASSET } from 'redux/reducers/AssetsReducer';
import {
  CREATE_PAYMENTS_ERROR,
  CREATE_PAYMENTS_REQUEST,
  CREATE_PAYMENTS_SUCCESS,
  RESET_FLAGS_PAYMENTS,
} from 'redux/reducers/PaymentReducer';
import { getSimplifiedError } from 'utils/error';

async function createPayment(payload) {
  return await Axios.post(`/payments`, payload);
}
function* handleCreatePayment({ payload }) {
  try {
    const response = yield call(createPayment, payload);
    if (response) {
      yield put({
        type: CREATE_PAYMENTS_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_PAYMENTS,
        blockType: 'createPayment',
      });
      yield put({
        type: RESET_BLOCK_ASSET,
        blockType: 'createAsset',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: CREATE_PAYMENTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([takeLatest(CREATE_PAYMENTS_REQUEST, handleCreatePayment)]);

import CompanyModal from 'components/CompanyModal';
import {
  CurrencyType,
  ReimbursementStatusType,
} from 'components/FilterModal/FilterHelper';
import KybModal from 'components/kybModal';
import NoData from 'components/NoData';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import Loading from 'components/UI/Loading';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getCompanies } from 'redux/actions/CompaniesAction';
// import { getReimbursements } from 'redux/actions/KybRequestsAction';
import { buildCompaniesTableData } from 'utils/helper';
import {
  columnsCompanies,
  columnsReimbursement,
  companiesMockData,
} from 'utils/mockData';
import './style.less';

const Companies = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectCompany, setSelectCompany] = useState(null);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [tableData, setTableData] = useState([]);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const payerCode = query.get('search');
  const [filterData, setFilterData] = useState([
    ...ReimbursementStatusType,
    ...CurrencyType,
  ]);
  const [isFillData, setIsFillData] = useState(false);
  const [isBuild, setIsBuild] = useState(false);
  const {
    // getCompany: { data, loading, success },
    getCompany: { data: { meta = {} } = {}, data = {}, loading, success },
  } = useSelector(({ companies }) => companies);

  // const {
  //   getReimbursement: { data: rData },
  // } = useSelector(({ reimbursement }) => reimbursement);
  const { page, total, hasMore, perPage } = meta;
  const { companies = [] } = data;

  const toggleHandler = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    dispatch(getCompanies());
  }, []);

  const handleRowClick = (row) => {
    push(`/compliance/company/${row?.companiesData?.code}`);
  };

  useEffect(() => {
    // setTableData(buildCompaniesTableData(companies));
  }, [companies]);

  const handleFilter = (query) => {
    const { status: s, currency: c, beneficiary: b, search } = query;

    dispatch(
      getCompanies({ search }),
      // getReimbursements({
      //   status,
      //   currency,
      //   user,
      // }),
    );
  };

  const handleNextPage = () => {
    if (!hasMore) return;
    dispatch(getCompanies({ page: parseInt(page, 10) + 1, ...query }));
  };
  const handlePreviousPage = () => {
    dispatch(getCompanies({ page: parseInt(page, 10) - 1, ...query }));
  };

  if (loading) return <Loading isPage color="#D28B28" />;
  return (
    <div className="companies-wrapper">
      <TopBar
        headerText={'Companies'}
        number={total}
        addOnClick={toggleHandler}
        addIcon={false}
        inputPlaceholder="Search"
        filterData={filterData}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        handleFilterApply={handleFilter}
        withOutSearch={false}
      />
      {!companies.length ? (
        <div className="innerWrapper">
          <NoData
            headerText="No Companies yet"
            bodyText="Companies data will be listed here"
            onClickHandler={toggleHandler}
            withButton={false}
          />
        </div>
      ) : (
        <>
          <Container>
            <Row>
              <Col xs={12} className="border border-dark rounded-3">
                <Table
                  columns={columnsCompanies}
                  data={buildCompaniesTableData(companies)}
                  pagination
                  onRowClick={handleRowClick}
                  hasMore={hasMore}
                  currentPage={page}
                  totalPage={Math.ceil(total / perPage)}
                  nextPage={handleNextPage}
                  previousPage={handlePreviousPage}
                />
              </Col>
            </Row>
          </Container>
          <CompanyModal
            selectCompany={selectCompany}
            setSelectCompany={setSelectCompany}
          />
        </>
      )}
    </div>
  );
};

export default Companies;

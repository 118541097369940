import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Axios } from 'api/axios';
import { getSimplifiedError } from 'utils/error';
import {
  RESET_FLAGS_TRANSACTIONS,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_ERROR,
  GET_SINGLE_TRANSACTIONS_ERROR,
  GET_SINGLE_TRANSACTIONS_SUCCESS,
  GET_SINGLE_TRANSACTIONS_REQUEST,
} from '../reducers/TransactionsReducer';
import { toast } from 'react-toastify';

async function getTransaction({ payload = {} }) {
  return await Axios.get('/transactions', { params: payload });
}
function* handleGetTransaction(payload) {
  try {
    const response = yield call(getTransaction, payload);
    if (response) {
      yield put({
        type: GET_TRANSACTIONS_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_TRANSACTIONS,
        blockType: 'getTransaction',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_TRANSACTIONS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function singleTransaction(payload) {
  return await Axios.get(`/transactions/${payload}`);
}
function* handleSingleTransaction({ payload }) {
  try {
    const response = yield call(singleTransaction, payload);
    if (response) {
      yield put({
        type: GET_SINGLE_TRANSACTIONS_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_TRANSACTIONS,
        blockType: 'getSingleTransaction',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_SINGLE_TRANSACTIONS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(GET_TRANSACTIONS_REQUEST, handleGetTransaction),
  takeLatest(GET_SINGLE_TRANSACTIONS_REQUEST, handleSingleTransaction),
]);

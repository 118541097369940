export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';  

export const GET_SINGLE_USER_REQUEST = 'GET_SINGLE_USER_REQUEST';
export const GET_SINGLE_USER_SUCCESS = 'GET_SINGLE_USER_SUCCESS';
export const GET_SINGLE_USER_ERROR = 'GET_SINGLE_USER_ERROR';      

export const RESET_BLOCK_USER = 'RESET_BLOCK_USER';

export const RESET_FLAGS_USER = 'RESET_FLAGS_USER';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getUser: { ...block },
  getSingleUser: { ...block },
};

export const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_REQUEST:
      return { ...state, getUser: { ...state.getUser, loading: true } };
    case GET_USER_SUCCESS:
      return {
        ...state,
        getUser: {
          ...state.getUser,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_USER_ERROR:
      return {
        ...state,
        getUser: {
          ...state.getUser,
          loading: false,
          error: action.error,
        },
      };

    case GET_SINGLE_USER_REQUEST:
      return { ...state, getSingleUser: { ...state.getSingleUser, loading: true } };
    case GET_SINGLE_USER_SUCCESS:
      return {
        ...state,
        getSingleUser: {
          ...state.getSingleUser,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SINGLE_USER_ERROR:
      return {
        ...state,
        getSingleUser: {
          ...state.getSingleUser,
          loading: false,
          error: action.error,
        },
      };

    
    //reset block with flag and data
    case RESET_BLOCK_USER:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...initialState[action.blockType],
        },
      };

    //reset only flags(block)
    case RESET_FLAGS_USER:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};

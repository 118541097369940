import { all } from 'redux-saga/effects';

import AuthSaga from './AuthSaga';
import BudgetsSaga from './BudgetsSaga';
import VendorsSaga from './VendorsSaga';
import AssetsSaga from './AssetsSaga';
import CardSaga from './CardSaga';
import BeneficiariesSaga from './BeneficiariesSaga';
import TransactionsSaga from './TransactionsSaga';
import ProfileSaga from './ProfileSaga';
import CompaniesSaga from './CompaniesSaga'
import KybRequestsSaga from './KybRequestsSaga';
import UsersSaga from './UsersSaga';
import ToggleSaga from './ToggleSaga';
import PaymentSaga from './PaymentSaga';
import OverviewSaga from './OverviewSaga';

export function* sagas() {
  yield all([
    AuthSaga,
    BudgetsSaga,
    VendorsSaga,
    AssetsSaga,
    CardSaga,
    BeneficiariesSaga,
    TransactionsSaga,
    ProfileSaga,
    CompaniesSaga,
    KybRequestsSaga,
    UsersSaga,
    ToggleSaga,
    PaymentSaga,
    OverviewSaga
  ]);
}

import React from 'react';
import './style.less';

const AuthFormWrapper = ({ children }) => {
  return (
    <div className="auth-form-wrapper">
      <div className="content">{children}</div>
    </div>
  );
};

const AuthFormHeader = ({ title, subtitle }) => {
  return (
    <div className="form-header">
      <h1>{title}</h1>
      {subtitle && <h4 className="text-lc">{subtitle} </h4>}
    </div>
  );
};

export { AuthFormWrapper, AuthFormHeader };

import { ArrowRightOutlined, RiseOutlined } from '@ant-design/icons';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import TransactionModal from 'components/TransactionModal';
import Loading from 'components/UI/Loading';
import OverviewStatisticTimeLine from 'components/UI/OverviewStatisticTimeLine';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCompanyStats } from 'redux/actions/CompaniesAction';
import { getCharts, getStatistics } from 'redux/actions/OverviewAction';
import { getTransaction } from 'redux/actions/TransactionsAction';
import { buildTransactionTableData } from 'utils/helper';
import { columns } from 'utils/mockData';
import { ReactComponent as RightArrow } from '../../../assets/icons/right-top.svg';
import LineChartView from './components/LineChartView';
import SubHeader from './components/SubHeader';

const TransactionsAction = () => {
  const history = useHistory();
  const onTransactionHandler = () => {
    history.push('/transactions');
  };
  return (
    <div className="d-flex align-items-center text-white">
      <div className="d-flex align-items-center text-white cursor px-3 borderRight">
        Export <RightArrow className="ps-1" />
        <RiseOutlined className="ps-1" />
      </div>
      <div
        onClick={onTransactionHandler}
        className="d-flex align-items-center text-white cursor px-3"
      >
        All transactions <ArrowRightOutlined className="ps-1" />
      </div>
    </div>
  );
};

const Overview = ({ company }) => {
  const dispatch = useDispatch();
  const defaultCurrency = company.defaultCurrency || 'NGN';

  const {
    selectedDateRange,
    getStatistic: { data: statistic, loading },
    getChart: { data: chart },
  } = useSelector(({ overview }) => overview);

  const {
    getTransaction: { data: { meta = {} } = {}, data = [] },
  } = useSelector(({ transaction }) => transaction);

  const { transactions = [] } = data;

  const [stats, setStats] = useState({ transactions: [] });
  const [selectTransaction, setSelectTransaction] = useState(null);

  useEffect(() => {
    dispatch(getStatistics());
    dispatch(getCharts());
    dispatch(getTransaction());
  }, []);

  const [chartData, setChartData] = useState({
    chart: [],
    totalSpent: null,
    totalPercentage: null,
  });
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleRange = (val) => {};
  useEffect(() => {
    handleRange(Math.floor(Math.random() * 3) + 1);
  }, [selectedDateRange]);

  useEffect(() => {
    if (company.code) dispatch(getCompanyStats({ id: company.code }));
  }, [company]);

  useEffect(() => {
    if (chart) {
      setStats(chart);
    }
  }, [chart]);

  // useEffect(() => {
  //   setPayModal(toggle);
  // }, [toggle]);

  // const payToggleHandler = () => {
  //   dispatch(toggleAction());
  // };

  if (loading) return <Loading isPage color="#D28B28" />;

  const handleRefresh = () => {
    dispatch(getCompanyStats({ id: company.code }));
  };

  return (
    <div className="page-wrapper pb-5">
      <div className="w-100">
        <SubHeader handleRefresh={handleRefresh} />

        <div className="overview-statistics">
          <Row>
            <Col md={3}>
              <OverviewStatisticTimeLine
                title="Employees Registered"
                number={statistic?.sign_ups}
              />
            </Col>
            <Col md={3}>
              <OverviewStatisticTimeLine
                title="Companies Registered"
                number={statistic?.companyCount}
              />
            </Col>
            <Col md={3}>
              <OverviewStatisticTimeLine
                title="Pending Review"
                number={statistic?.companyInReview}
              />
            </Col>
            <Col md={3}>
              <OverviewStatisticTimeLine
                title="Onboarded"
                number={statistic?.companyActivated}
                completed={true}
              />
            </Col>
          </Row>
        </div>

        <LineChartView
          chart={stats}
          totalSpent={chartData.totalSpent}
          currency={defaultCurrency}
          totalPercentage={chartData.totalPercentage}
          amountType="Transactions per day"
          // noDataPlaceholder="A trend of your beneficiaries spend within a period"
        />

        <div className="recent-transactions-wrapper border border-dark rounded-3">
          <TopBar
            headerText={
              <span className="d-block position-relative mb-4 text-color">
                Recent Transactions
              </span>
            }
            isRightBar={<TransactionsAction />}
            colClass="pt-0"
          />
          <Table
            columns={columns}
            data={buildTransactionTableData(transactions, true)}
            previous={false}
            next={1}
            // onRowClick={handleRowClick}
            withTopBorder
            emptyPlaceholder="No transactions yet"
          />

          <TransactionModal
            selectTransaction={selectTransaction}
            setSelectTransaction={setSelectTransaction}
          />
        </div>
      </div>
    </div>
  );
};
export default Overview;

import { CloseOutlined } from '@ant-design/icons';
import Modal from 'components/UI/Modal';
import { Col, Form, Row, Stack } from 'react-bootstrap';
import BadgeType from '../UI/Table/BadgeType';
import UsersInfo from './UsersInfo';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getSingleUser } from 'redux/actions/UsersAction';
import { capitalizeFirstLetter } from 'utils/helper';
import CustomInput from 'components/UI/CustomInput';
import CustomButton from 'components/UI/CustomButton';
import CustomSelect from 'components/UI/CustomSelect';
import MultipleSelect from 'components/UI/MultipleSelect';

const UsersModal = ({ selectUsers, setSelectUsers }) => {
  const dispatch = useDispatch();
  const [editUser, setEditUser] = useState(false);
  const {
    getSingleUser: { data: userData, loading },
  } = useSelector(({ user }) => user);

  useEffect(() => {
    if (!selectUsers) return;
    dispatch(getSingleUser(selectUsers.userData.code))
  }, []);

  useEffect(() => {
    if (userData) {
      setSelectUsers({ ...selectUsers, ...userData });
    }
  }, [userData]);
  
  const handleClose = () => {
    setSelectUsers(null);
  };

  const edit = () => {
    setEditUser(true);
  }

  const [userEditData, setUserData] = useState({
    phone: '',
    budget: '',
    status: '',
  });

  const handleChange = ({ target: { name, value } }) => {
    setUserData({ ...userEditData, [name]: value });
  };

  const handleSubmitEdit = () => {
    setEditUser(false);
  }

  const handleCancel = () => {
    setEditUser(false);
  }
  // const handleClick = () => {
  //   history.push(`/budgets/${selectUsers?.budget?.code}/overview`);
  // };
  if (!selectUsers) return <div />;
  return (
    <Modal show={selectUsers} onClose={handleClose}>
      <div className="content">
        <div className="card-modal-header">
          <div className="d-flex align-items-center cursor" onClick={handleClose}>
            <CloseOutlined />
            <span className="ps-1">Close</span>
          </div>
        </div>
        <div className="card-modal-body">
          {
            !editUser &&
            <div className="information-wrapper">
              <Row>
                <span className='edit' onClick={edit}>Edit</span>
              </Row>
              <BadgeType value={selectUsers.status} />
              <div className="d-flex align-items-center justify-content-between">
                <h2 className="card-title">{selectUsers?.name}</h2>
              </div>
              <span style={{color: '#BFC6B9', fontSize: '14px', fontWeight: '400' }}>
                {selectUsers?.creationdate}
              </span>
              <hr/>
              <UsersInfo title="Company" value={selectUsers?.company ? selectUsers?.company : 'N/A'} />
              <Row>
                <Col md={5}>
                  <UsersInfo title="Phone number" value={selectUsers?.phoneNumber ? selectUsers?.phoneNumber : 'N/A'} />
                </Col>
                <Col md={7}>
                  <UsersInfo title="Email address" value={selectUsers?.email ? selectUsers?.email : 'N/A'} />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <UsersInfo title="Date of Birth" value={selectUsers?.dob || 'N/A'} />
                </Col>
                <Col md={6}>
                  <UsersInfo title="Role" value={selectUsers?.role?.value || selectUsers.role} />
                </Col>
              </Row>
              <UsersInfo title="Budget Assigned" value={selectUsers?.budgetCount || selectUsers?.budget} />
              <UsersInfo title="Cards" value={selectUsers?.budgets ? selectUsers?.budgets : 'N/A'} />
              
            </div>
          }
            
          {/* Edit */}
          {
            editUser &&
            <div className="information-wrapper">
              <Row>
                <Form>
                  <Row className="mb-3">
                    <CustomInput
                      error="enter valid phone number"
                      label="Phone number"
                      placeholder="Enter phone number"
                      type="text"
                      name="phone"
                      onChange={handleChange}
                      value={userEditData.phone}
                      required
                    />
                  </Row>
                  <Row className="mb-3">
                    <MultipleSelect
                      placeholder="Select Budget assigned"
                      label="Budget Assigned"
                      // isDisabled={
                      //   !form.name ||
                      //   !form.lastName ||
                      //   Object.keys(selectedBudget).length > 0
                      // }
                      name="budget"
                      // defaultValue={selectedBudget?.value ? selectedBudget : null}
                      options={[
                        { value: 'Budget 1', label: 'Budget 1' },
                        { value: 'Budget 2', label: 'Budget 2' },
                        { value: 'Budget 3', label: 'Budget 3' },
                      ]}
                      // onChange={(val) => setForm({ ...form, budget: val })}
                    />
                  </Row>
                  <Row className="mb-3">
                    <CustomSelect
                      label="Status"
                      name="status"
                      // onChange={(nationality) =>
                      //   setBankInfo({ ...bankInfo, nationality: nationality })
                      // }
                      // value={bankInfo.nationality}
                      options={[
                        { value: 'Active', label: 'Active' },
                        { value: 'Inactive', label: 'Inactive' }
                      ]}
                    />
                  </Row>
                  <div style={{marginTop: '20px'}}>
                    <CustomButton fullWidth className="mt-3" onClick={handleSubmitEdit}>
                      Submit
                    </CustomButton>
                  </div>
                  <div className="ms-auto">
                    <CustomButton withoutBg fullWidth className="mt-3" onClick={handleCancel}>
                      Cancel
                    </CustomButton>
                  </div>
                </Form>
              </Row>
            </div>
          }
          {/* Edit */}
          
        </div>
      </div>
    </Modal>
  );
};

export default UsersModal;

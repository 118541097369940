import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Axios } from 'api/axios';
import { getSimplifiedError } from 'utils/error';
import {
  RESET_FLAGS_STATISTICS,
  GET_STATISTICS_REQUEST,
  GET_STATISTICS_SUCCESS,
  GET_STATISTICS_ERROR,

  GET_CHART_REQUEST,
  GET_CHART_SUCCESS,
  GET_CHART_ERROR,

} from '../reducers/OverviewReducer';
import { toast } from 'react-toastify';

async function getStatistics({ payload = {} }) {
  return await Axios.get(`/admin/stats`, { params: payload });
}
function* handleGetStatistics(payload) {
  try {
    const response = yield call(getStatistics, payload);
    if (response) {
      yield put({
        type: GET_STATISTICS_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_STATISTICS,
        blockType: 'getStatistics',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_STATISTICS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getCharts({ payload = {} }) {
  return await Axios.get(`/admin/analytics`, { params: payload });
}
function* handleGetCharts(payload) {
  try {
    const response = yield call(getCharts, payload);
    if (response) {
      yield put({
        type: GET_CHART_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_STATISTICS,
        blockType: 'getCharts',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_CHART_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(GET_STATISTICS_REQUEST, handleGetStatistics),
  takeLatest(GET_CHART_REQUEST, handleGetCharts),
]);

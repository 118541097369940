import React from 'react';
import AuthForm from 'components/Auth/Form';
import { useLocation } from 'react-router-dom';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';

const PasswordRequestForm = () => {
  const [errorMsg, setErrorMsg] = React.useState();
  const location = useLocation();
  const initialValues = {
    email: '',
  };

  const onSuccess = () => {
    const { from } = location.state;
  };

  const onError = (message) => {
    setErrorMsg(message);
  };

  const onFinish = async (values) => {
    // dispatch(loginAction(values, onSuccess, onError));
    onSuccess();
  };

  return (
    <>
      <AuthForm
        onFinish={onFinish}
        initialValues={initialValues}
        errorMsg={errorMsg}
        submitText="Reset password"
        hidePassword
      />
      <h4 className="secondary-action">
        <ArrowLeft />
        {/* <Link to={Pages.LOGIN_LINK}> Return to Log in </Link>{' '} */}
      </h4>
      <h4 className="secondary-action">
        <ArrowLeft />
        {/* <Link to={Pages.VERIFICATION_LINK}> Verification </Link>{' '} */}
      </h4>
    </>
  );
};

export default PasswordRequestForm;

import React, { useEffect } from 'react';
import cs from 'classnames';
import { Col, Form } from 'react-bootstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

const CustomSelect = ({
  label = '',
  error = '',
  closeMenuOnSelect = true,
  isMulti = true,
  md = 12,
  marginTop,
  wrapperClass = '',
  defaultValue,
  createable = false,
  ...res
}) => {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      //   border: state.isFocused ? '#000' : base.color,
      // This line disable the blue border
      minHeight: '45px',
      padding: '7px 4px',
      borderRadius: '4px',
      background: '#242628',
      borderColor: state.isFocused
        ? '#606060'
        : error
        ? 'rgb(220 38 38 /100)'
        : '#606060',
      ring: state.isFocused ? '#000' : '#000',
      color: state.isSelected ? '#ffffff' : '#ffffff',
      boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(208, 213, 221, .7)' : 0,
      '&:hover': {
        borderColor: state.isFocused ? '#000' : null,
        ring: state.isFocused ? '#000' : null,
      },

      '*': {
        boxShadow: 'none !important',
        borderColor: '#000',
        color: '#fff !important',
      },
    }),
    option: (base, state) => ({
      ...base,
      color: state.isSelected ? '#FFF' : '#6B7280',
      backgroundColor: state.isSelected ? '#606060' : base.color,
      borderBottom: '1px solid rgba(0, 0, 0, 0.125)',

      '&:hover': {
        color: '#6B7280',
        backgroundColor: '#F1F5F9',
      },
    }),

    indicatorSeparator: () => ({ display: 'none' }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        textAlign: 'start',
      };
    },
  };

  return (
    <Form.Group
      as={Col}
      md={md}
      className={cs('groupWrapper', { [wrapperClass]: wrapperClass })}
    >
      {label && <Form.Label>{label}</Form.Label>}

      {createable ? (
        <CreatableSelect
          isClearable
          defaultValue={defaultValue}
          closeMenuOnSelect={closeMenuOnSelect}
          styles={customStyles}
          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
          {...res}
        />
      ) : (
        <Select
          defaultValue={defaultValue}
          closeMenuOnSelect={closeMenuOnSelect}
          styles={customStyles}
          {...res}
        />
      )}
    </Form.Group>
  );
};
export default CustomSelect;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CloseOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import Modal from 'components/UI/Modal';
import TransactionInfo from './TransactionInfo';
import { Col, Row } from 'react-bootstrap';
import FileUpload from 'components/UI/FileUpload';
import CustomButton from 'components/UI/CustomButton';
import BadgeType from '../UI/Table/BadgeType';
import { useHistory } from 'react-router-dom';
import { getSingleTransaction } from 'redux/actions/TransactionsAction';
import { getFormattedDate } from 'utils/helper';

const BudgetModal = ({ selectBudget, setSelectBudget }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [transaction, setTransaction] = useState([])

  const {
    getSingleTransaction: { data: transactionData, loading },
  } = useSelector(({ transaction }) => transaction);

  useEffect(() => {
    if(transactionData) {
      setTransaction(transactionData.data)
    }

  }, [transactionData])



  const handleClose = () => {
    setSelectBudget(null);
  };
  const handleClick = () => {
    // history.push(`/budgets/${selectBudget?.budget?.code}/overview`);
  };
  if (!selectBudget) return <div />;
  return (
    <Modal show={selectBudget} onClose={handleClose} >
      <div className="content">
        <div className="card-modal-header">
          <div className="d-flex align-items-center cursor" onClick={handleClose}>
            <CloseOutlined />
            <span className="ps-1">Close</span>
          </div>
        </div>
        <div className="card-modal-body">
          <div className="information-wrapper">
            <div className="d-flex align-items-center justify-content-between">
              <h2 className="card-title">{selectBudget.name}</h2>
              <BadgeType value={selectBudget.status} />
            </div>
            <TransactionInfo title="Company Name" value={selectBudget?.companyName} />
            <TransactionInfo title="How many members" value={selectBudget?.assignedUsers} />
            <TransactionInfo title="Created By" value={selectBudget?.nameOfCreator} />
            <TransactionInfo title="Creation Date" value={getFormattedDate(selectBudget?.budgetData?.created_at)} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BudgetModal;

import React, { useEffect, useState } from 'react';
import VitrineSidebar from 'components/Auth/LeftVitrine';
import createVitrine from 'assets/images/create-vitrine.png';
import { AuthFormHeader, AuthFormWrapper } from 'components/Auth';
import { ReactComponent as SmileLogo } from 'assets/icons/smile.svg';
import '../../auth/style.less';
import { Form, Row } from 'react-bootstrap';
import CustomInput from 'components/UI/CustomInput';
import CustomButton from 'components/UI/CustomButton';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetBlockAuth, update_user_profile } from 'redux/actions/AuthAction';
import { parsePhoneNumber } from 'libphonenumber-js';
import { toast } from 'react-toastify';

const EditUser = () => {
  const dispatch = useDispatch();
  const {
    user: { data = {} },
    updatedUser: { success },
  } = useSelector(({ auth }) => auth);
  const [countryCode, setCountryCode] = useState('234');
  const leftImage = (
    <>
      <img className="vitrine-image w-75" src={createVitrine} />
    </>
  );
  useEffect(() => {
    if (success) {
      history.push('/');
    }
  }, [success]);

  useEffect(() => {
    dispatch(resetBlockAuth({ blockType: 'otp' }));
  }, []);

  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    number: '',
    company: '',
  });
  const history = useHistory();
  const handleChange = ({ target: { name, value } }) => {
    setUser({ ...user, [name]: value });
  };
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (countryCode && user.number) {
      try {
        parsePhoneNumber(user.number, {
          defaultCallingCode: countryCode.replace('+', ''),
        });
      } catch (e) {
        return toast('Please enter a valid phone number', { type: 'error' });
      }
    }
    if (form.checkValidity() === false) {
      return;
    }
    let payloadData = {
      firstName: user.first_name,
      lastName: user.last_name,
      email: data.user.email,
      phoneNumber: {
        countryCode: countryCode.substring(1),
        localFormat: user.number,
      },
    };
    if (!data.user.company) {
      payloadData.company = user.company;
    }
    dispatch(update_user_profile(payloadData));
    return false;
  };
  const getCountryCode = (data) => {
    setCountryCode(data);
  };

  return (
    <div className="page-wrapper">
      <div className="vitrinesb-col">
        <VitrineSidebar
          message="Few clicks away from starting your journey of smart spending."
          imageComponent={leftImage}
          widthLink
        />
      </div>

      <div className="page-content with-vit">
        <AuthFormWrapper>
          <SmileLogo className="verify-logo" />
          <AuthFormHeader
            titlle="Tell us about yourself"
            subtitle="One last step to complete your registration"
          />
          <Form noValidate validated={false} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <CustomInput
                label="First name"
                error="enter valid first name"
                placeholder="Enter name"
                value={user.first_name}
                required
                name="first_name"
                onChange={handleChange}
                md={6}
              />
              <CustomInput
                label="Last name"
                error="enter valid last name"
                placeholder="Enter name"
                value={user.last_name}
                required
                name="last_name"
                onChange={handleChange}
                md={6}
              />
            </Row>
            <Row className="mb-3">
              <CustomInput
                label="Mobile number"
                maxLength="10"
                error="enter valid number"
                placeholder="Enter number"
                value={user.number}
                required
                type="number"
                isNumber
                name="number"
                onChange={handleChange}
                getTypeVal={getCountryCode}
              />
            </Row>
            <Row className="mb-3">
              <CustomInput
                label="Where do you work"
                error="enter valid company"
                placeholder="Enter Company"
                value={user.company}
                required
                name="company"
                onChange={handleChange}
              />
            </Row>
            <CustomButton type="submit">Get started</CustomButton>
          </Form>
        </AuthFormWrapper>
      </div>
    </div>
  );
};

export default EditUser;

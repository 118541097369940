import cs from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { downloadAsset } from 'redux/actions/AssetsAction';
import CustomPopover from '../Popover';
import './styles.scss';
import { truncateText } from 'utils/helper';
import { PieChartOutlined } from '@ant-design/icons';
import { memo } from 'react';
import BadgeType from './BadgeType';

const StringType = ({
  value,
  fontBold,
  withIcon,
  onClick = null,
  withNumber,
  withImg,
  link,
  type,
  dottedBadge = false,
  setSelectedId,
  selectedId,
  index,
  withSubString,
  isDropDown,
  withColor,
  columnName,
  truncate = true,
  truncateLength,
  stringType = true,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    downloadAsset: { loading },
  } = useSelector(({ assets }) => assets);

  if (!value) return <div />;
  const handleClick = (event) => {
    event?.preventDefault();
    if (index === selectedId) return;
    if (link && value.link && value.type === 'invoice') {
      window.location.replace(value.link);
    }
    if (link && value.link) {
      history.push(value.link);
    }
    if (value.type === 'receipt') {
      if (value?.code) return dispatch(downloadAsset(value?.code));
    }
    if (value.value === '(Add to a budget)') {
      onClick();
    }
  };
  const handleTogglePopover = (event) => {
    event?.preventDefault();
    event?.stopPropagation();
    if (value.value !== '(Add to a budget)' && isDropDown) {
      if (selectedId === index) return setSelectedId('');
      setSelectedId(index);
      // return setIsPopoverOpen(selectedId);
    } else if (value.value === '(Add to a budget)') {
      return onClick();
    } else if (value.type === 'receipt') {
      if (value?.code) return dispatch(downloadAsset(value?.code));
    } else {
      if (value.link) history.push(value.link);
    }
  };

  const Actions = ({ list }) => {
    return (
      <div
        className="actions-dialog"
        style={{ minWidth: columnName === 'statusList' ? '120px' : undefined }}
      >
        {list?.map((l, i) => {
          return l.type === 'badge' ? (
            <div className="p-2 border-bottom">
              <BadgeType
                value={{
                  value: l.name,
                  count: l.count ? <span className="me-1">{l.count}</span> : null,
                }}
              />
            </div>
          ) : (
            <div
              key={i}
              className="actionLink"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                if (l.link) history.push(l.link);
              }}
            >
              {l?.code && l?.code?.startsWith('bdg_') && <PieChartOutlined />}
              {l.name}
            </div>
          );
        })}
      </div>
    );
  };

  if (value.component) return value.component;

  if (withIcon || withImg) {
    const iconRender = ['budgets', 'status'].includes(type)
      ? +value.number >= 1 || value.value === '(Add to a budget)'
      : !!value.icon;
    return (
      <>
        <div
          className={cs(
            { ['fw-light-bold']: fontBold },
            'align-items-center d-flex table-font-size',
          )}
        >
          {!isDropDown && value.value && iconRender && (
            <span className="ps-1 d-flex align-items-center silver-color mr-3">
              {index === selectedId ? value.downIcon : value.icon}
            </span>
          )}
          {!isDropDown && withImg && value.img && (
            <span className="pe-2 table-card" onClick={handleClick}>
              {value.img}
            </span>
          )}
          {value.value && truncateLength ? (
            <span className={cs({ 'dotted-badge': dottedBadge })} onClick={handleClick}>
              {truncateText(value.value, 0, truncateLength)}
            </span>
          ) : null}
          {value.value && !truncateLength ? (
            <span className={cs({ 'dotted-badge': dottedBadge })} onClick={handleClick}>
              {columnName === 'statusList' ? (
                <BadgeType value={{ value: value.value }} />
              ) : (
                truncateText(value.value, 0, 25)
              )}
            </span>
          ) : (
            '-'
          )}

          {isDropDown && (
            <CustomPopover
              content={<Actions list={value && value?.numberList} />}
              showPopover={index === selectedId}
              clickOutside={handleTogglePopover}
            >
              <div
                onClick={handleTogglePopover}
                className={cs('cursor d-flex align-items-center bg-transparent', {
                  ['disabled']: loading,
                })}
              >
                {withNumber && <span className="ps-2 silver-color">{value.number}</span>}
                {iconRender && isDropDown && (
                  <span
                    className="ps-1 d-flex align-items-center silver-color"
                    onClick={value.value === '(Add to a budget)' ? onClick : null}
                  >
                    {index === selectedId ? value.downIcon : value.icon}
                  </span>
                )}
              </div>
            </CustomPopover>
          )}
        </div>
      </>
    );
  }

  const truncateTextValue = (value, isTruncate = true) => {
    return isTruncate ? truncateText(value, 0, truncateLength ?? 25) : value;
  };

  const isEvidence = columnName === 'evidence';

  return (
    <span
      className={cs(
        { ['string-type-font']: stringType },
        { ['fw-light-bold']: fontBold },
        { ['line-height-0']: isEvidence },
      )}
    >
      <div>
        {withColor ? (
          <span
            style={{
              color: `${value?.color || '#0000'}`,
            }}
          >
            {value.sign && value.sign} {truncateTextValue(value.value, truncate)}
          </span>
        ) : columnName === 'permissions' ? (
          value
        ) : (
          truncateTextValue(value, truncate)
        )}

        {withSubString && (
          <p className="with-substring">{truncateTextValue(withSubString, truncate)}</p>
        )}
      </div>
    </span>
  );
};
export default memo(StringType);

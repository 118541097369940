import { 
  SELECT_DATE_RANGE, 
  GET_STATISTICS_REQUEST,
  GET_CHART_REQUEST
} from '../reducers/OverviewReducer';

export const selectDateRnage = (payload) => ({
  type: SELECT_DATE_RANGE,
  payload,
});

export const getStatistics = (payload) => ({
  type: GET_STATISTICS_REQUEST,
  payload,
});

export const getCharts = (payload) => ({
  type: GET_CHART_REQUEST,
  payload,
});
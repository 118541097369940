import React from 'react';
import { ReactComponent as Arrow } from 'assets/icons/up-arrow.svg';
import { Accordion, Card } from 'react-bootstrap';
import ContextAwareToggle from './ContextAwareToggle';
import cs from 'classnames';
import './styles.scss';

const AccordionCard = ({
  children,
  eventKey,
  handleClick = () => { return null;},
  activeKey,
  title = '',
  isLarge = false,
  isFilter = false,
  contextClass = '',
  wrapperClass = '',
}) => {
  return (
    <div className={cs('accordion-wrapper', { [wrapperClass]: wrapperClass })}>
      <ContextAwareToggle
        eventKey={eventKey}
        handleClick={handleClick}
        className={contextClass}
      >
        <div className={cs('accordion-header cursor', { ['isFilter']: isFilter })}>
          <span className={cs({ ['large-title']: isLarge })}>{title}</span>
          <Arrow className={cs({ ['rotate']: +eventKey !== +activeKey })} />
        </div>
      </ContextAwareToggle>
      <Accordion.Collapse eventKey={eventKey}>
        <div>{children}</div>
      </Accordion.Collapse>
    </div>
  );
};
export default AccordionCard;

import React, { useEffect, useState } from 'react';
import { AuthFormHeader, AuthFormWrapper } from 'components/Auth';
import { ReactComponent as AppLogo } from 'assets/logos/appLogo.svg';
import { Form, Row } from 'react-bootstrap';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import '../style.less';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { newPassword } from 'redux/actions/AuthAction';
import { toast } from 'react-toastify';

const ResetPassword = () => {
  const history = useHistory();
  const { hashVal } = useParams();
  const { newPassword: statusNewPassword } = useSelector(({ auth }) => auth);

  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [authData, setAuthData] = React.useState({
    password: '',
    conPassword: '',
  });

  const handleChange = ({ target: { name, value } }) => {
    setAuthData({ ...authData, [name]: value });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false || authData.password !== authData.conPassword) {
      toast('Password not match', { type: 'error' });
      return;
    }
    dispatch(
      newPassword({
        hash: hashVal,
        confirmPassword: authData.conPassword,
        newPassword: authData.password,
      }),
    );
    // history.push('/login');
    setValidated(true);
  };

  useEffect(() => {
    if (statusNewPassword?.error) {
      toast(statusNewPassword?.error, { type: 'error' });
      history.push('/reset-request');
    }
    if (statusNewPassword?.success) {
      history.push('/login');
    }
  }, [statusNewPassword]);
  return (
    <div className="page-wrapper grey">
      <div className="page-content">
        <div className="auth-header">
          <AppLogo className="logo" />
        </div>
        <div className="page-content with-vit">
          <AuthFormWrapper>
            <div className="text-center">
              <AuthFormHeader titlle="Reset your password" />
              <div className="subHeader">
                {'Choose a new password for your Bujeti account'}
              </div>
            </div>
            <Form
              className="mt-3"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <Row className="mb-3">
                <CustomInput
                  label="Your new password"
                  error="enter valid password"
                  placeholder="Enter password"
                  type="password"
                  name="password"
                  onChange={handleChange}
                  value={authData.password}
                  required
                />
              </Row>
              <Row className="mb-3">
                <CustomInput
                  label="Confirm new password"
                  error="Confirm password does not match"
                  placeholder="Enter password"
                  type="password"
                  name="conPassword"
                  onChange={handleChange}
                  value={authData.conPassword}
                  required
                />
              </Row>
              <CustomButton className={'buttonStyle'} type="submit">
                Reset password
              </CustomButton>
            </Form>
          </AuthFormWrapper>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

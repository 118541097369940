export const CREATE_BENEFICIARIES_REQUEST = 'CREATE_BENEFICIARIES_REQUEST';
export const CREATE_BENEFICIARIES_SUCCESS = 'CREATE_BENEFICIARIES_SUCCESS';
export const CREATE_BENEFICIARIES_ERROR = 'CREATE_BENEFICIARIES_ERROR';

export const GET_BENEFICIARIES_REQUEST = 'GET_BENEFICIARIES_REQUEST';
export const GET_BENEFICIARIES_SUCCESS = 'GET_BENEFICIARIES_SUCCESS';
export const GET_BENEFICIARIES_ERROR = 'GET_BENEFICIARIES_ERROR';

export const UPDATE_BENEFICIARIES_REQUEST = 'UPDATE_BENEFICIARIES_REQUEST';
export const UPDATE_BENEFICIARIES_SUCCESS = 'UPDATE_BENEFICIARIES_SUCCESS';
export const UPDATE_BENEFICIARIES_ERROR = 'UPDATE_BENEFICIARIES_ERROR';

export const SEARCH_RECIPIENTS_REQUEST = 'SEARCH_RECIPIENTS_REQUEST';
export const SEARCH_RECIPIENTS_SUCCESS = 'SEARCH_RECIPIENTS_SUCCESS';
export const SEARCH_RECIPIENTS_ERROR = 'SEARCH_RECIPIENTS_ERROR';

export const RESET_BLOCK_BENEFICIARIE = 'RESET_BLOCK_BENEFICIARIE';

export const RESET_FLAGS_BENEFICIARIE = 'RESET_FLAGS_BENEFICIARIE';

export const GET_INVITED_BENEFICIARIES_REQUEST = 'GET_INVITED_BENEFICIARIES_REQUEST';
export const GET_INVITED_BENEFICIARIES_SUCCESS = 'GET_INVITED_BENEFICIARIES_SUCCESS';
export const GET_INVITED_BENEFICIARIES_ERROR = 'GET_INVITED_BENEFICIARIES_ERROR';

export const ADD_BENEFICIARIES_BANK_REQUEST = 'ADD_BENEFICIARIES_BANK_REQUEST';
export const ADD_BENEFICIARIES_BANK_SUCCESS = 'ADD_BENEFICIARIES_BANK_SUCCESS';
export const ADD_BENEFICIARIES_BANK_ERROR = 'ADD_BENEFICIARIES_BANK_ERROR';

export const GET_BENEFICIARIES_BANK_REQUEST = 'GET_BENEFICIARIES_BANK_REQUEST';
export const GET_BENEFICIARIES_BANK_SUCCESS = 'GET_BENEFICIARIES_BANK_SUCCESS';
export const GET_BENEFICIARIES_BANK_ERROR = 'GET_BENEFICIARIES_BANK_ERROR';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getBeneficiaries: { ...block },
  getInvitedBeneficiaryDetails: { ...block },
  createBeneficiaries: { ...block },
  updateBeneficiaries: { ...block },
  addBeneficiaryBank: { ...block },
  getBeneficiaryBank: { ...block },
  searchRecipients: { ...block },
};

export const BeneficiariesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BENEFICIARIES_REQUEST:
      return { ...state, getBeneficiaries: { ...state.getBeneficiaries, loading: true } };
    case GET_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        getBeneficiaries: {
          ...state.getBeneficiaries,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_BENEFICIARIES_ERROR:
      return {
        ...state,
        getBeneficiaries: {
          ...state.getBeneficiaries,
          loading: false,
          error: action.error,
        },
      };

    case CREATE_BENEFICIARIES_REQUEST:
      return {
        ...state,
        createBeneficiaries: { ...state.createBeneficiaries, loading: true },
      };
    case CREATE_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        createBeneficiaries: {
          ...state.createBeneficiaries,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
        getBeneficiaries: {
          ...state.getBeneficiaries,
          data: {
            ...state.getBeneficiaries.data,
            beneficiaries: [
              ...state.getBeneficiaries.data.beneficiaries,
              action.data.data,
            ],
          },
        },
      };
    case CREATE_BENEFICIARIES_ERROR:
      return {
        ...state,
        createBeneficiaries: {
          ...state.createBeneficiaries,
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_BENEFICIARIES_REQUEST:
      return {
        ...state,
        updateBeneficiaries: {
          ...state.updateBeneficiaries,
          loading: true,
        },
      };
    case UPDATE_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        updateBeneficiaries: {
          ...state.updateBeneficiaries,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case UPDATE_BENEFICIARIES_ERROR:
      return {
        ...state,
        updateBeneficiaries: {
          ...state.updateBeneficiaries,
          loading: false,
          error: action.error,
        },
      };

    case SEARCH_RECIPIENTS_REQUEST:
      return {
        ...state,
        searchRecipients: { ...state.searchRecipients, loading: true },
      };
    case SEARCH_RECIPIENTS_SUCCESS:
      return {
        ...state,
        searchRecipients: {
          ...state.searchRecipients,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case SEARCH_RECIPIENTS_ERROR:
      return {
        ...state,
        searchRecipients: {
          ...state.searchRecipients,
          loading: false,
          error: action.error,
        },
      };

    //
    case GET_INVITED_BENEFICIARIES_REQUEST:
      return {
        ...state,
        getInvitedBeneficiaryDetails: {
          ...state.getInvitedBeneficiaryDetails,
          loading: true,
        },
      };
    case GET_INVITED_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        getInvitedBeneficiaryDetails: {
          ...state.getInvitedBeneficiaryDetails,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_INVITED_BENEFICIARIES_ERROR:
      return {
        ...state,
        getInvitedBeneficiaryDetails: {
          ...state.getInvitedBeneficiaryDetails,
          loading: false,
          error: action.error,
        },
      };

    case ADD_BENEFICIARIES_BANK_REQUEST:
      return {
        ...state,
        addBeneficiaryBank: { ...state.addBeneficiaryBank, loading: true },
      };
    case ADD_BENEFICIARIES_BANK_SUCCESS:
      return {
        ...state,
        addBeneficiaryBank: {
          ...state.addBeneficiaryBank,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case ADD_BENEFICIARIES_BANK_ERROR:
      return {
        ...state,
        addBeneficiaryBank: {
          ...state.addBeneficiaryBank,
          loading: false,
          error: action.error,
        },
      };

    case GET_BENEFICIARIES_BANK_REQUEST:
      return {
        ...state,
        getBeneficiaryBank: { ...state.getBeneficiaryBank, loading: true },
      };
    case GET_BENEFICIARIES_BANK_SUCCESS:
      return {
        ...state,
        getBeneficiaryBank: {
          ...state.getBeneficiaryBank,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_BENEFICIARIES_BANK_ERROR:
      return {
        ...state,
        getBeneficiaryBank: {
          ...state.getBeneficiaryBank,
          loading: false,
          error: action.error,
        },
      };

    //reset block with flag and data
    case RESET_BLOCK_BENEFICIARIE:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...initialState[action.blockType],
        },
      };

    //reset only flags(block)
    case RESET_FLAGS_BENEFICIARIE:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};

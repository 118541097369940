import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import NotFoundImg from 'assets/images/404.png';
import CustomButton from 'components/UI/CustomButton';
import { DoubleLeftOutlined } from '@ant-design/icons';
import './styles.scss';
import { useHistory } from 'react-router-dom';

const PageNotFound = () => {
  const history = useHistory();
  return (
    <Container className="user-select-none">
      <Row>
        <Col className="text-center">
          <div className="notfound-wrapper">
            <img src={NotFoundImg} alt="404 not found" className="w-100" />
          </div>
          <CustomButton className="mt-5" onClick={() => history.push('/')}>
            <DoubleLeftOutlined /> {''}
            Home page
          </CustomButton>
        </Col>
      </Row>
    </Container>
  );
};
export default PageNotFound;

import React, { useEffect, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { DownOutlined } from '@ant-design/icons';
import Range from './Range';
import { selectDateRnage } from 'redux/actions/OverviewAction';
import { useDispatch } from 'react-redux';

const DateRangeFilter = ({ handleSelectRange = () => null, selectedRange = '' }) => {
  const dispatch = useDispatch();
  const [isRangePopoverOpen, setIsRangePopoverOpen] = useState(false);
  const handleToggleRangePopover = () => {
    setIsRangePopoverOpen(!isRangePopoverOpen);
  };

  useEffect(() => {
    isRangePopoverOpen && setIsRangePopoverOpen(!isRangePopoverOpen);
  }, [selectedRange]);

  const handleOptionClick = (val, date) => () => {
    Date.prototype.addDays = function (days) {
      // Add days to given date
      const date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    const today = new Date();

    switch (val) {
      case 'Today':
        dispatch(selectDateRnage({ val, range: [{ startDate: today, endDate: today }] }));
        return handleSelectRange(val, [{ startDate: today, endDate: today }]);
      case 'Last 7 days':
        dispatch(selectDateRnage({ val, range: [{ startDate: today, endDate: today }] }));
        return handleSelectRange(val, [{ startDate: today.addDays(-7), endDate: today }]);
      case 'Last 30 days':
        dispatch(
          selectDateRnage({
            val,
            range: [{ startDate: today.addDays(-30), endDate: today }],
          }),
        );
        return handleSelectRange(val, [
          { startDate: today.addDays(-30), endDate: today },
        ]);
      default:
        dispatch(selectDateRnage({ val, range: date }));
        return handleSelectRange(val, date);
    }
  };

  return (
    <Popover
      isOpen={isRangePopoverOpen}
      align="end"
      positions={['bottom', 'left']}
      content={
        <Range
          handleOptionClick={handleOptionClick}
          handleToggleRangePopover={handleToggleRangePopover}
        />
      }
    >
      <div onClick={handleToggleRangePopover} className="cursor">
        {selectedRange} <DownOutlined className="ps-1" />
      </div>
    </Popover>
  );
};
export default DateRangeFilter;

export const CREATE_BUDGET_REQUEST = 'CREATE_BUDGET_REQUEST';
export const CREATE_BUDGET_SUCCESS = 'CREATE_BUDGET_SUCCESS';
export const CREATE_BUDGET_ERROR = 'CREATE_BUDGET_ERROR';

export const DELETE_BUDGET_REQUEST = 'DELETE_BUDGET_REQUEST';
export const DELETE_BUDGET_SUCCESS = 'DELETE_BUDGET_SUCCESS';
export const DELETE_BUDGET_ERROR = 'DELETE_BUDGET_ERROR';

export const GET_BUDGET_REQUEST = 'GET_BUDGET_REQUEST';
export const GET_BUDGET_SUCCESS = 'GET_BUDGET_SUCCESS';
export const GET_BUDGET_ERROR = 'GET_BUDGET_ERROR';

export const GET_BUDGET_STATS_REQUEST = 'GET_BUDGET_STATS_REQUEST';
export const GET_BUDGET_STATS_SUCCESS = 'GET_BUDGET_STATS_SUCCESS';
export const GET_BUDGET_STATS_ERROR = 'GET_BUDGET_STATS_ERROR';

export const GET_SINGLE_BUDGET_REQUEST = 'GET_SINGLE_BUDGET_REQUEST';
export const GET_SINGLE_BUDGET_SUCCESS = 'GET_SINGLE_BUDGET_SUCCESS';
export const GET_SINGLE_BUDGET_ERROR = 'GET_SINGLE_BUDGET_ERROR';

export const UPDATE_BUDGET_REQUEST = 'UPDATE_BUDGET_REQUEST';
export const UPDATE_BUDGET_SUCCESS = 'UPDATE_BUDGET_SUCCESS';
export const UPDATE_BUDGET_ERROR = 'UPDATE_BUDGET_ERROR';

export const BUDGET_BENEFICIARIES_REQUEST = 'BUDGET_BENEFICIARIES_REQUEST';
export const BUDGET_BENEFICIARIES_SUCCESS = 'BUDGET_BENEFICIARIES_SUCCESS';
export const BUDGET_BENEFICIARIES_ERROR = 'BUDGET_BENEFICIARIES_ERROR';

export const BUDGET_CARD_REQUEST = 'BUDGET_CARD_REQUEST';
export const BUDGET_CARD_SUCCESS = 'BUDGET_CARD_SUCCESS';
export const BUDGET_CARD_ERROR = 'BUDGET_CARD_ERROR';

export const CREATE_NEW_BENEFICIARIES_REQUEST = 'CREATE_NEW_BENEFICIARIES_REQUEST';
export const CREATE_NEW_BENEFICIARIES_SUCCESS = 'CREATE_NEW_BENEFICIARIES_SUCCESS';
export const CREATE_NEW_BENEFICIARIES_ERROR = 'CREATE_NEW_BENEFICIARIES_ERROR';

export const GET_SINGLE_BENEFICIARY_REQUEST = 'GET_SINGLE_BENEFICIARY_REQUEST';
export const GET_SINGLE_BENEFICIARY_SUCCESS = ' GET_SINGLE_BENEFICIARY_SUCCESS';
export const GET_SINGLE_BENEFICIARY_ERROR = ' GET_SINGLE_BENEFICIARY_ERROR';

export const GET_SINGLE_TRANSACTION_REQUEST = 'GET_SINGLE_TRANSACTION_REQUEST';
export const GET_SINGLE_TRANSACTION_SUCCESS = ' GET_SINGLE_TRANSACTION_SUCCESS';
export const GET_SINGLE_TRANSACTION_ERROR = ' GET_SINGLE_TRANSACTION_ERROR';

export const RESET_BLOCK_BUDGET = 'RESET_BLOCK_BUDGET';

export const RESET_FLAGS_BUDGET = 'RESET_FLAGS_BUDGET';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getBudget: { ...block },
  createBudget: { ...block },
  updateBudget: { ...block },
  getSingleBudget: { ...block },
  getSingleBeneficiary: { ...block },
  getSingleTransaction: { ...block },
  getBudgetStat: { ...block },
  getBudgetBeneficiaries: { ...block },
  getBudgetCard: { ...block },
  createNewBeneficiaries: { ...block },
  deleteBudget: { ...block },
};

export const BudgetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BUDGET_REQUEST:
      return { ...state, getBudget: { ...state.getBudget, loading: true } };
    case GET_BUDGET_SUCCESS:
      return {
        ...state,
        getBudget: {
          ...state.getBudget,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_BUDGET_ERROR:
      return {
        ...state,
        getBudget: { ...state.getBudget, loading: false, error: action.error },
      };

    case GET_BUDGET_STATS_REQUEST:
      return { ...state, getBudgetStat: { ...state.getBudgetStat, loading: true } };
    case GET_BUDGET_STATS_SUCCESS:
      return {
        ...state,
        getBudgetStat: {
          ...state.getBudgetStat,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_BUDGET_STATS_ERROR:
      return {
        ...state,
        getBudgetStat: { ...state.getBudgetStat, loading: false, error: action.error },
      };

    case CREATE_BUDGET_REQUEST:
      return { ...state, createBudget: { ...state.createBudget, loading: true } };
    case CREATE_BUDGET_SUCCESS:
      return {
        ...state,
        createBudget: {
          ...state.createBudget,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CREATE_BUDGET_ERROR:
      return {
        ...state,
        createBudget: { ...state.createBudget, loading: false, error: action.error },
      };

    case DELETE_BUDGET_REQUEST:
      return { ...state, deleteBudget: { ...state.deleteBudget, loading: true } };
    case DELETE_BUDGET_SUCCESS:
      return {
        ...state,
        deleteBudget: {
          ...state.deleteBudget,
          loading: false,
          success: true,
          error: '',
        },
      };
    case DELETE_BUDGET_ERROR:
      return {
        ...state,
        deleteBudget: { ...state.deleteBudget, loading: false, error: action.error },
      };

    case UPDATE_BUDGET_REQUEST:
      return {
        ...state,
        UPDATE_BUDGET_REQUEST: { ...state.UPDATE_BUDGET_REQUEST, loading: true },
      };
    case UPDATE_BUDGET_SUCCESS:
      return {
        ...state,
        UPDATE_BUDGET_REQUEST: {
          ...state.UPDATE_BUDGET_REQUEST,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case UPDATE_BUDGET_ERROR:
      return {
        ...state,
        UPDATE_BUDGET_REQUEST: {
          ...state.UPDATE_BUDGET_REQUEST,
          loading: false,
          error: action.error,
        },
      };

    case GET_SINGLE_BUDGET_REQUEST:
      return { ...state, getSingleBudget: { ...state.getSingleBudget, loading: true } };
    case GET_SINGLE_BUDGET_SUCCESS:
      return {
        ...state,
        getSingleBudget: {
          ...state.getSingleBudget,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SINGLE_BUDGET_ERROR:
      return {
        ...state,
        getSingleBudget: {
          ...state.getSingleBudget,
          loading: false,
          error: action.error,
        },
      };

    case BUDGET_BENEFICIARIES_REQUEST:
      return {
        ...state,
        getBudgetBeneficiaries: { ...state.getBudgetBeneficiaries, loading: true },
      };
    case BUDGET_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        getBudgetBeneficiaries: {
          ...state.getBudgetBeneficiaries,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case BUDGET_BENEFICIARIES_ERROR:
      return {
        ...state,
        getBudgetBeneficiaries: {
          ...state.getBudgetBeneficiaries,
          loading: false,
          error: action.error,
        },
      };

    case BUDGET_CARD_REQUEST:
      return { ...state, getBudgetCard: { ...state.getBudgetCard, loading: true } };
    case BUDGET_CARD_SUCCESS:
      return {
        ...state,
        getBudgetCard: {
          ...state.getBudgetCard,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case BUDGET_CARD_ERROR:
      return {
        ...state,
        getBudgetCard: {
          ...state.getBudgetCard,
          loading: false,
          error: action.error,
        },
      };

    case CREATE_NEW_BENEFICIARIES_REQUEST:
      return {
        ...state,
        createNewBeneficiaries: { ...state.createNewBeneficiaries, loading: true },
      };
    case CREATE_NEW_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        createNewBeneficiaries: {
          ...state.createNewBeneficiaries,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CREATE_NEW_BENEFICIARIES_ERROR:
      return {
        ...state,
        createNewBeneficiaries: {
          ...state.createNewBeneficiaries,
          loading: false,
          error: action.error,
        },
      };

    case GET_SINGLE_BENEFICIARY_REQUEST:
      return {
        ...state,
        getSingleBeneficiary: { ...state.getSingleBeneficiary, loading: true },
      };
    case GET_SINGLE_BENEFICIARY_SUCCESS:
      return {
        ...state,
        getSingleBudget: {
          ...state.getSingleBudget,
          data: {
            ...state.getSingleBudget?.data,
            beneficiaries: action.data?.beneficiaries || [],
          },
        },
        getSingleBeneficiary: {
          ...state.getSingleBeneficiary,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SINGLE_BENEFICIARY_ERROR:
      return {
        ...state,
        getSingleBeneficiary: {
          ...state.getSingleBeneficiary,
          loading: false,
          error: action.error,
        },
      };

    case GET_SINGLE_TRANSACTION_REQUEST:
      return {
        ...state,
        getSingleTransaction: { ...state.getSingleTransaction, loading: true },
      };
    case GET_SINGLE_TRANSACTION_SUCCESS:
      return {
        ...state,
        getBudgetStat: {
          ...state.getBudgetStat,
          data: {
            ...state.getBudgetStat.data,
            transactions: action.data?.transactions,
          },
        },
        getSingleTransaction: {
          ...state.getSingleTransaction,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SINGLE_TRANSACTION_ERROR:
      return {
        ...state,
        getSingleTransaction: {
          ...state.getSingleTransaction,
          loading: false,
          error: action.error,
        },
      };

    //reset block with flag and data
    case RESET_BLOCK_BUDGET:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...initialState[action.blockType],
        },
      };

    //reset only flags(block)
    case RESET_FLAGS_BUDGET:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};

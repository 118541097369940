import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Axios } from 'api/axios';
import { getSimplifiedError } from 'utils/error';
import {
  GET_VENDOR_REQUEST,
  GET_VENDOR_SUCCESS,
  GET_VENDOR_ERROR,
  CREATE_VENDOR_REQUEST,
  CREATE_VENDOR_SUCCESS,
  CREATE_VENDOR_ERROR,
  RESET_FLAGS_VENDOR,
  
  UPDATE_VENDOR_REQUEST,
  UPDATE_VENDOR_SUCCESS,
  UPDATE_VENDOR_ERROR,
  GET_SINGLE_VENDOR_ERROR,
  GET_SINGLE_VENDOR_SUCCESS,
  GET_SINGLE_VENDOR_REQUEST,
  GET_VENDOR_STATS_SUCCESS,
  GET_VENDOR_STATS_ERROR,
  GET_VENDOR_STATS_REQUEST,
  VENDOR_BENEFICIARIES_REQUEST,
  VENDOR_CARD_REQUEST,
  VENDOR_BENEFICIARIES_SUCCESS,
  VENDOR_BENEFICIARIES_ERROR,
  VENDOR_CARD_SUCCESS,
  VENDOR_CARD_ERROR,
  CREATE_NEW_BENEFICIARIES_REQUEST,
  CREATE_NEW_BENEFICIARIES_SUCCESS,
  CREATE_NEW_BENEFICIARIES_ERROR,
  DELETE_VENDOR_REQUEST,
  DELETE_VENDOR_ERROR,
  DELETE_VENDOR_SUCCESS,
} from '../reducers/VendorsReducer';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

async function getVendor(
  // params = {}
  ) {
  // return await Axios.get('/vendors/', { params });
  return await Axios.get('/vendors');
}

function* handleGetVendor({ payload }) {
  try {
    const response = yield call(getVendor, payload);
    if (response) {
      yield put({
        type: GET_VENDOR_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_VENDOR,
        blockType: 'getVendor',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_VENDOR_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createVendor(payload) {
  return await Axios.post('/vendors', payload);
}
function* handleCreateVendor({ payload }) {
  try {
    const response = yield call(createVendor, payload);
    if (response) {
      yield put({
        type: CREATE_VENDOR_SUCCESS,
        data: response.data,
      });
      toast(response.message, { type: 'success' });
      yield put({
        type: RESET_FLAGS_VENDOR,
        blockType: 'createVendor',
      });
      yield put({
        type: GET_VENDOR_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: CREATE_VENDOR_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteVendor({ code }) {
  
  return await Axios.delete(`/vendors/${code}`);
}
function* handleDeleteVendor({ payload }) {
  try {
    const response = yield call(deleteVendor, payload);
    if (response) {
      yield put({
        type: DELETE_VENDOR_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_VENDOR,
        blockType: 'deleteVendor',
      });
      yield put({
        type: GET_VENDOR_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: DELETE_VENDOR_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getVendorStats({ id }) {
  return await Axios.get(`/vendors/${id}/statistics`);
}
function* handleGetVendorStats({ payload }) {
  try {
    const response = yield call(getVendorStats, payload);
    if (response) {
      yield put({
        type: GET_VENDOR_STATS_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_VENDOR,
        blockType: 'getVendorStat',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_VENDOR_STATS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}



async function updateVendor({ payload, code }) {
  return await Axios.put(`/vendors/${code}`, payload);
}
function* handleUpdateVendor({ payload }) {
  try {
    const getPayload = { ...payload };
    delete payload.code;
    const response = yield call(updateVendor, { payload, code: getPayload.code });
    if (response) {
      yield put({
        type: UPDATE_VENDOR_SUCCESS,
        data: payload,
      });
      yield put({
        type: GET_SINGLE_VENDOR_REQUEST,
        payload: { id: getPayload.code },
      });
      yield put({
        type: RESET_FLAGS_VENDOR,
        blockType: 'updateVendor',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: UPDATE_VENDOR_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function singleVendor({ id }) {
  return await Axios.get(`/vendors/${id}`);
}
function* handleSingleVendor({ payload }) {
  try {
    const response = yield call(singleVendor, payload);
    if (response) {
      yield put({
        type: GET_SINGLE_VENDOR_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_VENDOR,
        blockType: 'getSingleVendor',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_SINGLE_VENDOR_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function vendorBeneficiaries(params) {
  return await Axios.get('/beneficiaries', { params });
}
function* handleVendorBeneficiaries({ payload }) {
  try {
    const response = yield call(vendorBeneficiaries, payload);
    if (response) {
      yield put({
        type: VENDOR_BENEFICIARIES_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_VENDOR,
        blockType: 'getVendorBeneficiaries',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: VENDOR_BENEFICIARIES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function vendorCard(params) {
  return await Axios.get('/cards', { params });
}
function* handleVendorCard({ payload }) {
  try {
    const response = yield call(vendorCard, payload);
    if (response) {
      yield put({
        type: VENDOR_CARD_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_VENDOR,
        blockType: 'getVendorCard',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: VENDOR_CARD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}
async function newBeneficiaries(payload) {
  const { vendorsCode, beneficiariesCode } = { ...payload };
  delete payload['vendorsCode'];
  delete payload['beneficiariesCode'];
  return await Axios.post(
    `/vendors/${vendorsCode}/beneficiaries/${beneficiariesCode}`,
    payload,
  );
}
function* handleCreateNewBeneficiaries({ payload }) {
  try {
    const response = yield call(newBeneficiaries, payload);
    if (response) {
      yield put({
        type: CREATE_NEW_BENEFICIARIES_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_VENDOR,
        blockType: 'createNewBeneficiaries',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: CREATE_NEW_BENEFICIARIES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(GET_VENDOR_REQUEST, handleGetVendor),
  takeLatest(DELETE_VENDOR_REQUEST, handleDeleteVendor),
  takeLatest(GET_VENDOR_STATS_REQUEST, handleGetVendorStats),
  takeLatest(CREATE_VENDOR_REQUEST, handleCreateVendor),
  takeLatest(UPDATE_VENDOR_REQUEST, handleUpdateVendor),
  takeLatest(GET_SINGLE_VENDOR_REQUEST, handleSingleVendor),
  takeLatest(VENDOR_BENEFICIARIES_REQUEST, handleVendorBeneficiaries),
  takeLatest(VENDOR_CARD_REQUEST, handleVendorCard),
  takeLatest(CREATE_NEW_BENEFICIARIES_REQUEST, handleCreateNewBeneficiaries),
]);

import {
  CREATE_ASSET_REQUEST,
  DOWNLOAD_ASSET_REQUEST,
  GET_ASSET_REQUEST,
  UPLOAD_FILE_REQUEST,
} from '../reducers/AssetsReducer';

export const getAssets = (payload) => ({
  type: GET_ASSET_REQUEST,
  payload,
});
export const downloadAsset = (payload) => ({
  type: DOWNLOAD_ASSET_REQUEST,
  payload,
});

export const createAssets = (payload) => ({ type: CREATE_ASSET_REQUEST, payload });

export const uploadFiles = (payload) => ({ type: UPLOAD_FILE_REQUEST, payload });

import React from 'react';
import cs from 'classnames';
import { Col, Form } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();
const MultipleSelect = ({
  closeMenuOnSelect = false,
  isMulti = true,
  md = 12,
  label = '',
  wrapperClass = '',
  defaultValue,
  ...res
}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '100%',
      border: '1px solid #e5e5e5',
      outline: 0,
      padding: 6,
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #e5e5e5',
      },
    }),
  };

  return (
    <div>
      <Form.Group
        as={Col}
        md={md}
        className={cs('groupWrapper', { [wrapperClass]: wrapperClass })}
      >
        {label && <Form.Label>{label}</Form.Label>}
        <Select
          defaultValue={defaultValue}
          closeMenuOnSelect={closeMenuOnSelect}
          components={animatedComponents}
          // defaultValue={[colourOptions[4], colourOptions[5]]}
          isMulti={isMulti}
          styles={customStyles}
          {...res}
        />
      </Form.Group>
    </div>
  );
};
export default MultipleSelect;

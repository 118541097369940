import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Axios } from 'api/axios';
import { getSimplifiedError } from 'utils/error';
import {
  CREATE_BUDGET_REQUEST,
  CREATE_BUDGET_SUCCESS,
  CREATE_BUDGET_ERROR,
  RESET_FLAGS_BUDGET,
  GET_BUDGET_REQUEST,
  GET_BUDGET_SUCCESS,
  GET_BUDGET_ERROR,
  UPDATE_BUDGET_REQUEST,
  UPDATE_BUDGET_SUCCESS,
  UPDATE_BUDGET_ERROR,
  GET_SINGLE_BUDGET_ERROR,
  GET_SINGLE_BUDGET_SUCCESS,
  GET_SINGLE_BUDGET_REQUEST,
  GET_BUDGET_STATS_SUCCESS,
  GET_BUDGET_STATS_ERROR,
  GET_BUDGET_STATS_REQUEST,
  BUDGET_BENEFICIARIES_REQUEST,
  BUDGET_CARD_REQUEST,
  BUDGET_BENEFICIARIES_SUCCESS,
  BUDGET_BENEFICIARIES_ERROR,
  BUDGET_CARD_SUCCESS,
  BUDGET_CARD_ERROR,
  CREATE_NEW_BENEFICIARIES_REQUEST,
  CREATE_NEW_BENEFICIARIES_SUCCESS,
  CREATE_NEW_BENEFICIARIES_ERROR,
  DELETE_BUDGET_REQUEST,
  DELETE_BUDGET_ERROR,
  DELETE_BUDGET_SUCCESS,
} from '../reducers/BudgetsReducer';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

async function getBudget(params = {}) {
  return await Axios.get('/admin/budgets/', { params });
}
function* handleGetBudget({ payload }) {
  try {
    const response = yield call(getBudget, payload);
    if (response) {
      yield put({
        type: GET_BUDGET_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_BUDGET,
        blockType: 'getBudget',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_BUDGET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteBudget({ code }) {
  
  return await Axios.delete(`/budgets/${code}`);
}
function* handleDeleteBudget({ payload }) {
  try {
    const response = yield call(deleteBudget, payload);
    if (response) {
      yield put({
        type: DELETE_BUDGET_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_BUDGET,
        blockType: 'deleteBudget',
      });
      yield put({
        type: GET_BUDGET_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: DELETE_BUDGET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getBudgetStats({ id }) {
  return await Axios.get(`/budgets/${id}/statistics`);
}
function* handleGetBudgetStats({ payload }) {
  try {
    const response = yield call(getBudgetStats, payload);
    if (response) {
      yield put({
        type: GET_BUDGET_STATS_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_BUDGET,
        blockType: 'getBudgetStat',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_BUDGET_STATS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createBudget(payload) {
  return await Axios.post('/budgets', payload);
}
function* handleCreateBudget({ payload }) {
  try {
    const response = yield call(createBudget, payload);
    if (response) {
      yield put({
        type: CREATE_BUDGET_SUCCESS,
        data: response.data,
      });
      toast(response.message, { type: 'success' });
      yield put({
        type: RESET_FLAGS_BUDGET,
        blockType: 'createBudget',
      });
      yield put({
        type: GET_BUDGET_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: CREATE_BUDGET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateBudget({ payload, code }) {
  return await Axios.put(`/budgets/${code}`, payload);
}
function* handleUpdateBudget({ payload }) {
  try {
    const getPayload = { ...payload };
    delete payload.code;
    const response = yield call(updateBudget, { payload, code: getPayload.code });
    if (response) {
      yield put({
        type: UPDATE_BUDGET_SUCCESS,
        data: payload,
      });
      yield put({
        type: GET_SINGLE_BUDGET_REQUEST,
        payload: { id: getPayload.code },
      });
      yield put({
        type: RESET_FLAGS_BUDGET,
        blockType: 'updateBudget',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: UPDATE_BUDGET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function singleBudget({ id }) {
  return await Axios.get(`/budgets/${id}`);
}
function* handleSingleBudget({ payload }) {
  try {
    const response = yield call(singleBudget, payload);
    if (response) {
      yield put({
        type: GET_SINGLE_BUDGET_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_BUDGET,
        blockType: 'getSingleBudget',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_SINGLE_BUDGET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function budgetBeneficiaries(params) {
  return await Axios.get('/beneficiaries', { params });
}
function* handleBudgetBeneficiaries({ payload }) {
  try {
    const response = yield call(budgetBeneficiaries, payload);
    if (response) {
      yield put({
        type: BUDGET_BENEFICIARIES_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_BUDGET,
        blockType: 'getBudgetBeneficiaries',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: BUDGET_BENEFICIARIES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function budgetCard(params) {
  return await Axios.get('/cards', { params });
}
function* handleBudgetCard({ payload }) {
  try {
    const response = yield call(budgetCard, payload);
    if (response) {
      yield put({
        type: BUDGET_CARD_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_BUDGET,
        blockType: 'getBudgetCard',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: BUDGET_CARD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}
async function newBeneficiaries(payload) {
  const { budgetsCode, beneficiariesCode } = { ...payload };
  delete payload['budgetsCode'];
  delete payload['beneficiariesCode'];
  return await Axios.post(
    `/budgets/${budgetsCode}/beneficiaries/${beneficiariesCode}`,
    payload,
  );
}
function* handleCreateNewBeneficiaries({ payload }) {
  try {
    const response = yield call(newBeneficiaries, payload);
    if (response) {
      yield put({
        type: CREATE_NEW_BENEFICIARIES_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_BUDGET,
        blockType: 'createNewBeneficiaries',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: CREATE_NEW_BENEFICIARIES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(GET_BUDGET_REQUEST, handleGetBudget),
  takeLatest(DELETE_BUDGET_REQUEST, handleDeleteBudget),
  takeLatest(GET_BUDGET_STATS_REQUEST, handleGetBudgetStats),
  takeLatest(CREATE_BUDGET_REQUEST, handleCreateBudget),
  takeLatest(UPDATE_BUDGET_REQUEST, handleUpdateBudget),
  takeLatest(GET_SINGLE_BUDGET_REQUEST, handleSingleBudget),
  takeLatest(BUDGET_BENEFICIARIES_REQUEST, handleBudgetBeneficiaries),
  takeLatest(BUDGET_CARD_REQUEST, handleBudgetCard),
  takeLatest(CREATE_NEW_BENEFICIARIES_REQUEST, handleCreateNewBeneficiaries),
]);

import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Axios } from 'api/axios';
import { getSimplifiedError } from 'utils/error';
import {
  FILE_UPLOAD_REQUEST,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_ERROR,
  RESET_FLAGS_CARD,
} from '../reducers/ProfileReducer';
import { toast } from 'react-toastify';
import axios from 'axios';

async function fileDate({ payload, response }) {
  const requestOptions = {
    method: 'PUT',
    body: payload,
  };
  return await fetch(response.data.signedUrl, requestOptions);
}

async function fileUploadAPI(payload) {
  return await Axios.post('/files/url/upload', payload);
}
function* handleFileUpload({ payload }) {
  try {
    const response = yield call(fileUploadAPI, payload.fileName);
    if (response.data) {
      const enew = { payload: payload.fileData, response };

      const res = yield call(fileDate, enew);
      yield put({
        type: FILE_UPLOAD_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_CARD,
        blockType: 'fileUpload',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: FILE_UPLOAD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([takeLatest(FILE_UPLOAD_REQUEST, handleFileUpload)]);

import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { Axios } from 'api/axios';
import { getSimplifiedError } from 'utils/error';
import {
  RESET_FLAGS_COMPANY,
  GET_COMPANY_REQUEST,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_ERROR,
  GET_SINGLE_COMPANY_REQUEST,
  GET_SINGLE_COMPANY_SUCCESS,
  GET_SINGLE_COMPANY_ERROR,
  GET_COUNTRY_REQUEST,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_ERROR,
  GET_STATE_REQUEST,
  GET_STATE_SUCCESS,
  GET_STATE_ERROR,
  GET_COMPANY_STATS_REQUEST,
  GET_COMPANY_STATS_SUCCESS,
  GET_COMPANY_STATS_ERROR,
  GET_BANK_ACCOUNT_REQUEST,
  GET_BANK_ACCOUNT_SUCCESS,
  GET_BANK_ACCOUNT_ERROR,
  UPDATE_COMPANY_ERROR,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_REQUEST,
  REVIEW_COMPANY_REQUEST,
  REVIEW_COMPANY_SUCCESS,
  REVIEW_COMPANY_ERROR,
  ONBOARDING_BUSINESS_INFORMATION_REQUEST,
  ONBOARDING_BUSINESS_INFORMATION_SUCCESS,
  ONBOARDING_BUSINESS_INFORMATION_ERROR,
  ONBOARDING_BUSINESS_ADDRESS_REQUEST,
  ONBOARDING_BUSINESS_ADDRESS_SUCCESS,
  ONBOARDING_BUSINESS_ADDRESS_ERROR,
  ONBOARDING_BUSINESS_DOC_REQUEST,
  ONBOARDING_BUSINESS_DOC_SUCCESS,
  ONBOARDING_BUSINESS_DOC_ERROR,
  FUND_WIDTH_CARDS_REQUEST,
  FUND_WIDTH_CARDS_SUCCESS,
  FUND_WIDTH_CARDS_ERROR,
} from '../reducers/CompaniesReducer';
import { toast } from 'react-toastify';

async function getCompany(params = {}) {
  return await Axios.get(`/admin/company`, { params });
}
function* handleGetCompany({ payload }) {
  try {
    const response = yield call(getCompany, payload);
    if (response) {
      yield put({
        type: GET_COMPANY_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_COMPANY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSingleCompany({ payload = {} }) {
  return await Axios.get(`/admin/company/one?code=${payload}`);
}
function* handleGetSingleCompany(payload) {
  try {
    const response = yield call(getSingleCompany, payload);
    if (response) {
      yield put({
        type: GET_SINGLE_COMPANY_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_SINGLE_COMPANY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getCountry(payload) {
  // return await Axios.get(`/countryData/${payload}/`);
  return await Axios.get(`/mock/all-countries`);
}

function* handleGetCountries({ payload }) {
  try {
    const response = yield call(getCountry, payload);
    if (response) {
      yield put({
        type: GET_COUNTRY_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_COUNTRY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getState(payload) {
  // return await Axios.get(`/countryData/${payload}/`);
  return await Axios.get(`/mock/a-country?country_code=${payload}`);
}

function* handleGeStates({ payload }) {
  try {
    const response = yield call(getState, payload);
    if (response) {
      yield put({
        type: GET_STATE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_STATE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function onboardingBusinessInformation(payload) {
  // const { code } = { ...payload };
  // delete payload['code'];
  return await Axios.post(`/onboarding/business-information`, payload);
}
function* handleOnboardingBusinessInformation({ payload }) {
  try {
    const response = yield call(onboardingBusinessInformation, payload);
    if (response) {
      yield put({
        type: ONBOARDING_BUSINESS_INFORMATION_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: ONBOARDING_BUSINESS_INFORMATION_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function onboardingBusinessAddress(payload) {
  // const { code } = { ...payload };
  // delete payload['code'];
  return await Axios.post(`/onboarding/business-address`, payload);
}
function* handleOnboardingBusinessAdress({ payload }) {
  try {
    const response = yield call(onboardingBusinessAddress, payload);
    if (response) {
      yield put({
        type: ONBOARDING_BUSINESS_ADDRESS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: ONBOARDING_BUSINESS_ADDRESS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function onboardingBusinessDoc(payload) {
  // const { code } = { ...payload };
  // delete payload['code'];
  return await Axios.post(`/onboarding/business-docs`, payload);
}
function* handleOnboardingBusinessDoc({ payload }) {
  try {
    const response = yield call(onboardingBusinessDoc, payload);
    if (response) {
      yield put({
        type: ONBOARDING_BUSINESS_DOC_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: ONBOARDING_BUSINESS_DOC_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getStats({ id, params }) {
  return await Axios.get(`/companies/${id}/statistics`, { params });
}
function* handleStats({ payload }) {
  try {
    const response = yield call(getStats, payload);
    if (response) {
      yield put({
        type: GET_COMPANY_STATS_SUCCESS,
        data: response.data,
      });
      toast(response.data.message, { type: 'success' });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_COMPANY_STATS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getBankAccount({ code }) {
  return await Axios.get(`/companies/${code}/virtual-account`);
}
function* handleGetBankAccount({ payload }) {
  try {
    const response = yield call(getBankAccount, payload);
    if (response) {
      yield put({
        type: GET_BANK_ACCOUNT_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_BANK_ACCOUNT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateCompany(payload) {
  const { code } = { ...payload };
  delete payload['code'];
  // return await Axios.patch(`/companies/${code}`, payload);
  return await Axios.patch(`/admin/company/update/${code}`, payload);
}
function* handleUpdateCompany({ payload }) {
  try {
    const response = yield call(updateCompany, payload);
    if (response) {
      yield put({
        type: UPDATE_COMPANY_SUCCESS,
        data: response.data,
      });

      yield put(delay(200));
      yield put({
        type: RESET_FLAGS_COMPANY,
        blockType: 'updateCompany',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: UPDATE_COMPANY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

// TODO @hitesh remove everything related to /companies/review this endpoint shouldn't be on the dashboard
async function reviewCompany(payload) {
  return await Axios.post(`companies/review`);
}
function* handleFreezeUnfreezeCard({ payload }) {
  try {
    const response = yield call(reviewCompany, payload);
    if (response) {
      yield put({
        type: REVIEW_COMPANY_SUCCESS,
        data: response.data,
      });

      yield put(delay(200));
      yield put({
        type: RESET_FLAGS_COMPANY,
        blockType: 'reviewCompany',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: REVIEW_COMPANY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

// TODO @hitesh remove everything related to /companies/review this endpoint shouldn't be on the dashboard
async function fundCreateCall(payload) {
  const { code } = { ...payload };
  delete payload['code'];
  return await Axios.post(`/companies/${code}/fund`, payload);
}
function* handleFundCreateAPI({ payload }) {
  try {
    const response = yield call(fundCreateCall, payload);
    if (response) {
      yield put({
        type: FUND_WIDTH_CARDS_SUCCESS,
        data: response.data,
      });
      yield put(delay(200));
      yield put({
        type: RESET_FLAGS_COMPANY,
        blockType: 'fundCreate',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: FUND_WIDTH_CARDS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(GET_COMPANY_REQUEST, handleGetCompany),
  takeLatest(GET_SINGLE_COMPANY_REQUEST, handleGetSingleCompany),
  takeLatest(GET_COUNTRY_REQUEST, handleGetCountries),
  takeLatest(GET_STATE_REQUEST, handleGeStates),
  takeLatest(
    ONBOARDING_BUSINESS_INFORMATION_REQUEST,
    handleOnboardingBusinessInformation,
  ),
  takeLatest(ONBOARDING_BUSINESS_ADDRESS_REQUEST, handleOnboardingBusinessAdress),
  takeLatest(ONBOARDING_BUSINESS_DOC_REQUEST, handleOnboardingBusinessDoc),
  takeLatest(GET_COMPANY_STATS_REQUEST, handleStats),
  takeLatest(GET_BANK_ACCOUNT_REQUEST, handleGetBankAccount),
  takeLatest(UPDATE_COMPANY_REQUEST, handleUpdateCompany),
  takeLatest(REVIEW_COMPANY_REQUEST, handleFreezeUnfreezeCard),
  takeLatest(FUND_WIDTH_CARDS_REQUEST, handleFundCreateAPI),
]);

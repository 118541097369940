import { all, call, put, takeLatest } from 'redux-saga/effects';
import Cookies from 'js-cookie';

import { Axios } from 'api/axios';
import { getSimplifiedError } from 'utils/error';
import {
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_ERROR,
  RESET_FLAGS_AUTH,
  OTP_SUCCESS,
  OTP_ERROR,
  OTP_REQUEST,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  NEW_PASSWORD_SUCCESS,
  NEW_PASSWORD_ERROR,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_ERROR,
  NEW_PASSWORD_REQUEST,
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_ERROR,
  SET_BENEFICIARY_PASSWORD_REQUEST,
  SET_BENEFICIARY_PASSWORD_SUCCESS,
  SET_BENEFICIARY_PASSWORD_ERROR,
} from '../reducers/AuthReducer';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { GET_COMPANY_REQUEST } from 'redux/reducers/CompaniesReducer';
import { GET_BENEFICIARIES_BANK_REQUEST } from 'redux/reducers/BeneficiariesReducer';
import { GET_BUDGET_REQUEST } from 'redux/reducers/BudgetsReducer';

async function signup(payload) {
  return await Axios.post('/users/register', payload);
}
function* handleSignup({ payload }) {
  try {
    const response = yield call(signup, payload);
    if (response) {
      const options = { path: '/' };
      Cookies.set('hash', response.data.hash, options);
      yield put({
        type: SIGNUP_SUCCESS,
      });
      yield put({
        type: RESET_FLAGS_AUTH,
        payload: { blockType: 'signup' },
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: SIGNUP_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function login(payload) {
  return await Axios.post('/auth/login', payload);
}
function* handleLogin({ payload }) {
  try {
    const response = yield call(login, payload);
    if (response.data.token) {
      const options = { path: '/' };
      Cookies.set('mrdr-token', response.data.token, options);
      yield put({
        type: LOGIN_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    const errors = getSimplifiedError(error);
    if (errors === 'Please verify your email/phone number') {
      const options = { path: '/' };
      Cookies.set('hash', error.response.data.data.data.hash, options);
    }

    if (error) {
      toast(errors, { type: 'error' });
    }
    yield put({
      type: LOGIN_ERROR,
      error: errors,
    });
    yield put({
      type: RESET_FLAGS_AUTH,
      payload: { blockType: 'login' },
    });
  }
}

async function get_user() {
  // return await Axios.get('/users/');
}
function* get_user_profile() {
  try {
    const response = yield call(get_user);
    if (response) {
      const {
        data: { user },
      } = response;

      const { status, role, company } = user;

      localStorage.setItem('uInfo', JSON.stringify({ status: status, role: role }));

      yield put({
        type: GET_USER_PROFILE_SUCCESS,
        data: response,
      });

      if (company?.code) {
        localStorage.setItem(
          'onBoardingStatus',
          JSON.stringify({
            status: company?.status,
            onboardingStatus: company?.onboardingStatus,
          }),
        );
        yield put({
          type: GET_BUDGET_REQUEST,
        });

        yield put({
          type: GET_COMPANY_REQUEST,
          payload: company?.code,
        });
      }
      if (['employee', 'manager'].includes(role)) {
        yield put({
          type: GET_BENEFICIARIES_BANK_REQUEST,
        });
      }
      yield put({
        type: RESET_FLAGS_AUTH,
        payload: { blockType: 'loginUser' },
      });
    }
  } catch (error) {
    yield put({
      type: GET_USER_PROFILE_ERROR,
      error: getSimplifiedError(error),
    });
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

async function verifyOtpApi(payload) {
  return await Axios.post('/users/verify-otp', payload);
}
function* handleVerifyOtp({ payload }) {
  try {
    const response = yield call(verifyOtpApi, payload);
    if (response.data.token) {
      const options = { path: '/' };
      Cookies.set('mrdr-token', response.data.token, options);
      yield put({
        type: OTP_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: OTP_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function resetPasswordApi(payload) {
  return await Axios.post('/users/forgot-password', payload);
}
function* handleResetPassword({ payload }) {
  try {
    const response = yield call(resetPasswordApi, payload);
    if (response) {
      yield put({
        type: RESET_PASSWORD_SUCCESS,
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: RESET_PASSWORD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function newPasswordApi(payload) {
  return await Axios.post('/users/reset-password', payload);
}
function* handleNewPassword({ payload }) {
  try {
    const response = yield call(newPasswordApi, payload);
    if (response) {
      yield put({
        type: NEW_PASSWORD_SUCCESS,
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: NEW_PASSWORD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function verifyEmailApi(payload) {
  return await Axios.post('/users/users/verify-otp', payload);
}
function* handleVerifyEmail({ payload }) {
  const history = useHistory();
  try {
    const response = yield call(verifyEmailApi, payload);
    if (response.data.token) {
      yield put({
        type: VERIFY_EMAIL_SUCCESS,
        data: response.data,
      });
      history.push('/user/edit');
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: VERIFY_EMAIL_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updatedUserApi(payload) {
  return await Axios.put('/users', payload);
}
function* handleUpdateUser({ payload }) {
  try {
    const response = yield call(updatedUserApi, payload);
    if (response.data) {
      yield put({
        type: UPDATE_USER_PROFILE_SUCCESS,
        data: response.data,
      });
      yield put({
        type: GET_USER_PROFILE_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: UPDATE_USER_PROFILE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function setBeneficiaryPassword(payload) {
  return await Axios.post('/users/beneficiary-password', payload);
}
function* handleSetBeneficiaryPassword({ payload }) {
  try {
    const response = yield call(setBeneficiaryPassword, payload);
    if (response.data.token) {
      const options = { path: '/' };
      Cookies.set('mrdr-token', response.data.token, options);
      yield put({
        type: SET_BENEFICIARY_PASSWORD_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    const errors = getSimplifiedError(error);
    if (errors === 'Please verify your email/phone number') {
      const options = { path: '/' };
      Cookies.set('hash', error.response.data.data.data.hash, options);
    }

    if (error) {
      toast(errors, { type: 'error' });
    }
    yield put({
      type: SET_BENEFICIARY_PASSWORD_ERROR,
      error: errors,
    });
    yield put({
      type: RESET_FLAGS_AUTH,
      payload: { blockType: 'login' },
    });
  }
}

export default all([
  takeLatest(SIGNUP_REQUEST, handleSignup),
  takeLatest(GET_USER_PROFILE_REQUEST, get_user_profile),
  takeLatest(LOGIN_REQUEST, handleLogin),
  takeLatest(SET_BENEFICIARY_PASSWORD_REQUEST, handleSetBeneficiaryPassword),
  takeLatest(OTP_REQUEST, handleVerifyOtp),
  takeLatest(RESET_PASSWORD_REQUEST, handleResetPassword),
  takeLatest(NEW_PASSWORD_REQUEST, handleNewPassword),
  takeLatest(VERIFY_EMAIL_SUCCESS, handleVerifyEmail),
  takeLatest(UPDATE_USER_PROFILE_REQUEST, handleUpdateUser),
]);

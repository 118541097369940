import React from 'react';
import cs from 'classnames';
import copy from 'copy-text-to-clipboard';
import './styles.scss';
import { toast } from 'react-toastify';

const KybInfo = ({
  title,
  value,
  withIcon = false,
  titleClass = '',
  valueClass = '',
  removeIconBg,
  fullCursor,
  onTitleClick,
  isCopy = true,
}) => {
  return (
    <div className={cs('mt-4', { ['cursor']: fullCursor })}>
      <div
        className="d-flex align-items-center justify-content-between"
        onClick={onTitleClick}
      >
        <div className="transaction-wrapper">
          <p className={titleClass}>{title}</p>
          <h3 className={valueClass}>{value}</h3>
        </div>
        {withIcon && (
          <div
            className={cs('transaction-icon', { ['removeIconBg']: removeIconBg })}
            onClick={() => {
              if (isCopy) {
                copy(value);
                toast(`copy: ${value}`, { type: 'sussess' });
              }
            }}
          >
            {withIcon}
          </div>
        )}
      </div>
    </div>
  );
};
export default KybInfo;

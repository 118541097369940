export const data = [
  { name: '00', total: 1400 },
  { name: '01', total: 1000 },
  { name: '02', total: 1200 },
  { name: '03', total: 1300 },
  { name: '04', total: 1800 },
  { name: '05', total: 1200 },
  { name: '06', total: 800 },
  { name: '07', total: 1100 },
  { name: '08', total: 1600 },
  { name: '09', total: 2200 },
  { name: '10', total: 2400 },
  { name: '11', total: 2900 },
  { name: '12', total: 2500 },
  { name: '13', total: 2700 },
  { name: '14', total: 3000 },
  { name: '15', total: 3600 },
  { name: '16', total: 3800 },
  { name: '17', total: 3500 },
  { name: '18', total: 3100 },
  { name: '19', total: 2800 },
  { name: '20', total: 2400 },
  { name: '21', total: 2000 },
  { name: '22', total: 2600 },
  { name: '23' },
  { name: '24' },
  { name: '25' },
  { name: '26' },
];

export const data1 = [
  { name: '00', total: 1400 },
  { name: '01', total: 1520 },
  { name: '02', total: 3655 },
  { name: '03', total: 3400 },
  { name: '04', total: 3200 },
  { name: '05', total: 2566 },
  { name: '06', total: 1800 },
  { name: '07', total: 2200 },
  { name: '08', total: 2600 },
  { name: '09', total: 2800 },
  { name: '10', total: 2900 },
  { name: '11', total: 2550 },
  { name: '12', total: 1500 },
  { name: '13', total: 1200 },
  { name: '14', total: 1520 },
  { name: '15', total: 1800 },
  { name: '16', total: 1600 },
  { name: '17', total: 2440 },
  { name: '18', total: 2800 },
  { name: '19', total: 2200 },
  { name: '20', total: 1800 },
  { name: '21', total: 1600 },
  { name: '22', total: 1400 },
  { name: '23' },
  { name: '24' },
  { name: '25' },
  { name: '26' },
];

export const data2 = [
  { name: '00', total: 1680 },
  { name: '01', total: 2222 },
  { name: '02', total: 2352 },
  { name: '03', total: 2410 },
  { name: '04', total: 2985 },
  { name: '05', total: 2000 },
  { name: '06', total: 2654 },
  { name: '07', total: 2145 },
  { name: '08', total: 1598 },
  { name: '09', total: 1475 },
  { name: '10', total: 1698 },
  { name: '11', total: 1666 },
  { name: '12', total: 1500 },
  { name: '13', total: 1300 },
  { name: '14', total: 1100 },
  { name: '15', total: 800 },
  { name: '16', total: 1500 },
  { name: '17', total: 1600 },
  { name: '18', total: 1700 },
  { name: '19', total: 1800 },
  { name: '20', total: 1900 },
  { name: '21', total: 1500 },
  { name: '22', total: 1200 },
  { name: '23' },
  { name: '24' },
  { name: '25' },
  { name: '26' },
];

export const data3 = [
  { name: '00', total: 2222 },
  { name: '01', total: 1900 },
  { name: '02', total: 1800 },
  { name: '03', total: 1700 },
  { name: '04', total: 1600 },
  { name: '05', total: 1500 },
  { name: '06', total: 1400 },
  { name: '07', total: 1200 },
  { name: '08', total: 1600 },
  { name: '09', total: 1700 },
  { name: '10', total: 1800 },
  { name: '11', total: 1900 },
  { name: '12', total: 2500 },
  { name: '13', total: 2700 },
  { name: '14', total: 3000 },
  { name: '15', total: 3600 },
  { name: '16', total: 2900 },
  { name: '17', total: 2700 },
  { name: '18', total: 2600 },
  { name: '19', total: 2650 },
  { name: '20', total: 2200 },
  { name: '21', total: 2000 },
  { name: '22', total: 2600 },
  { name: '23' },
  { name: '24' },
  { name: '25' },
  { name: '26' },
];

export const noData = [
  { name: '00' },
  { name: '01' },
  { name: '02' },
  { name: '03' },
  { name: '04' },
  { name: '05' },
  { name: '06' },
  { name: '07' },
  { name: '08' },
  { name: '09' },
  { name: '10' },
  { name: '11' },
  { name: '12' },
  { name: '13' },
  { name: '14' },
  { name: '15' },
  { name: '16' },
  { name: '17' },
  { name: '18' },
  { name: '19' },
  { name: '20' },
  { name: '21' },
  { name: '22' },
  { name: '23' },
  { name: '24' },
  { name: '25' },
  { name: '26' },
];

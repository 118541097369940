import React from 'react';
import { Spin } from 'antd';
import cs from 'classnames';
import { LoadingOutlined } from '@ant-design/icons';
import { ArrowRightOutlined } from '@ant-design/icons';
import { ReactComponent as Ellipse } from 'assets/icons/ellipse.svg';
import { ReactComponent as Divider } from 'assets/icons/divider.svg';
import { ReactComponent as GreenCheck } from 'assets/icons/green-check.svg';
import './styles.scss';

const OverviewStatisticTimeLine = ({title, completed=false, number}) => {

  return (
    <>
      <div style={{display: 'flex'}}>
        {completed ?
          <GreenCheck style={{marginTop: '-10px'}} /> :
          <>
            <Ellipse className='overview-statistics-ellipse'/>
            <Divider  className='overview-statistics-divider'/>
          </>
        }
      </div>
      <div className='statistics-title-div'>
        <div style={{display: 'flex', fontSize: '14px', fontWeight: '400', marginBottom: '10px'}}>
          <span className='statistics-title'>{title}</span>
          <ArrowRightOutlined style={{marginTop: '5px', fontSize: '12px'}} />
        </div>
        {completed ? 
          <p className='statistic-number-completed' >{number}</p> :
          <p className='statistic-number' >{number}</p>
        }
        
      </div>
    </>   
  );
};
export default OverviewStatisticTimeLine;

import NavBar from 'components/NavBar';
import Sidebar from 'components/Sidebar';
import DefaultAPI from 'pages/DefaultAPI';
import { useEffect } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  withRouter,
} from 'react-router-dom';
import { getUserLandingPage, isLoggedIn } from 'utils/utility';
import './baseStyles.scss';
import Routes from './Routes';

const renderRoutes = () => {
  const {
    user
  } = useSelector(({ auth }) => auth);
  
  const renderRoute = (routerProps, Component, props, isPrivate = false, headerHide) => {
    if (Component) {
      const componentProps = {
        ...routerProps,
        ...props,
      };
      if (isPrivate) {
        if (isLoggedIn()) {
          return (
            <>
              {!headerHide && <Sidebar />}
              {/* {!headerHide && <NavBar />} */}
              <main>
                <div className="main-content">
                  <Component {...componentProps} style={{ width: '80%' }} />
                </div>
              </main>
            </>
          );
        } else {
          localStorage.clear();
          return <Redirect to="/login" />;
        }
        // return isLoggedIn() ? (
        //   <>
        //     {!headerHide && <NavBar />}
        //     <Component {...componentProps} />
        //     <BujetiPayModal />
        //   </>
        // ) : (
        //   <Redirect to="/login" />
        // );
      }
      return isLoggedIn() && user ? (
        <Redirect to={getUserLandingPage(user)} />
      ) : (
        <Component {...componentProps} />
      ); // eslint-disable-line
    }
    return null;
  };

  return Routes.map((route) => (
    <Route
      key={route.name}
      exact={route.exact}
      path={route.path}
      render={(routerProps) =>
        renderRoute(
          routerProps,
          route.component,
          route.props,
          route.isPrivate,
          route.headerHide,
        )
      }
    />
  ));
};

const Router = () => <Switch>{renderRoutes()}</Switch>;

const App = () => {
  const history = useHistory();

  const {
    logout: { success },
  } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (success) {
      history.push('/login');
    }
  }, [success]);

  return (
    <div className="App">
      <DefaultAPI />
      <Router />
    </div>
  );
};

export default withRouter(App);

import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { get_user_profile } from 'redux/actions/AuthAction';
import { useHistory } from 'react-router-dom';
const DefaultAPI = () => {
  const token = Cookies.get('mrdr-token');
  const {
    user: { data = {} },
  } = useSelector(({ auth }) => auth);
  const history = useHistory();

  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (token) {
  //     // dispatch(get_user_profile());
  //     history.push('/');
  //   }
  // }, [token]);

  useEffect(() => {
    // if (!data?.user?.company) history.push('/user/edit');
  }, [data?.user]);
  return <></>;
};
export default DefaultAPI;

import React from 'react';
import { Col, Form } from 'react-bootstrap';
import './styles.scss';
import cs from 'classnames';

const CustomDate = ({ label, defaultValue, className, ...res }) => {
  return (
    <div className="relative">
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        defaultValue={defaultValue}
        type="date"
        className={cs('date-input', { [className]: className })}
        {...res}
      />
    </div>
  );
};
export default CustomDate;

import { Link } from 'react-router-dom';
import { ArrowUpRightIcon } from 'assets/icons';
import './styles.scss';
import copy from 'copy-text-to-clipboard';
import { toast } from 'react-toastify';
import Loading from 'components/UI/Loading';
import { toastSuccess } from 'components/UI/toast';

/**
 * @param {String} title: the title of the item
 * @param {String} value: the value of the item
 * @param {String} isUnderline: this adds a text-underline style
 * @param {isLink} isLink: this adds a arrow icon to signify that it's a link
 * @returns
 */

const ItemInfo = ({
  title,
  value,
  icon,
  isUnderline,
  isCopy,
  isLink,
  withAction,
  action,
  isLoading,
  hideValue,
  withBadge,
}) => {
  return (
    <section className="item-info gap-1 d-flex flex-column">
      <h6 className="item-title text-sm font-medium color-grey-500">{title}</h6>
      <div className={`d-flex gap-2 ${isUnderline ? 'underline' : ''}`}>
        {withBadge && icon}
        {!isLink && !isCopy && !hideValue && (
          <span className="color-grey-900 font-medium item-value item-size">{value}</span>
        )}
        {withAction && !isLink && (
          <span onClick={action} className="cursor">
            {isLoading ? <Loading color="#D28B28" size={20} /> : icon}
          </span>
        )}

        {isLink && !withAction && (
          <Link to={isLink} target="_blank" className="link item-value">
            <span className="color-grey-900 font-medium item-value item-size ">
              {value}
            </span>
            <ArrowUpRightIcon stroke="#D28B28" width="16" height="16" />
          </Link>
        )}
        {isLink && withAction && (
          <>
            <Link to={isLink} target="_blank" className="item-value ">
              <span className="color-grey-900 font-medium item-value item-size">
                {value}
              </span>
            </Link>
            <span onClick={action} className="cursor">
              {isLoading ? <Loading color="#D28B28" size={20} /> : icon}
            </span>
          </>
        )}
        {isCopy && (
          <>
            <span
              className="color-grey-900 font-medium item-value item-size cursor"
              onClick={() => {
                if (isCopy) {
                  copy(value);
                  toastSuccess(`${title} copied successfully`);
                }
              }}
            >
              {value}
              {icon}
            </span>
          </>
        )}
      </div>
    </section>
  );
};

export default ItemInfo;

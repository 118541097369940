import {
  SIGNUP_REQUEST,
  LOGIN_REQUEST,
  RESET_BLOCK_AUTH,
  RESET_FLAGS_AUTH,
  LOGOUT,
  GET_USER_PROFILE_REQUEST,
  OTP_REQUEST,
  RESET_PASSWORD_REQUEST,
  NEW_PASSWORD_REQUEST,
  VERIFY_EMAIL_REQUEST,
  UPDATE_USER_PROFILE_REQUEST,
  SET_BENEFICIARY_PASSWORD_REQUEST,
} from '../reducers/AuthReducer';

export const signup = (payload) => ({ type: SIGNUP_REQUEST, payload });

export const verifyOTP = (payload) => ({ type: OTP_REQUEST, payload });

export const login = (payload) => ({ type: LOGIN_REQUEST, payload });

export const setBeneficiaryPassword = (payload) => ({ type: SET_BENEFICIARY_PASSWORD_REQUEST, payload });


export const resetPasswordAction = (payload) => ({
  type: RESET_PASSWORD_REQUEST,
  payload,
});

export const newPassword = (payload) => ({ type: NEW_PASSWORD_REQUEST, payload });

export const get_user_profile = (payload) => ({ type: GET_USER_PROFILE_REQUEST, payload });

export const update_user_profile = (payload) => ({
  type: UPDATE_USER_PROFILE_REQUEST,
  payload,
});

export const verifyEmail = (payload) => ({ type: VERIFY_EMAIL_REQUEST, payload });

export const resetBlockAuth = (payload) => ({
  type: RESET_BLOCK_AUTH,
  payload,
});

export const resetFlagsAuth = (payload) => ({
  type: RESET_FLAGS_AUTH,
  payload,
});

export const logout = () => ({
  type: LOGOUT,
});

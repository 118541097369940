export const CREATE_CARD_REQUEST = 'CREATE_CARD_REQUEST';
export const CREATE_CARD_SUCCESS = 'CREATE_CARD_SUCCESS';
export const CREATE_CARD_ERROR = 'CREATE_CARD_ERROR';

export const GET_CARD_REQUEST = 'GET_CARD_REQUEST';
export const GET_CARD_SUCCESS = 'GET_CARD_SUCCESS';
export const GET_CARD_ERROR = 'GET_CARD_ERROR';

export const DEACTIVATE_CARD_REQUEST = 'DEACTIVATE_CARD_REQUEST';
export const DEACTIVATE_CARD_SUCCESS = 'DEACTIVATE_CARD_SUCCESS';
export const DEACTIVATE_CARD_ERROR = 'DEACTIVATE_CARD_ERROR';

export const GET_SINGLE_CARD_REQUEST = 'GET_SINGLE_CARD_REQUEST';
export const GET_SINGLE_CARD_SUCCESS = 'GET_SINGLE_CARD_SUCCESS';
export const GET_SINGLE_CARD_ERROR = 'GET_SINGLE_CARD_ERROR';

export const FREEZE_UNFREEZE_CARD_REQUEST = 'FREEZE_UNFREEZE_CARD_REQUEST';
export const FREEZE_UNFREEZE_CARD_SUCCESS = 'FREEZE_UNFREEZE_CARD_SUCCESS';
export const FREEZE_UNFREEZE_CARD_ERROR = 'FREEZE_UNFREEZE_CARD_ERROR';

export const RESET_BLOCK_CARD = 'RESET_BLOCK_CARD';

export const RESET_FLAGS_CARD = 'RESET_FLAGS_CARD';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getCard: { ...block, data: {} },
  createCard: { ...block },
  deactivateCard: { ...block },
  getSingleCard: { ...block },
  freezeUnfreezeCard: { ...block },
};

export const CardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CARD_REQUEST:
      return { ...state, getCard: { ...state.getCard, loading: true } };
    case GET_CARD_SUCCESS:
      return {
        ...state,
        getCard: {
          ...state.getCard,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_CARD_ERROR:
      return {
        ...state,
        getCard: { ...state.getCard, loading: false, error: action.error },
      };

    case CREATE_CARD_REQUEST:
      return { ...state, createCard: { ...state.createCard, loading: true } };
    case CREATE_CARD_SUCCESS:
      return {
        ...state,
        createCard: {
          ...state.createCard,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CREATE_CARD_ERROR:
      return {
        ...state,
        createCard: { ...state.createCard, loading: false, error: action.error },
      };

    case DEACTIVATE_CARD_REQUEST:
      return { ...state, deactivateCard: { ...state.deactivateCard, loading: true } };
    case DEACTIVATE_CARD_SUCCESS:
      return {
        ...state,
        deactivateCard: {
          ...state.deactivateCard,
          loading: false,
          success: true,
          error: '',
        },
      };
    case DEACTIVATE_CARD_ERROR:
      return {
        ...state,
        deactivateCard: { ...state.deactivateCard, loading: false, error: action.error },
      };

    case GET_SINGLE_CARD_REQUEST:
      return { ...state, getSingleCard: { ...state.getSingleCard, loading: true } };
    case GET_SINGLE_CARD_SUCCESS:
      return {
        ...state,
        getSingleCard: {
          ...state.getSingleCard,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SINGLE_CARD_ERROR:
      return {
        ...state,
        getSingleCard: { ...state.getSingleCard, loading: false, error: action.error },
      };

    case FREEZE_UNFREEZE_CARD_REQUEST:
      return {
        ...state,
        freezeUnfreezeCard: { ...state.freezeUnfreezeCard, loading: true },
      };
    case FREEZE_UNFREEZE_CARD_SUCCESS:
      return {
        ...state,
        freezeUnfreezeCard: {
          ...state.freezeUnfreezeCard,
          loading: false,
          success: true,
          error: '',
        },
      };
    case FREEZE_UNFREEZE_CARD_ERROR:
      return {
        ...state,
        freezeUnfreezeCard: {
          ...state.freezeUnfreezeCard,
          loading: false,
          error: action.error,
        },
      };
    //reset block with flag and data
    case RESET_BLOCK_CARD:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...initialState[action.blockType],
        },
      };

    //reset only flags(block)
    case RESET_FLAGS_CARD:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cs from 'classnames';
import './styles.scss';
import { EyeOutlined } from '@ant-design/icons';
import { ReactComponent as Trash } from 'assets/icons/Trash.svg';

const FileView = ({ label, wrapperClass, fileName, fileUrl, action }) => {
  const dispatch = useDispatch();

  // const [fileDetails, setFileDetails] = useState({})
  // const [fileName, setFileName] = useState('');

  const openFile = () => {
    window.open(fileUrl, '_blank', 'noopener,noreferrer');
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const deleteFile = () => {
  }
  

  return (
    <div className={cs('fileUpload', { [wrapperClass]: wrapperClass })+' mt-4'}>
      <label className='viewFileLabel'>{label}</label>
      {
        action === 'view' ?
        <div className="fileUploadedDiv" onClick={openFile}>
          <span className="uploadedFileText">{fileName}</span>
          <EyeOutlined style={{opacity: '0.5'}} />
        </div> :
        action === 'delete' ?
        <div className="fileUploadedDiv" onClick={deleteFile}>
          <span className="uploadedFileText">{fileName}</span>
          <Trash style={{opacity: '0.5'}} />
        </div> :
        ''
      }
      
    </div>
  );
};
export default FileView;

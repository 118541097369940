import {
  GET_USER_REQUEST,
  GET_SINGLE_USER_REQUEST,
} from '../reducers/UsersReducer';


export const getUsers = (payload) => ({
  type: GET_USER_REQUEST,
  payload,
});

export const getSingleUser = (payload) => ({
  type: GET_SINGLE_USER_REQUEST,
  payload,
});
import React, { useEffect, useRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import Portal from '../Portal';
import styles from './modal.module.css';

const Modal = ({ modalStyle, children, show, onClose, backdropStyle }) => {
  const modalRef = useRef(null);
  useEffect(() => {
    if (show) {
      modalRef.current.classList.add(styles.visible);
    } else {
      modalRef.current.classList.remove(styles.visible);
    }
  }, [show]);

  const handleOutsideClick = () => {
    const toastList = document.getElementsByClassName('Toastify__toast');
    setTimeout(() => {
      if (toastList.length > 0) return;
      if (onClose) onClose();
    }, 200);
  };

  return (
    <React.Fragment>
      <Portal className="modal-portal">
        <div ref={modalRef} style={backdropStyle} className={`${styles.modal__wrap}`}>
          <div className="ms-auto">
            <OutsideClickHandler
              onOutsideClick={() => {
                handleOutsideClick();
              }}
            >
              <div style={modalStyle} className={styles.modal}>
                {children}
              </div>
            </OutsideClickHandler>
          </div>
        </div>
      </Portal>
    </React.Fragment>
  );
};

export default Modal;

import { CloseOutlined } from '@ant-design/icons';
import Documents from 'components/Documents';
import CustomButton from 'components/UI/CustomButton';
import Loading from 'components/UI/Loading';
import Modal from 'components/UI/Modal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  approveDisapproveKYBRequests,
  getKybRequests,
  getSingleKybRequest,
} from 'redux/actions/KybRequestsAction';
import { RESET_BLOCK_COMPANY } from 'redux/reducers/CompaniesReducer';
import BadgeType from '../UI/Table/BadgeType';
import KybInfo from './KybInfo';
import KybOnboardingDetails from './KybOnboardingDetails';
import { Row } from 'react-bootstrap';

const KybModal = ({ selectKyb, setSelectKyb }) => {
  const dispatch = useDispatch();
  
  const [editCompany, setEditCompany] = useState(false);

  const {
    getSingleKybRequest: { data: kybData, loading },
    approveDisapproveKYBRequest: {
      success: approved,
      loading: loadingApproval,
      error: rejected,
    },
    updateKybRequest: { success },
  } = useSelector(({ Kybrequest }) => Kybrequest);

  useEffect(() => {
    if (selectKyb) {
      dispatch(getSingleKybRequest(selectKyb.kybData.code));
    }
  }, [selectKyb]);

  useEffect(() => {
    if (approved) {
      handleClose();
      dispatch(getKybRequests());
    }
  }, [approved, rejected]);

  const approveDisapproveKYBRequest = (status) => {
    const payload = {
      decision: status,
      company: kybData.code,
    };
    dispatch(approveDisapproveKYBRequests(payload));
  };

  const handleClose = () => {
    setSelectKyb(null);
    setEditCompany(false);
    dispatch({ type: RESET_BLOCK_COMPANY, blockType: 'getSingleCompany' });
  };

  const edit = () => {
    setEditCompany(!editCompany);
  };

  useEffect(() => {
    if (success) {
      setEditCompany(false);
    }
  }, [success]);

  const handleCancel = () => {
    setEditCompany(false);
  };

  if (!selectKyb) return <div />;
  return (
    <Modal show={selectKyb} onClose={handleClose}>
      <div className="content">
        <div className="card-modal-header">
          <div className="d-flex align-items-center cursor" onClick={handleClose}>
            <CloseOutlined />
            <span className="ps-1">Close</span>
          </div>
        </div>
        <div className="card-modal-body" style={{ color: '#ffffff' }}>
          {loading ? (
            <Loading isPage color="#D28B28" />
          ) : (
            <div className="information-wrapper">
              <div className="flex">
                <Row>
                  <span className="edit" onClick={edit}>
                    Edit
                  </span>
                </Row>
                <BadgeType value={selectKyb.status} />
              </div>

              <div className="d-flex align-items-center justify-content-between">
                <h2 className="card-title">{selectKyb.businessname}</h2>
              </div>
              <span style={{ color: '#BFC6B9', fontSize: '14px', fontWeight: '400' }}>
                {selectKyb?.creationdate}
              </span>
              <hr />
              {editCompany ? (
                <KybOnboardingDetails
                  data={kybData}
                  selectCompany={selectKyb}
                  handleCancel={handleCancel}
                />
              ) : (
                <>
                  <KybInfo title="Business Name" value={selectKyb?.businessname} />
                  <KybInfo title="Business Code" value={selectKyb?.kybData?.code} />
                  <KybInfo
                    title="Business Address"
                    value={selectKyb?.kybData?.address?.street}
                  />
                  <KybInfo title="Number of employees" value={selectKyb?.kybData?.size} />
                  <KybInfo
                    title="Description"
                    value={kybData?.description ? kybData?.description : 'N/A'}
                  />
                  <KybInfo title="Website" value={selectKyb?.kybData?.website} />
                  <KybInfo
                    title="Email address"
                    value={selectKyb?.kybData?.contact_email}
                  />
                  <KybInfo
                    title="Phone number"
                    value={`+${selectKyb?.kybData?.contact_phone}`}
                  />
                  <KybInfo
                    title="Business Type"
                    value={selectKyb?.kybData?.businessType}
                  />
                  <Documents documents={kybData?.documents} />
                </>
              )}
              {!editCompany && (
                <>
                  <div style={{ marginTop: '20px' }}>
                    <CustomButton
                      fullWidth
                      className="mt-3"
                      onClick={() => approveDisapproveKYBRequest('ACCEPTED')}
                      disabled={loadingApproval}
                    >
                      Approve request
                    </CustomButton>
                  </div>
                  <div className="ms-auto">
                    <CustomButton
                      withoutBg
                      fullWidth
                      className="mt-3"
                      onClick={() => approveDisapproveKYBRequest('REJECTED')}
                      disabled={loadingApproval}
                    >
                      Decline request
                    </CustomButton>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default KybModal;

import React from 'react';
import { Badge } from 'react-bootstrap';
import './styles.scss';
import { getColor, truncateText } from 'utils/helper';

const DottedBadge = ({ value }) => {
  return (
    <span
      style={{ backgroundColor: `${value.color || getColor(value.value)}` }}
      className="dotted-badge"
    >
      {truncateText(value.value, 0, 25)}
    </span>
  );
};
export default DottedBadge;

export const CREATE_VENDOR_REQUEST = 'CREATE_VENDOR_REQUEST';
export const CREATE_VENDOR_SUCCESS = 'CREATE_VENDOR_SUCCESS';
export const CREATE_VENDOR_ERROR = 'CREATE_VENDOR_ERROR';

export const DELETE_VENDOR_REQUEST = 'DELETE_VENDOR_REQUEST';
export const DELETE_VENDOR_SUCCESS = 'DELETE_VENDOR_SUCCESS';
export const DELETE_VENDOR_ERROR = 'DELETE_VENDOR_ERROR';

export const GET_VENDOR_REQUEST = 'GET_VENDOR_REQUEST';
export const GET_VENDOR_SUCCESS = 'GET_VENDOR_SUCCESS';
export const GET_VENDOR_ERROR = 'GET_VENDOR_ERROR';

export const GET_VENDOR_STATS_REQUEST = 'GET_VENDOR_STATS_REQUEST';
export const GET_VENDOR_STATS_SUCCESS = 'GET_VENDOR_STATS_SUCCESS';
export const GET_VENDOR_STATS_ERROR = 'GET_VENDOR_STATS_ERROR';

export const GET_SINGLE_VENDOR_REQUEST = 'GET_SINGLE_VENDOR_REQUEST';
export const GET_SINGLE_VENDOR_SUCCESS = 'GET_SINGLE_VENDOR_SUCCESS';
export const GET_SINGLE_VENDOR_ERROR = 'GET_SINGLE_VENDOR_ERROR';

export const UPDATE_VENDOR_REQUEST = 'UPDATE_VENDOR_REQUEST';
export const UPDATE_VENDOR_SUCCESS = 'UPDATE_VENDOR_SUCCESS';
export const UPDATE_VENDOR_ERROR = 'UPDATE_VENDOR_ERROR';

export const VENDOR_BENEFICIARIES_REQUEST = 'VENDOR_BENEFICIARIES_REQUEST';
export const VENDOR_BENEFICIARIES_SUCCESS = 'VENDOR_BENEFICIARIES_SUCCESS';
export const VENDOR_BENEFICIARIES_ERROR = 'VENDOR_BENEFICIARIES_ERROR';

export const VENDOR_CARD_REQUEST = 'VENDOR_CARD_REQUEST';
export const VENDOR_CARD_SUCCESS = 'VENDOR_CARD_SUCCESS';
export const VENDOR_CARD_ERROR = 'VENDOR_CARD_ERROR';

export const CREATE_NEW_BENEFICIARIES_REQUEST = 'CREATE_NEW_BENEFICIARIES_REQUEST';
export const CREATE_NEW_BENEFICIARIES_SUCCESS = 'CREATE_NEW_BENEFICIARIES_SUCCESS';
export const CREATE_NEW_BENEFICIARIES_ERROR = 'CREATE_NEW_BENEFICIARIES_ERROR';

export const GET_SINGLE_BENEFICIARY_REQUEST = 'GET_SINGLE_BENEFICIARY_REQUEST';
export const GET_SINGLE_BENEFICIARY_SUCCESS = ' GET_SINGLE_BENEFICIARY_SUCCESS';
export const GET_SINGLE_BENEFICIARY_ERROR = ' GET_SINGLE_BENEFICIARY_ERROR';

export const GET_SINGLE_TRANSACTION_REQUEST = 'GET_SINGLE_TRANSACTION_REQUEST';
export const GET_SINGLE_TRANSACTION_SUCCESS = ' GET_SINGLE_TRANSACTION_SUCCESS';
export const GET_SINGLE_TRANSACTION_ERROR = ' GET_SINGLE_TRANSACTION_ERROR';

export const RESET_BLOCK_VENDOR = 'RESET_BLOCK_VENDOR';

export const RESET_FLAGS_VENDOR = 'RESET_FLAGS_VENDOR';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getVendor: { ...block },
  createVendor: { ...block },
  updateVendor: { ...block },
  getSingleVendor: { ...block },
  getSingleBeneficiary: { ...block },
  getSingleTransaction: { ...block },
  getVendorStat: { ...block },
  getVendorBeneficiaries: { ...block },
  getVendorCard: { ...block },
  createNewBeneficiaries: { ...block },
  deleteVendor: { ...block },
};

export const VendorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VENDOR_REQUEST:
      return { ...state, getVendor: { ...state.getVendor, loading: true } };
    case GET_VENDOR_SUCCESS:
      return {
        ...state,
        getVendor: {
          ...state.getVendor,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_VENDOR_ERROR:
      return {
        ...state,
        getVendor: { ...state.getVendor, loading: false, error: action.error },
      };

    case CREATE_VENDOR_REQUEST:
      return { ...state, createVendor: { ...state.createVendor, loading: true } };
    case CREATE_VENDOR_SUCCESS:
      return {
        ...state,
        createVendor: {
          ...state.createVendor,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CREATE_VENDOR_ERROR:
      return {
        ...state,
        createVendor: { ...state.createVendor, loading: false, error: action.error },
      };
  
    case GET_VENDOR_STATS_REQUEST:
      return { ...state, getVendorStat: { ...state.getVendorStat, loading: true } };
    case GET_VENDOR_STATS_SUCCESS:
      return {
        ...state,
        getVendorStat: {
          ...state.getVendorStat,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_VENDOR_STATS_ERROR:
      return {
        ...state,
        getVendorStat: { ...state.getVendorStat, loading: false, error: action.error },
      };

    
    case DELETE_VENDOR_REQUEST:
      return { ...state, deleteVendor: { ...state.deleteVendor, loading: true } };
    case DELETE_VENDOR_SUCCESS:
      return {
        ...state,
        deleteVendor: {
          ...state.deleteVendor,
          loading: false,
          success: true,
          error: '',
        },
      };
    case DELETE_VENDOR_ERROR:
      return {
        ...state,
        deleteVendor: { ...state.deleteVendor, loading: false, error: action.error },
      };

    case UPDATE_VENDOR_REQUEST:
      return {
        ...state,
        UPDATE_VENDOR_REQUEST: { ...state.UPDATE_VENDOR_REQUEST, loading: true },
      };
    case UPDATE_VENDOR_SUCCESS:
      return {
        ...state,
        UPDATE_VENDOR_REQUEST: {
          ...state.UPDATE_VENDOR_REQUEST,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case UPDATE_VENDOR_ERROR:
      return {
        ...state,
        UPDATE_VENDOR_REQUEST: {
          ...state.UPDATE_VENDOR_REQUEST,
          loading: false,
          error: action.error,
        },
      };

    case GET_SINGLE_VENDOR_REQUEST:
      return { ...state, getSingleVendor: { ...state.getSingleVendor, loading: true } };
    case GET_SINGLE_VENDOR_SUCCESS:
      return {
        ...state,
        getSingleVendor: {
          ...state.getSingleVendor,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SINGLE_VENDOR_ERROR:
      return {
        ...state,
        getSingleVendor: {
          ...state.getSingleVendor,
          loading: false,
          error: action.error,
        },
      };

    case VENDOR_BENEFICIARIES_REQUEST:
      return {
        ...state,
        getVendorBeneficiaries: { ...state.getVendorBeneficiaries, loading: true },
      };
    case VENDOR_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        getVendorBeneficiaries: {
          ...state.getVendorBeneficiaries,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case VENDOR_BENEFICIARIES_ERROR:
      return {
        ...state,
        getVendorBeneficiaries: {
          ...state.getVendorBeneficiaries,
          loading: false,
          error: action.error,
        },
      };

    case VENDOR_CARD_REQUEST:
      return { ...state, getVendorCard: { ...state.getVendorCard, loading: true } };
    case VENDOR_CARD_SUCCESS:
      return {
        ...state,
        getVendorCard: {
          ...state.getVendorCard,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case VENDOR_CARD_ERROR:
      return {
        ...state,
        getVendorCard: {
          ...state.getVendorCard,
          loading: false,
          error: action.error,
        },
      };

    case CREATE_NEW_BENEFICIARIES_REQUEST:
      return {
        ...state,
        createNewBeneficiaries: { ...state.createNewBeneficiaries, loading: true },
      };
    case CREATE_NEW_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        createNewBeneficiaries: {
          ...state.createNewBeneficiaries,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CREATE_NEW_BENEFICIARIES_ERROR:
      return {
        ...state,
        createNewBeneficiaries: {
          ...state.createNewBeneficiaries,
          loading: false,
          error: action.error,
        },
      };

    case GET_SINGLE_BENEFICIARY_REQUEST:
      return {
        ...state,
        getSingleBeneficiary: { ...state.getSingleBeneficiary, loading: true },
      };
    case GET_SINGLE_BENEFICIARY_SUCCESS:
      return {
        ...state,
        getSingleVendor: {
          ...state.getSingleVendor,
          data: {
            ...state.getSingleVendor?.data,
            beneficiaries: action.data?.beneficiaries || [],
          },
        },
        getSingleBeneficiary: {
          ...state.getSingleBeneficiary,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SINGLE_BENEFICIARY_ERROR:
      return {
        ...state,
        getSingleBeneficiary: {
          ...state.getSingleBeneficiary,
          loading: false,
          error: action.error,
        },
      };

    case GET_SINGLE_TRANSACTION_REQUEST:
      return {
        ...state,
        getSingleTransaction: { ...state.getSingleTransaction, loading: true },
      };
    case GET_SINGLE_TRANSACTION_SUCCESS:
      return {
        ...state,
        getVendorStat: {
          ...state.getVendorStat,
          data: {
            ...state.getVendorStat.data,
            transactions: action.data?.transactions,
          },
        },
        getSingleTransaction: {
          ...state.getSingleTransaction,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SINGLE_TRANSACTION_ERROR:
      return {
        ...state,
        getSingleTransaction: {
          ...state.getSingleTransaction,
          loading: false,
          error: action.error,
        },
      };

    //reset block with flag and data
    case RESET_BLOCK_VENDOR:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...initialState[action.blockType],
        },
      };

    //reset only flags(block)
    case RESET_FLAGS_VENDOR:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};

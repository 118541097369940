export const GET_TRANSACTIONS_REQUEST = 'GET_TRANSACTIONS_REQUEST';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_ERROR = 'GET_TRANSACTIONS_ERROR';

export const GET_SINGLE_TRANSACTIONS_REQUEST = 'GET_SINGLE_TRANSACTIONS_REQUEST';
export const GET_SINGLE_TRANSACTIONS_SUCCESS = 'GET_SINGLE_TRANSACTIONS_SUCCESS';
export const GET_SINGLE_TRANSACTIONS_ERROR = 'GET_SINGLE_TRANSACTIONS_ERROR';

export const RESET_BLOCK_TRANSACTIONS = 'RESET_BLOCK_TRANSACTIONS';

export const RESET_FLAGS_TRANSACTIONS = 'RESET_FLAGS_TRANSACTIONS';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getTransaction: { ...block },
  createTransaction: { ...block },
  updateTransaction: { ...block },
  getSingleTransaction: { ...block },
};

export const TransactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRANSACTIONS_REQUEST:
      return { ...state, getTransaction: { ...state.getTransaction, loading: true } };
    case GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        getTransaction: {
          ...state.getTransaction,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_TRANSACTIONS_ERROR:
      return {
        ...state,
        getTransaction: { ...state.getTransaction, loading: false, error: action.error },
      };

    case GET_SINGLE_TRANSACTIONS_REQUEST:
      return {
        ...state,
        getSingleTransaction: { ...state.getSingleTransaction, loading: true },
      };
    case GET_SINGLE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        getSingleTransaction: {
          ...state.getSingleTransaction,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SINGLE_TRANSACTIONS_ERROR:
      return {
        ...state,
        getSingleTransaction: {
          ...state.getSingleTransaction,
          loading: false,
          error: action.error,
        },
      };

    //reset block with flag and data
    case RESET_BLOCK_TRANSACTIONS:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...initialState[action.blockType],
        },
      };

    //reset only flags(block)
    case RESET_FLAGS_TRANSACTIONS:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};

import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { Axios } from 'api/axios';
import { getSimplifiedError } from 'utils/error';
import {
  RESET_FLAGS_KYBREQUESTS,
  GET_KYBREQUESTS_REQUEST,
  GET_KYBREQUESTS_SUCCESS,
  GET_KYBREQUESTS_ERROR,
  GET_SINGLE_KYBREQUESTS_REQUEST,
  GET_SINGLE_KYBREQUESTS_SUCCESS,
  GET_SINGLE_KYBREQUESTS_ERROR,
  APPROVE_DISAPPROVE_KYB_REQUEST,
  APPROVE_DISAPPROVE_KYB_SUCCESS,
  APPROVE_DISAPPROVE_KYB_ERROR,
  GET_REQUIRED_DOCUMENTS_REQUEST,
  GET_REQUIRED_DOCUMENTS_SUCCESS,
  GET_REQUIRED_DOCUMENTS_ERROR,
  SUBMIT_DOCUMENTS_REQUEST,
  SUBMIT_DOCUMENTS_SUCCESS,
  SUBMIT_DOCUMENTS_ERROR,
  RESET_BLOCK_KYBREQUESTS,
  GET_SINGLE_COMPLIENCE_REQUEST,
  GET_SINGLE_COMPLIENCE_SUCCESS,
  GET_SINGLE_COMPLIENCE_ERROR,
  UPDATE_KYBREQUESTS_SUCCESS,
  UPDATE_KYBREQUESTS_ERROR,
  UPDATE_KYBREQUESTS_REQUEST,
  REVIEW_COMPANY_DOCUMENTS_SUCCESS,
  REVIEW_COMPANY_DOCUMENTS_ERROR,
  REVIEW_COMPANY_DOCUMENTS_REQUEST,
} from '../reducers/KybRequestsReducer';
import { toast } from 'react-toastify';

async function getKybRequest({ payload = {} }) {
  return await Axios.get(`/admin/onboarding/status`, { params: payload });
}
function* handleGetKybRequest(payload) {
  try {
    const response = yield call(getKybRequest, payload);
    if (response) {
      yield put({
        type: GET_KYBREQUESTS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_KYBREQUESTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSingleKybRequest({ payload = {} }) {
  return await Axios.get(`/admin/company/one?code=${payload}`);
}
function* handleGetSingleKybRequest(payload) {
  try {
    const response = yield call(getSingleKybRequest, payload);
    if (response) {
      yield put({
        type: GET_SINGLE_KYBREQUESTS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_SINGLE_KYBREQUESTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateKybRequest({ payload }) {
  const { code } = payload;
  delete payload.code;
  return await Axios.patch(`/admin/company/${code}`, payload);
}
function* handleUpdateKybRequest(payload) {
  try {
    const response = yield call(updateKybRequest, payload);
    if (response) {
      yield put({
        type: UPDATE_KYBREQUESTS_SUCCESS,
        data: response.data,
      });

      yield put(delay(200));
      yield put({
        type: RESET_BLOCK_KYBREQUESTS,
        blockType: 'updateKybRequest',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: UPDATE_KYBREQUESTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function approveDisapproveKYBRequest(payload) {
  return await Axios.put('admin/company/review', payload);
}
function* handleApproveDisapproveKYBRequest({ payload }) {
  try {
    const response = yield call(approveDisapproveKYBRequest, payload);

    if (response) {
      yield put({
        type: APPROVE_DISAPPROVE_KYB_SUCCESS,
        data: response.data,
      });

      toast(response.message, { type: 'success' });

      yield put({
        type: RESET_BLOCK_KYBREQUESTS,
        blockType: 'approveDisapproveKYBRequest',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: APPROVE_DISAPPROVE_KYB_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getRequiredDocuments({ payload }) {
  return await Axios.get(`compliances/${payload}/get-docs`);
}
function* handleGetRequiredDocuments(payload) {
  try {
    const response = yield call(getRequiredDocuments, payload);
    if (response) {
      yield put({
        type: GET_REQUIRED_DOCUMENTS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
      yield put({
        type: RESET_BLOCK_KYBREQUESTS,
        blockType: 'getRequiredDocuments',
      });
    }

    yield put({
      type: GET_REQUIRED_DOCUMENTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function submitRequiredDocuments({ payload }) {
  return await Axios.patch(`compliances/${payload.company}/update-docs`, payload);
}
function* handleSubmitRequiredDocuments(payload) {
  try {
    const response = yield call(submitRequiredDocuments, payload);
    if (response) {
      yield put({
        type: SUBMIT_DOCUMENTS_SUCCESS,
        data: response.data,
      });
      toast(response.message, { type: 'success' });

      yield delay(200);
      yield put({
        type: RESET_BLOCK_KYBREQUESTS,
        blockType: 'submitDocuments',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: SUBMIT_DOCUMENTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSingleComplience({ payload }) {
  return await Axios.get(`/compliances/${payload}`);
}
function* handleGetSingleComplience(payload) {
  try {
    const response = yield call(getSingleComplience, payload);
    if (response) {
      yield put({
        type: GET_SINGLE_COMPLIENCE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_SINGLE_COMPLIENCE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function reviewCompanyDocuments({ payload }) {
  return await Axios.post(`/onboarding/documents/review`, payload);
}
function* handleReviewCompanyDocuments(payload) {
  try {
    const response = yield call(reviewCompanyDocuments, payload);
    if (response) {
      yield put({
        type: REVIEW_COMPANY_DOCUMENTS_SUCCESS,
        data: response.data,
      });

      toast(response.message, { type: 'success' });

      yield put({
        type: RESET_BLOCK_KYBREQUESTS,
        blockType: 'reviewCompanyDocuments',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: REVIEW_COMPANY_DOCUMENTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(UPDATE_KYBREQUESTS_REQUEST, handleUpdateKybRequest),
  takeLatest(GET_SINGLE_COMPLIENCE_REQUEST, handleGetSingleComplience),
  takeLatest(GET_KYBREQUESTS_REQUEST, handleGetKybRequest),
  takeLatest(GET_SINGLE_KYBREQUESTS_REQUEST, handleGetSingleKybRequest),
  takeLatest(APPROVE_DISAPPROVE_KYB_REQUEST, handleApproveDisapproveKYBRequest),
  takeLatest(GET_REQUIRED_DOCUMENTS_REQUEST, handleGetRequiredDocuments),
  takeLatest(SUBMIT_DOCUMENTS_REQUEST, handleSubmitRequiredDocuments),
  takeLatest(REVIEW_COMPANY_DOCUMENTS_REQUEST, handleReviewCompanyDocuments),
]);

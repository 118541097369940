import { useAccordionButton } from 'react-bootstrap';
import cs from 'classnames';

function ContextAwareToggle({ children, eventKey, handleClick, className }) {
  const decoratedOnClick = useAccordionButton(eventKey, () => handleClick(eventKey));

  return (
    <div onClick={decoratedOnClick} className={cs('cursor', { [className]: className })}>
      {children}
    </div>
  );
}
export default ContextAwareToggle;

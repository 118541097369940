import axios from 'axios';
import Cookies from 'js-cookie';

const baseURL = process.env.REACT_APP_BASE_URL;

const getToken = (name = 'mrdr-token') => {
  return Cookies.get(name);
};

const Axios = axios.create({
  baseURL,
  withCredentials: false,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

Axios.interceptors.request.use(
  async (config) => {
    const token = getToken('mrdr-token');
    if (token) config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => Promise.reject(error),
);

Axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error && error.response && error.response.status === 401) {
      Cookies.remove('mrdr-token');
      setTimeout(() => {
        window.location.href = '/';
      }, 1000);
    }else {
      return Promise.reject(error);
    }
  },
);

export { Axios };

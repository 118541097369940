import { ArrowLeft, CancelIcon } from 'assets/icons';
import cs from 'classnames';
import Loading from 'components/UI/Loading';
import './styles.scss';

const ModalContainer = ({
  onCancel,
  onConfirm,
  title,
  lg,
  xl,
  subTitle,
  loading,
  children = null,
  actionBtnText = 'Submit',
  style,
  goBack = null,
  cancelBtn = 'Cancel',
  disabled,
}) => {
  return (
    <div>
      <div className="confirm-modal-overlay" onClick={onCancel} />
      <div className="confirm-modal-wrapper position-relative">
        <div
          className="confirm-modal-body p-0"
          style={{ ...style, width: lg ? '592px' : xl ? 'fit-content' : '432px' }}
        >
          {!goBack && (
            <i onClick={onCancel} className="cancel-icon">
              <CancelIcon stroke="#ffffff" style={{ padding: '2px' }} />
            </i>
          )}

          {!!goBack && (
            <div className="back-btn text-white" onClick={goBack}>
              <ArrowLeft color="#ffffff" width="12" height="12" /> <div>Back</div>
            </div>
          )}

          <div className="w-100 px-4 pt-4 pb-3 text-white">
            <div
              className={cs('title text-white', lg || xl ? 'large' : 'small', {
                ['pt-3']: goBack,
              })}
            >
              {typeof title === 'string' ? (
                <h5 className="mb-0 text-white">{title}</h5>
              ) : (
                title
              )}

              <p>{subTitle}</p>
            </div>
          </div>

          <div className="w-100 border-top py-3">
            <div className="w-100 px-4 children">{children}</div>
          </div>

          <div className="w-100 border-top py-3">
            <div className="d-flex align-items-center gap-2 w-100 px-4">
              <button
                disabled={loading}
                onClick={onCancel}
                className={cs('btn border xs ms-auto', { ['w-100']: !lg })}
              >
                {cancelBtn}
              </button>
              <button
                disabled={loading || disabled}
                onClick={onConfirm}
                className={cs('btn green xs', { ['w-100']: !lg })}
              >
                {loading ? <Loading color="#D28B28" size={18} /> : actionBtnText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModalContainer;

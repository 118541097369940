import FileView from 'components/UI/FileView';
import { Col, Row } from 'react-bootstrap';
import DocumentInfo from './DocumentInfo';

const Documents = ({ documents }) => {
  return (
    <div className="content">
      <div className="card-modal-body" style={{ color: '#ffffff' }}>
        <div className="information-wrapper">
          {documents?.map((document, index) => {
            if (!document?.submitted) return null;
            if (document?.type === 'file') {
              return (
                <FileView
                  key={index}
                  label={document?.name}
                  fileName={document?.asset?.name}
                  fileUrl={document?.asset?.url}
                  action={'view'}
                />
              );
            } else
              return (
                <Row className="mb-3 align-items-center">
                  <Col md={7}>
                    <DocumentInfo
                      key={`id-${index}`}
                      title={document?.name}
                      value={document?.number}
                    />
                  </Col>
                </Row>
              );
          })}
        </div>
      </div>
    </div>
  );
};

export default Documents;

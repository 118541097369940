export const GET_COMPANY_REQUEST = 'GET_COMPANY_REQUEST';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_ERROR = 'GET_COMPANY_ERROR';

export const GET_COUNTRY_REQUEST = 'GET_COUNTRY_REQUEST';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_ERROR = 'GET_COUNTRY_ERROR';

export const GET_STATE_REQUEST = 'GET_STATE_REQUEST';
export const GET_STATE_SUCCESS = 'GET_STATE_SUCCESS';
export const GET_STATE_ERROR = 'GET_STATE_ERROR';

export const GET_SINGLE_COMPANY_REQUEST = 'GET_SINGLE_COMPANY_REQUEST';
export const GET_SINGLE_COMPANY_SUCCESS = 'GET_SINGLE_COMPANY_SUCCESS';
export const GET_SINGLE_COMPANY_ERROR = 'GET_SINGLE_COMPANY_ERROR';

export const ONBOARDING_BUSINESS_INFORMATION_REQUEST =
  'ONBOARDING_BUSINESS_INFORMATION_REQUEST';
export const ONBOARDING_BUSINESS_INFORMATION_SUCCESS =
  'ONBOARDING_BUSINESS_INFORMATION_SUCCESS';
export const ONBOARDING_BUSINESS_INFORMATION_ERROR =
  'ONBOARDING_BUSINESS_INFORMATION_ERROR';

export const ONBOARDING_BUSINESS_ADDRESS_REQUEST = 'ONBOARDING_BUSINESS_ADDRESS_REQUEST';
export const ONBOARDING_BUSINESS_ADDRESS_SUCCESS = 'ONBOARDING_BUSINESS_ADDRESS_SUCCESS';
export const ONBOARDING_BUSINESS_ADDRESS_ERROR = 'ONBOARDING_BUSINESS_ADDRESS_ERROR';

export const ONBOARDING_BUSINESS_DOC_REQUEST = 'ONBOARDING_BUSINESS_DOC_REQUEST';
export const ONBOARDING_BUSINESS_DOC_SUCCESS = 'ONBOARDING_BUSINESS_DOC_SUCCESS';
export const ONBOARDING_BUSINESS_DOC_ERROR = 'ONBOARDING_BUSINESS_DOC_ERROR';

export const GET_COMPANY_STATS_REQUEST = 'GET_COMPANY_STATS_REQUEST';
export const GET_COMPANY_STATS_SUCCESS = 'GET_COMPANY_STATS_SUCCESS';
export const GET_COMPANY_STATS_ERROR = 'GET_COMPANY_STATS_ERROR';

export const GET_BANK_ACCOUNT_REQUEST = 'GET_BANK_ACCOUNT_REQUEST';
export const GET_BANK_ACCOUNT_SUCCESS = 'GET_BANK_ACCOUNT_SUCCESS';
export const GET_BANK_ACCOUNT_ERROR = 'GET_BANK_ACCOUNT_ERROR';

export const UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_ERROR = 'UPDATE_COMPANY_ERROR';

export const REVIEW_COMPANY_REQUEST = 'REVIEW_COMPANY_REQUEST';
export const REVIEW_COMPANY_SUCCESS = 'REVIEW_COMPANY_SUCCESS';
export const REVIEW_COMPANY_ERROR = 'REVIEW_COMPANY_ERROR';

export const FUND_WIDTH_CARDS_REQUEST = 'FUND_WIDTH_CARDS_REQUEST';
export const FUND_WIDTH_CARDS_SUCCESS = 'FUND_WIDTH_CARDS_SUCCESS';
export const FUND_WIDTH_CARDS_ERROR = 'FUND_WIDTH_CARDS_ERROR';

export const RESET_BLOCK_COMPANY = 'RESET_BLOCK_COMPANY';

export const RESET_FLAGS_COMPANY = 'RESET_FLAGS_COMPANY';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getCompany: { ...block },
  getSingleCompany: { ...block },
  getStatistics: { ...block },
  getBankAccount: { ...block, data: {} },
  updateCompany: { ...block },
  reviewCompany: { ...block },
  onboardingBusinessInformation: { ...block },
  onboardingBusinessAddress: { ...block },
  onboardingBusinessDoc: { ...block },
  fundCreate: { ...block },
  getCountry: { ...block },
  getState: { ...block },
};

export const CompaniesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_REQUEST:
      return { ...state, getCompany: { ...state.getCompany, loading: true } };
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        getCompany: {
          ...state.getCompany,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_COMPANY_ERROR:
      return {
        ...state,
        getCompany: { ...state.getCompany, loading: false, error: action.error },
      };

    case GET_SINGLE_COMPANY_REQUEST:
      return { ...state, getSingleCompany: { ...state.getSingleCompany, loading: true } };
    case GET_SINGLE_COMPANY_SUCCESS:
      return {
        ...state,
        getSingleCompany: {
          ...state.getSingleCompany,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SINGLE_COMPANY_ERROR:
      return {
        ...state,
        getSingleCompany: {
          ...state.getSingleCompany,
          loading: false,
          error: action.error,
        },
      };

    case GET_COUNTRY_REQUEST:
      return { ...state, getCountry: { ...state.getCountry, loading: true } };
    case GET_COUNTRY_SUCCESS:
      return {
        ...state,
        getCountry: {
          ...state.getCountry,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_COUNTRY_ERROR:
      return {
        ...state,
        getCountry: { ...state.getCountry, loading: false, error: action.error },
      };

    case GET_STATE_REQUEST:
      return { ...state, getState: { ...state.getState, loading: true } };
    case GET_STATE_SUCCESS:
      return {
        ...state,
        getState: {
          ...state.getState,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_STATE_ERROR:
      return {
        ...state,
        getState: { ...state.getState, loading: false, error: action.error },
      };

    case ONBOARDING_BUSINESS_INFORMATION_REQUEST:
      return {
        ...state,
        onboardingBusinessInformation: {
          ...state.onboardingBusinessInformation,
          loading: true,
        },
      };
    case ONBOARDING_BUSINESS_INFORMATION_SUCCESS:
      return {
        ...state,
        onboardingBusinessInformation: {
          ...state.onboardingBusinessInformation,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case ONBOARDING_BUSINESS_INFORMATION_ERROR:
      return {
        ...state,
        onboardingBusinessInformation: {
          ...state.onboardingBusinessInformation,
          loading: false,
          error: action.error,
        },
      };

    case ONBOARDING_BUSINESS_ADDRESS_REQUEST:
      return {
        ...state,
        onboardingBusinessAddress: { ...state.onboardingBusinessAddress, loading: true },
      };
    case ONBOARDING_BUSINESS_ADDRESS_SUCCESS:
      return {
        ...state,
        onboardingBusinessAddress: {
          ...state.onboardingBusinessAddress,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case ONBOARDING_BUSINESS_ADDRESS_ERROR:
      return {
        ...state,
        onboardingBusinessAddress: {
          ...state.onboardingBusinessAddress,
          loading: false,
          error: action.error,
        },
      };

    case ONBOARDING_BUSINESS_DOC_REQUEST:
      return {
        ...state,
        onboardingBusinessDoc: { ...state.onboardingBusinessDoc, loading: true },
      };
    case ONBOARDING_BUSINESS_DOC_SUCCESS:
      return {
        ...state,
        onboardingBusinessDoc: {
          ...state.onboardingBusinessDoc,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case ONBOARDING_BUSINESS_DOC_ERROR:
      return {
        ...state,
        onboardingBusinessDoc: {
          ...state.onboardingBusinessDoc,
          loading: false,
          error: action.error,
        },
      };

    case GET_COMPANY_STATS_REQUEST:
      return { ...state, getStatistics: { ...state.getStatistics, loading: true } };
    case GET_COMPANY_STATS_SUCCESS:
      return {
        ...state,
        getStatistics: {
          ...state.getStatistics,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_COMPANY_STATS_ERROR:
      return {
        ...state,
        getStatistics: { ...state.getStatistics, loading: false, error: action.error },
      };

    case GET_BANK_ACCOUNT_REQUEST:
      return { ...state, getBankAccount: { ...state.getBankAccount, loading: true } };
    case GET_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        getBankAccount: {
          ...state.getBankAccount,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_BANK_ACCOUNT_ERROR:
      return {
        ...state,
        getBankAccount: { ...state.getBankAccount, loading: false, error: action.error },
      };

    case UPDATE_COMPANY_REQUEST:
      return { ...state, updateCompany: { ...state.updateCompany, loading: true } };
    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        updateCompany: {
          ...state.updateCompany,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case UPDATE_COMPANY_ERROR:
      return {
        ...state,
        updateCompany: { ...state.updateCompany, loading: false, error: action.error },
      };

    case REVIEW_COMPANY_REQUEST:
      return { ...state, reviewCompany: { ...state.reviewCompany, loading: true } };
    case REVIEW_COMPANY_SUCCESS:
      return {
        ...state,
        reviewCompany: {
          ...state.reviewCompany,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case REVIEW_COMPANY_ERROR:
      return {
        ...state,
        reviewCompany: { ...state.reviewCompany, loading: false, error: action.error },
      };

    case FUND_WIDTH_CARDS_REQUEST:
      return { ...state, fundCreate: { ...state.fundCreate, loading: true } };
    case FUND_WIDTH_CARDS_SUCCESS:
      return {
        ...state,
        fundCreate: {
          ...state.fundCreate,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case FUND_WIDTH_CARDS_ERROR:
      return {
        ...state,
        fundCreate: { ...state.fundCreate, loading: false, error: action.error },
      };

    //reset block with flag and data
    case RESET_BLOCK_COMPANY:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };

    //reset only flags(block)
    case RESET_FLAGS_COMPANY:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};

import * as React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as AppLogo } from '../../../assets/logos/appLogo.svg';
import './style.less';

const VitrineSidebar = ({ message, imageComponent, widthLink }) => {
  return (
    <div className="vitrinesb-wrapper">
      <div className="pb-4">
        <Link to="/">
          <AppLogo className="app-logo" />
        </Link>

        <p className="message">{message}</p>
        {widthLink && (
          <Link className="link" to="/">
            what do i need?
          </Link>
        )}
      </div>

      {imageComponent && imageComponent}
    </div>
  );
};

export default VitrineSidebar;

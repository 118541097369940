import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import { EyeInvisibleOutlined, EyeOutlined, DownOutlined } from '@ant-design/icons';
import { Col, Form } from 'react-bootstrap';
import { Popover } from 'react-tiny-popover';
import './styles.scss';
import { countryCode } from '../../../utils/countryCode';

const CustomInput = ({
  md = 12,
  label = '',
  type = 'text',
  wrapperClass = '',
  error = '',
  isAmount = false,
  className,
  xs,
  defaultValue,
  isNumber = false,
  setIsOtherPopoverOpen = () => null,
  getTypeVal = () => null,
  existAction,
  isUpdate,
  ...res
}) => {
  const [show, setShow] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const handleTogglePopover = () => {
    if (isUpdate) return;
    setIsPopoverOpen(!isPopoverOpen);
  };
  useEffect(() => {
    setIsOtherPopoverOpen(isPopoverOpen);
  }, [isPopoverOpen]);
  const [amountType, setAmountType] = useState('');
  const [actionList, setActionList] = useState([]);
  const sign = ['NGN'];
  // const sign = ['NGN', 'USD', 'EUR', 'GBP'];

  const getCurrencySymbol = (val) => {
    switch (val) {
      case 'NGN':
        return '₦';
      case 'USD':
        return '$';
      case 'EUR':
        return '€';
      case 'GBP':
        return '£';
    }
  };

  useEffect(() => {
    if (isNumber) {
      setAmountType(existAction ? existAction : countryCode[0].dial_code);
      setActionList(countryCode.sort((a, b) => a.code > b.code));
    }
    if (isAmount) {
      setAmountType(existAction ? existAction : sign[0]);
      setActionList(sign);
    }
  }, [isNumber, isAmount]);

  useEffect(() => {
    if (amountType) {
      getTypeVal(amountType);
    }
  }, [amountType]);

  useEffect(() => {
    if (!amountType) {
      if (isNumber) {
        getTypeVal(countryCode[0].dial_code);
      }
      if (isAmount) {
        getTypeVal(sign[0]);
      }
    }
  }, [existAction]);
  const handleSelectType = (val) => () => {
    setAmountType(val);
  };
  const Actions = () => {
    return (
      <div
        className={cs('actions-dialog border-full', {
          ['isInput']: isNumber || isAmount,
        })}
      >
        {actionList.map((l, i) => {
          if (isAmount) {
            return (
              <div onClick={handleSelectType(l)} className="actionLink" key={i}>
                {getCurrencySymbol(l)}
              </div>
            );
          }
          return (
            <div
              onClick={handleSelectType(l.dial_code)}
              className={cs('actionLink', {
                ['active-action']:
                  amountType.replace('+', '') === l.dial_code.replace('+', ''),
              })}
              key={i}
            >
              {l.flag} {l.name} ({l.dial_code})
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Form.Group
      as={Col}
      md={md}
      xs={xs}
      className={cs('groupWrapper', { [wrapperClass]: wrapperClass })}
    >
      {label && <Form.Label>{label}</Form.Label>}

      <Form.Control
        defaultValue={defaultValue}
        type={type === 'password' ? (show ? 'text' : 'password') : type}
        md={md}
        className={cs({ [className]: className }, { ['amount']: isAmount || isNumber })}
        {...res}
      />
      {type === 'password' && (
        <>
          <div className="view-password" onClick={() => setShow(!show)}>
            {!show ? <EyeInvisibleOutlined /> : <EyeOutlined />}
          </div>
        </>
      )}
      {/* <span className={cs('amount-icon', { ['hideLabel']: !label })}>
          $ <DownOutlined />
        </span> */}
      {(isAmount || isNumber) && (
        <Popover
          isOpen={isPopoverOpen}
          align="start"
          positions={['bottom', 'left']} // preferred positions by priority
          content={<Actions />}
          onClickOutside={handleTogglePopover}
        >
          <div
            onClick={handleTogglePopover}
            className={cs('amount-icon selector cursor', { ['hideLabel']: !label })}
          >
            <span
              className={cs(
                'amount-sign borderRight',
                { ['currencySymbol']: isAmount },
                { ['not-select']: isUpdate },
              )}
            >
              {isAmount ? getCurrencySymbol(amountType) : amountType}{' '}
              <DownOutlined style={{ fontSize: 8 }} />
            </span>
          </div>
        </Popover>
      )}

      {error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
    </Form.Group>
  );
};
export default CustomInput;

import { Download02, StatusIcon } from 'assets/icons';
import { getFormattedDate } from 'utils/helper';
import { ICONS } from '.';
import './styles.scss';

const Preview = ({ doc }) => {
  const handleDownload = (e) => {
    e.preventDefault();
    const link = document.createElement('a');
    link.href = doc?.asset?.url ?? doc?.url?.url;
    link.download = doc?.asset?.name ?? doc?.url?.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div className="fileUpload groupWrapper cursor">
      <div className="py-3 px-3 rounded-4 d-flex justify-content-between uploaded-file-container">
        <div className="d-flex  align-items-center">
          <img src={ICONS['text/csv']} alt="icon" style={{ height: 28 }} />
          <div className="ms-3">
            <p className="mb-1 p-0 m-0 uploaded-file-container-name">
              {doc?.name ?? doc?.url?.name}
            </p>
            {doc?.type !== 'file' && (
              <p className="mb-1 p-0 m-0 uploaded-file-container-name">{doc?.number}</p>
            )}
            <p className="uploaded-file-container-details gap-1 d-flex align-items-center p-0 m-0">
              <span className="d-flex align-items-center gap-1">
                <StatusIcon />
                {doc.status}{' '}
                {getFormattedDate(doc?.asset?.created_at ?? doc?.url?.created_at)}
              </span>{' '}
            </p>
          </div>
        </div>
        {doc?.type === 'file' && (
          <div className="d-flex gap-2">
            <Download02 stroke="#fff" onClick={handleDownload} />
            {/* <TrashIcon stroke="#fff" /> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Preview;

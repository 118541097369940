/* eslint-disable no-console */
import { ReactComponent as Clock } from 'assets/icons/clock.svg';
import Loading from 'components/UI/Loading';
import SpaceWrapper from 'components/UI/SpaceWrapper';
import { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { ReactComponent as BalanceIcon } from '../../assets/icons/balance.svg';
import { ReactComponent as Bank } from '../../assets/icons/bank.svg';
import { ReactComponent as BeneficiaryIcon } from '../../assets/icons/beneficiere.svg';
import { ReactComponent as BusinessIcon } from '../../assets/icons/business.svg';
import { ReactComponent as CreateBudgetIcon } from '../../assets/icons/createBudget.svg';
import { ReactComponent as Avatar } from '../../assets/icons/User.svg';
import Onboarding from './Onboarding';
import Overview from './Overview';
import './styles.scss';

const Dashboard = () => {
  const { user } = useSelector(({ auth }) => auth);
  const {
    getCompany: { data: companyData = {}, success },
  } = useSelector(({ companies }) => companies);
  const {
    getBeneficiaries: { data: beneficiariesData = {} },
    addBeneficiaryBank: { success: addbankSuccess },
    getBeneficiaryBank: { data: getbankData = {} },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const uInfo = JSON.parse(localStorage.getItem('uInfo'));
  const onboardingStaus = JSON.parse(localStorage.getItem('onBoardingStatus'));

  const [userName, setUserName] = useState('');
  const [company, setCompany] = useState({});
  const [isOnboarding, setIsOnboarding] = useState('');

  const [menus, setMenus] = useState([]);

  useMemo(() => {
    if (uInfo?.role === 'admin') {
      setMenus([
        {
          title: 'Add business details',
          info: 'Submit your business information and KYC',
          icon: <BusinessIcon />,
          isActive: true,
          isComplete: !!companyData.businessType,
        },
        {
          title: 'Add beneficiaries',
          icon: <BeneficiaryIcon />,
          withAction: false,
          isActive: false,
          isComplete: false,
        },
        {
          title: 'Create your first budget',
          icon: <CreateBudgetIcon />,
          withAction: false,
          isActive: false,
          isComplete: false,
        },
        {
          title: 'Fund your balance',
          icon: <BalanceIcon />,
          withAction: false,
          isActive: false,
          isComplete: false,
        },
        
      ]);
    } else if (['employee', 'manager'].includes(uInfo?.role)) {
      setMenus([
        {
          title: 'Add personal information',
          info: 'Submit your personal details',
          icon: <Avatar />,
          isActive: true,
          isComplete: !!user?.data?.user?.dob,
        },
        {
          title: 'Add bank information',
          info: 'Submit your bank account details',
          icon: <Bank />,
          withAction: false,
          isActive: false,
          isComplete: false,
        },
      ]);
    }
  }, [uInfo?.role]);

  useEffect(() => {
    if (uInfo?.role === 'admin') {
      setIsOnboarding(
        onboardingStaus &&
          ['pending', 'rejected'].includes(onboardingStaus.onboardingStatus),
      );
      localStorage.setItem(
        'overviewInfo',
        JSON.stringify({
          businessDetails: {},
          addBeneficiaries: {},
          fundBalance: {},
          firstBudget: {},
        }),
      );
    } else if (['employee', 'manager'].includes(uInfo?.role)) {
      localStorage.setItem(
        'overviewInfo',
        JSON.stringify({
          kycInfo: {},
          bankInfo: {},
        }),
      );
    }
  }, [company]);

  useEffect(() => {
    const processStep = localStorage.getItem('setStep');
    if (uInfo?.role === 'admin') {
      if (beneficiariesData?.beneficiaries?.length) {
        [0, 1, 2, 3].forEach((element) => {
          menus[element] = {
            ...menus[element],
            isComplete: element < +processStep,
            isActive: element === +processStep,
          };
        });
        setMenus([...menus]);
        return;
      }
      if (
        companyData.businessType &&
        success &&
        companyData &&
        ['pending', 'rejected'].includes(companyData.onboardingStatus)
      ) {
        menus[0] = {
          ...menus[0],
          isComplete: !!companyData.businessType,
          isActive: !companyData.businessType,
        };
        menus[1] = {
          ...menus[1],
          isComplete: !companyData.businessType,
          isActive: !!companyData.businessType,
        };
        setMenus([...menus]);
      }
    }
  }, [success, beneficiariesData]);

  useEffect(() => {
    const processStep = localStorage.getItem('setStep');
    if (['employee', 'manager'].includes(uInfo?.role)) {
      if (user?.data?.user?.dob) {
        [0, 1].forEach((element) => {
          menus[element] = {
            ...menus[element],
            isComplete: element < +processStep,
            isActive: element === +processStep,
          };
        });
        setMenus([...menus]);
      }
      if (getbankData[0]?.accountName || addbankSuccess) {
        menus[0] = {
          ...menus[0],
          isComplete: true,
          isActive: false,
        };
        menus[1] = {
          ...menus[1],
          isComplete: true,
          isActive: false,
        };
        setMenus([...menus]);
      }
    }
  }, [uInfo?.role, user?.data?.user?.dob, getbankData[0]?.accountName, addbankSuccess]);

  useEffect(() => {
    const processStep = localStorage.getItem('setStep');
    setMenus(
      menus?.map((m, i) => {
        if (i === +processStep)
          return {
            ...m,
            isActive: true,
            isComplete: true,
          };

        if (i <= +processStep)
          return {
            ...m,
            isActive: false,
            isComplete: true,
          };

        return {
          ...m,
          isActive: false,
          isComplete: false,
        };
      }),
    );
  }, []);

  const handleOpacity = () => {
    const activeIndex = menus?.findIndex((menu) => menu.isActive);
    if (uInfo?.role === 'admin') {
      switch (activeIndex) {
        case 0:
          return 0;
        case 1:
          return 25;
        case 2:
          return 50;
        case 3:
          return 75;
      }
    } else if (['employee', 'manager'].includes(uInfo?.role)) {
      switch (activeIndex) {
        case 0:
          return 0;
        case 1:
          return 50;
        default:
          return 100;
      }
    }
  };

  useEffect(() => {
    if (user.hasOwnProperty('data')) {
      setUserName(user?.data.user.firstName || 'User');
      setCompany(user?.data.user.company || {});
    }
  }, [user]);

  // if (!onboardingStaus) return <Loading isPage color="#D28B28" />;

  return (
    <div className="dashboard-wrapper">
      {/*//TODO: NotificationModal testing view*/}
      {/*<NotificationModal />*/}
      <Container>
        <Overview company={company} />
      </Container>
    </div>
  );
};
export default Dashboard;

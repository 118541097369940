import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Popover } from 'react-tiny-popover';
import cs from 'classnames';
import { ReactComponent as Logo } from 'assets/logos/smallLogo.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as Bell } from 'assets/icons/bell.svg';
import { ReactComponent as Line } from 'assets/icons/line.svg';
import { UserOutlined, LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import User from 'assets/photos/avatar2.png';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'redux/actions/AuthAction';
import './styles.scss';
import SearchInput from '../UI/SearchInput';
import { toggleAction } from 'redux/actions/ToggleAction';
import CustomPopover from 'components/UI/Popover';

const Sidebar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { toggle } = useSelector(({ toggle }) => toggle);
  const { user = {} } = useSelector(({ auth }) => auth);
  const {
    getBeneficiaryBank: { data: bankData },
  } = useSelector(({ beneficiaries }) => beneficiaries);
  const [userName, setUserName] = useState('B');
  const uInfo = JSON.parse(localStorage.getItem('uInfo'));
  useEffect(() => {
    if (user.hasOwnProperty('data')) {
      setUserName(user?.data?.user.firstName || 'there');
    }
  }, [user]);

  const locationArray = location.pathname.split('/');
  const [isPopoverOpen, setIsPopoverOpen] = useState();
  // status === 'active'
  const Actions = () => {
    const onLogout = () => {
      localStorage.clear();
      dispatch(logout());
    };

    const onNavigateHandler = (screenType) => () => {
      history.push(screenType);
    };
    return (
      <div className="actions-dialog">
        <div onClick={onNavigateHandler('/profile')} className="actionLink">
          <UserOutlined /> Profile
        </div>
        <div
          onClick={onNavigateHandler({
            pathname: '/profile',
            state: { type: 'settings' },
          })}
          className="actionLink"
        >
          <SettingOutlined /> Settings
        </div>
        <div onClick={onLogout} className="actionLink">
          <LogoutOutlined /> Log out
        </div>
      </div>
    );
  };

  const handleTogglePopover = () => setIsPopoverOpen(!isPopoverOpen);

  return (
    <>
      <nav id="sidebarMenu" className="collapse d-lg-block sidebar collapse">
        <div className="position-sticky">
          <div className={cs('top-navbar list-group list-group-flush mt-4')}>
            {/* <div  */}
            {/* className={cs('top-navbar fixed')}  */}
            {/* // expand="lg" */}
            {/* > */}
            {/* <Navbar.Collapse id="responsive-navbar-nav"> */}
            {/* <Nav className="me-auto my-2 my-lg-0"> */}
            <NavLink
              to="/"
              className="nav-link margin-top-50"
              exact
              activeClassName="is-active"
            >
              Overview
            </NavLink>
            {/* <NavLink
                    to="/companies"
                    className="nav-link"
                    exact
                    activeClassName="is-active"
                  >
                    Companies
                  </NavLink> */}
            <NavLink
              to="/compliance"
              className={cs('nav-link', {
                ['is-active']: locationArray[1] === 'compliance',
              })}
              exact
              activeClassName="is-active"
            >
              Compliance
            </NavLink>

            <NavLink
              to="/companies"
              className="nav-link"
              exact
              activeClassName="is-active"
            >
              Companies
            </NavLink>
            <NavLink
              to="/employees"
              className="nav-link"
              exact
              activeClassName="is-active"
            >
              Employees
            </NavLink>
            <NavLink
              to="/transactions"
              className="nav-link"
              exact
              activeClassName="is-active"
            >
              Transactions
            </NavLink>
            <NavLink to="/budgets" className="nav-link" exact activeClassName="is-active">
              Budgets
            </NavLink>
            {/* <NavLink to="/demo-request" className="nav-link" exact activeClassName="is-active">
                  Demo Requests
                  </NavLink> */}
            {/* </Nav> */}
            {/* </Navbar.Collapse> */}
            {/* </div> */}
            {/* <div style={{bottom: 0, position: 'fixed', display: 'flex'}}>
                    <Ball style={{color: '#ffffff'}}/>
                    <Line/>
                    <Search/>
                    <Logo/>
                  </div> */}

            <div
              className="d-flex right-side align-items-center"
              style={{
                bottom: 0,
                position: 'fixed',
                alignSelf: 'center',
                marginBottom: '25px',
              }}
            >
              <div className="me-3">
                <Bell />
              </div>
              <Line className="me-3" />
              <div className="user">
                <CustomPopover
                  showPopover={isPopoverOpen}
                  placement="top-start"
                  id="invoice-schedule"
                  zIndex="9999"
                  content={<Actions />}
                  clickOutside={handleTogglePopover}
                >
                  <div onClick={handleTogglePopover} className="cursor">
                    {userName && (
                      <div className="user-icon-menu">
                        <span>{userName.charAt(0)}</span>
                      </div>
                    )}
                    {/*<img src={User} className="user-icon" />*/}
                  </div>
                </CustomPopover>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
export default Sidebar;

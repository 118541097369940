import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import DatePicker from './DatePicker';
import { selectDateRnage } from 'redux/actions/OverviewAction';
import { useDispatch } from 'react-redux';
import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const Range = ({ handleOptionClick, handleToggleRangePopover }) => {
  const dispatch = useDispatch();
  const [isRangeCustomPopover, setIsRangeCustomPopover] = useState(false);

  const handleToggleRangeCustomPopover = () => {
    setIsRangeCustomPopover(!isRangeCustomPopover);
  };
  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const handleDateSelect = () => {
    const rangeDate =
      dateState[0].startDate === dateState[dateState.length - 1].endDate
        ? dayjs(dateState[0].startDate).format('DD MMM, YYYY')
        : `${dayjs(dateState[0].startDate).format('DD MMM, YYYY')} - ${dayjs(
            dateState[dateState.length - 1].endDate,
          ).format('DD MMM, YYYY')}`;
    handleOptionClick(rangeDate, dateState)();
    handleToggleRangeCustomPopover();
  };
  return (
    <OutsideClickHandler onOutsideClick={handleToggleRangePopover}>
      <div className="actions-dialog">
        {isRangeCustomPopover ? (
          <div>
            <div className="d-flex align-items-center justify-content-between pt-2">
              <ArrowLeftOutlined
                className="px-3 cursor"
                onClick={handleToggleRangeCustomPopover}
              />
              <CloseOutlined className="px-3 cursor" onClick={handleToggleRangePopover} />
            </div>
            <DatePicker
              handleDateSelect={handleDateSelect}
              state={dateState}
              setState={setDateState}
            />
          </div>
        ) : (
          <>
            <div className="actionLink" onClick={handleOptionClick('Today')}>
              Today
            </div>
            <div className="actionLink" onClick={handleOptionClick('Last 7 days')}>
              Last 7 days
            </div>
            <div className="actionLink" onClick={handleOptionClick('Last 30 days')}>
              Last 30 days
            </div>
            <div className="actionLink" onClick={handleToggleRangeCustomPopover}>
              Custom date range
            </div>
          </>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default Range;

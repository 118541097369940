import { Avatar, Tooltip } from 'antd';
import ImgCard from 'components/UI/ImgCard';
import BadgeType from 'components/UI/Table/BadgeType';
import DottedBadge from 'components/UI/Table/DottedBadgeType';
import DoubleStringType from 'components/UI/Table/DoubleStringType';
import ModifyTable from 'components/UI/Table/ModifyTable';
import StringType from 'components/UI/Table/StringType';
import { memo } from 'react';
import { cellType } from 'utils/helper';

const RenderComponent = ({
  column,
  row,
  value,
  type,
  selectedId,
  setSelectedId,
  setKebabId,
  kebabId,
  index,
  onEditClick,
  onDeleteClick,
  customClass,
  onDownloadClick,
  onShareClick,
  onCheckboxClick,
  modify,
  onClick,
  popoverAction,
  stringType,
}) => {
  switch (column?.type) {
    case cellType.STRING:
      return (
        <StringType
          value={value}
          withIcon={column.withIcon}
          withNumber={column.withNumber}
          withImg={column.withImg}
          link={column.link}
          stringType={stringType}
          type={type}
          setSelectedId={setSelectedId}
          selectedId={selectedId}
          index={index}
          onClick={onClick}
          isDropDown={column.isDropDown}
          withColor={column.withColor}
          columnName={column?.id}
          truncate={column?.truncate}
          truncateLength={column?.truncateLength}
        />
      );
    case cellType.STRING_BOLD:
      return (
        <StringType
          withColor={column.withColor}
          value={value}
          withIcon={column.withIcon}
          withNumber={column.withNumber}
          link={column.link}
          fontBold
          type={type}
          setSelectedId={setSelectedId}
          selectedId={selectedId}
          index={index}
          onClick={onClick}
          isDropDown={column.isDropDown}
          columnName={column?.id}
        />
      );
    case cellType.DOUBLE_STRING:
      return (
        <DoubleStringType
          value={value}
          fontBold
          withColor={column.withColor}
          withImg={column.withImg}
          truncate={column?.truncate}
        />
      );

    case cellType.STRING_BADGE:
      return (
        <StringType
          value={value}
          withIcon={column.withIcon}
          withNumber={column.withNumber}
          link={column.link}
          type={type}
          setSelectedId={setSelectedId}
          selectedId={selectedId}
          index={index}
          dottedBadge={false}
          onClick={onClick}
          isDropDown={column.isDropDown}
          withColor={column.withColor}
          columnName={column?.id}
        />
      );
    case cellType.MODIFY:
      return (
        <ModifyTable
          value={row}
          modify={row.modify}
          onEditClick={onEditClick}
          onDeleteClick={onDeleteClick}
          customClass={customClass}
          onShareClick={onShareClick}
          onCheckboxClick={onCheckboxClick}
          withModify={modify}
          onDownloadClick={onDownloadClick}
          withEdit={column.withEdit}
          withDownload={column.withDownload}
          withDelete={column.withDelete}
          withShare={column.withShare}
          withCheckbox={column.withCheckbox}
          withPopover={column.withPopover}
          custom={column.custom}
          setKebabId={setKebabId}
          kebabId={kebabId}
          index={index}
          columnId={column.id}
          popoverAction={popoverAction}
        />
      );
    case cellType.BADGE:
      return <BadgeType value={value} />;
    case cellType.DOUBLE_BADGE:
      return (
        <div className="d-flex align-items-center gap-2">
          {value.map((item, index) => (
            <BadgeType value={item} key={index} />
          ))}
        </div>
      );
    case cellType.DOTTED_BADGE:
      return <DottedBadge value={value} />;
    case cellType.AVATAR:
      return (
        <Tooltip title={element}>
          <div className="avatarStyle">
            <div className="text">{(value || '').substring(0, 2)}</div>
          </div>
        </Tooltip>
      );
    case cellType.AVATAR_LIST:
      return (
        <Avatar.Group maxCount={3}>
          {value?.map((element, i) => (
            <Tooltip title={element.title} key={i}>
              <Avatar className="img-avatar">
                <ImgCard initials={element.initials} />
              </Avatar>
            </Tooltip>
          ))}
        </Avatar.Group>
      );
    case cellType.FILE:
      return value;
    default:
      return <span>Enter Valid type</span>;
  }
};
export default memo(RenderComponent);

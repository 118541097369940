export const FILE_UPLOAD_REQUEST = 'FILE_UPLOAD_REQUEST';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR';

export const RESET_BLOCK_CARD = 'RESET_BLOCK_CARD';

export const RESET_FLAGS_CARD = 'RESET_FLAGS_CARD';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  fileUpload: { ...block },
};

export const ProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILE_UPLOAD_REQUEST:
      return {
        ...state,
        fileUpload: { ...state.fileUpload, loading: true },
      };
    case FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        fileUpload: {
          ...state.fileUpload,
          loading: false,
          success: true,
          error: '',
        },
      };
    case FILE_UPLOAD_ERROR:
      return {
        ...state,
        fileUpload: {
          ...state.fileUpload,
          loading: false,
          error: action.error,
        },
      };
    //reset block with flag and data
    case RESET_BLOCK_CARD:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...initialState[action.blockType],
        },
      };

    //reset only flags(block)
    case RESET_FLAGS_CARD:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};

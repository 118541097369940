import React from 'react';
import { CSVLink } from 'react-csv';
import { FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import './styles.scss';

const ExportDropdown = ({
  csvData,
  handleExport,
  exportPDF,
  exportLoading,
  handleExportPlatform,
  csvPlatform,
  csvFileName = 'Transaction Statement.csv',
}) => {
  const handleOnclick = () => {
    handleExport();
    exportPDF();
  };

  if (exportLoading)
    return (
      <div className="p-2 export-wrap">
        <div className="spinner-3 mx-auto" style={{ width: '60px' }}></div>
      </div>
    );
  return (
    <div className="p-2 export-wrap">
      <CSVLink
        data={csvData}
        onClick={handleExport}
        className="download-file mb-2"
        filename={csvFileName}
      >
        <FileExcelOutlined className="mr-3" />
        Export as CSV
      </CSVLink>
      {exportPDF && (
        <div className="download-file" onClick={handleOnclick}>
          <FilePdfOutlined className="mr-3" />
          Export as PDF
        </div>
      )}

      {csvPlatform && (
        <>
          <CSVLink
            data={csvPlatform.xero}
            onClick={() => handleExportPlatform('xero')}
            className="download-file mt-2 mb-2"
            filename={`Bujeti Statement Xero.csv`}
          >
            <FileExcelOutlined className="mr-3" />
            Export for Xero
          </CSVLink>
          <CSVLink
            data={csvPlatform.quickbooks}
            onClick={() => handleExportPlatform('quickbooks')}
            className="download-file mb-2"
            filename={`Bujeti Statement QuickBooks.csv`}
          >
            <FileExcelOutlined className="mr-3" />
            Export for Quickbooks
          </CSVLink>
        </>
      )}
    </div>
  );
};

export default ExportDropdown;

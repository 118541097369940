import TopBar from 'components/TopBar';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import FileUpload from 'components/UI/FileUpload';
import Input from 'components/UI/Input';
import { useDebounce } from 'hooks/useDebounce';
import React, { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCompanies } from 'redux/actions/CompaniesAction';
import {
  getRequiredDocuments,
  submitRequiredDocuments,
} from 'redux/actions/KybRequestsAction';

const DocumentUpload = () => {
  const { push } = useHistory();
  const [uploadingFile, setUploadingFile] = useState();
  const [documents, setDocuments] = useState({ asset: undefined, text: '' });
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 200);

  const {
    getRequiredDocuments: {
      data: requireDoc = [],
      loading: loadingDoc,
      success: successDoc,
      error,
    },
    submitDocuments: { loading: loadingSubmit, success: submitSuccess },
  } = useSelector(({ Kybrequest }) => Kybrequest);

  const {
    getCompany: { data: { companies = [] } = {}, loading, success },
  } = useSelector(({ companies }) => companies);

  const handleSelectChange = (val) => {
    // console.log(val);
    setValue(val);
  };

  useEffect(() => {
    const companyss = companies?.find((option) =>
      option.name.toLowerCase().includes(debouncedValue.toLowerCase()),
    );
    if (!companyss && debouncedValue)
      dispatch(getCompanies({ search: debouncedValue?.toLowerCase() }));
  }, [debouncedValue]);

  const mappedComp = companies?.map((item) => item.code);

  const companiesList = useMemo(() => {
    return companies?.map((item) => ({
      label: item.name,
      value: item.code,
    }));
  }, [success, mappedComp]);

  const mappedDoc = requireDoc?.map((item) => item.documentId);
  const documentTypes = useMemo(() => {
    return requireDoc?.map((item) => ({
      label: item.documentType,
      value: item.documentId,
      format: item.format,
    }));
  }, [successDoc, error, mappedDoc]);

  useEffect(() => {
    dispatch(getCompanies());
  }, []);

  useEffect(() => {
    if (documents?.company?.value) {
      setDocuments({ ...documents, documentType: '', asset: undefined, text: '' });
      dispatch(getRequiredDocuments(documents?.company?.value));
    }
  }, [documents?.company?.value]);

  const handleSubmit = () => {
    const payload = {
      company: documents?.company?.value,
      documentId: documents?.documentType?.value,
      text: documents?.text ? documents?.text : undefined,
      asset: documents?.asset ? documents?.asset : undefined,
    };
    dispatch(submitRequiredDocuments(payload));
  };

  useEffect(() => {
    if (submitSuccess) {
      setDocuments({ ...documents, asset: undefined, text: '' });
    }
  }, [submitSuccess]);

  return (
    <div className="kybrequest-wrapper">
      <TopBar
        largeTitle
        breadcrumbs={[
          { title: 'Compliance', action: () => push('/compliance') },
          {
            title: 'Document Upload',
            action: () => {
              null;
            },
          },
        ]}
        isRightBar={true}
      />
      <div className="mt-3 w-100 border border-light bg-black p-3 rounded-2">
        <div className="d-flex align-items-center text-white fs-3 fw-bold">
          Document Upload
        </div>
        <div className="mt-4 d-flex align-items-center gap-3">
          <Row className="mb-3 w-50">
            <CustomSelect
              label="Select a Business"
              name="company"
              placeholder="Select a Business"
              onInputChange={handleSelectChange}
              onChange={(val) => setDocuments({ ...documents, company: val })}
              value={documents.company}
              options={companiesList}
              isLoading={loading && !debouncedValue}
              isDisabled={loading && !debouncedValue}
            />
          </Row>

          <Row className="mb-3 w-50">
            <CustomSelect
              label="Select document type"
              name="documentId"
              placeholder="Select a document"
              onChange={(val) => setDocuments({ ...documents, documentType: val })}
              value={documents.documentType}
              options={documentTypes}
              isLoading={loadingDoc}
              isDisabled={loadingDoc}
            />
          </Row>
        </div>
        <div>
          <Row className="mb-3 w-50">
            {documents?.documentType?.format === 'TEXT' ? (
              <CustomInput
                type="text"
                label="Document Number"
                placeholder="Enter the number"
                name="businessID"
                onChange={(val) => setDocuments({ ...documents, text: val.target.value })}
                value={documents.text}
              />
            ) : documents?.documentType?.format === 'FILE' ? (
              <FileUpload
                companyCode={documents?.company?.value}
                label="Upload document"
                placeholder="Upload document (PDF, JPEG, PNG)"
                name="file"
                onChange={({ assetCode }) =>
                  setDocuments({ ...documents, asset: assetCode })
                }
                uploadedFile={documents.asset}
                setUploadingFile={setUploadingFile}
                uploadingFile={uploadingFile}
              />
            ) : null}
          </Row>
        </div>
        {documents?.documentType?.format && (
          <div className="mt-3" onClick={handleSubmit}>
            <CustomButton disabled={loadingSubmit}>Submit</CustomButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentUpload;

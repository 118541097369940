import {
  CREATE_BUDGET_REQUEST,
  GET_BUDGET_REQUEST,
  UPDATE_BUDGET_REQUEST,
  GET_SINGLE_BUDGET_REQUEST,
  GET_BUDGET_STATS_REQUEST,
  BUDGET_BENEFICIARIES_REQUEST,
  BUDGET_CARD_REQUEST,
  CREATE_NEW_BENEFICIARIES_REQUEST,
  RESET_BLOCK_BUDGET,
  DELETE_BUDGET_REQUEST,
  GET_SINGLE_BENEFICIARY_REQUEST,
  GET_SINGLE_TRANSACTION_REQUEST,
} from '../reducers/BudgetsReducer';

export const getBudgets = (payload) => ({ type: GET_BUDGET_REQUEST, payload });
export const deleteBudgets = (payload) => ({ type: DELETE_BUDGET_REQUEST, payload });
export const createBudgets = (payload) => ({ type: CREATE_BUDGET_REQUEST, payload });
export const updateBudgets = (payload) => ({ type: UPDATE_BUDGET_REQUEST, payload });
export const getSingleBudgets = (payload) => ({
  type: GET_SINGLE_BUDGET_REQUEST,
  payload,
});
export const getBudgetStats = (payload) => ({ type: GET_BUDGET_STATS_REQUEST, payload });
export const budgetBeneficiariesAPI = (payload) => ({
  type: BUDGET_BENEFICIARIES_REQUEST,
  payload,
});
export const budgetCard = (payload) => ({
  type: BUDGET_CARD_REQUEST,
  payload,
});
export const createNewBeneficiaries = (payload) => ({
  type: CREATE_NEW_BENEFICIARIES_REQUEST,
  payload,
});

export const resetBlockBudget = (payload) => ({
  type: RESET_BLOCK_BUDGET,
  payload,
});

export const getSingleBeneficiary = (payload) => ({
  type: GET_SINGLE_BENEFICIARY_REQUEST,
  payload,
});

export const getSingleTransactions = (payload) => ({
  type: GET_SINGLE_TRANSACTION_REQUEST,
  payload,
});
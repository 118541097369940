import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

// we will connect our reducers here
import { AuthReducer } from './AuthReducer';
import { BudgetsReducer } from './BudgetsReducer';
import { VendorsReducer } from './VendorsReducer';
import { AssetsReducer } from './AssetsReducer';
import { CardReducer } from './CardReducer';
import { BeneficiariesReducer } from './BeneficiariesReducer';
import { TransactionReducer } from './TransactionsReducer';
import { CompaniesReducer } from './CompaniesReducer';
import { ProfileReducer } from './ProfileReducer';
import { OverviewReducer } from './OverviewReducer';
import { KybRequestReducer } from './KybRequestsReducer';
import { UserReducer } from './UsersReducer';
import { ToggleReducer } from './ToggleReducer';
import { PaymentReducer } from './PaymentReducer';

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: AuthReducer,
    budget: BudgetsReducer,
    vendors: VendorsReducer,
    assets: AssetsReducer,
    card: CardReducer,
    beneficiaries: BeneficiariesReducer,
    transaction: TransactionReducer,
    companies: CompaniesReducer,
    profile: ProfileReducer,
    overview: OverviewReducer,
    Kybrequest: KybRequestReducer,
    user: UserReducer,
    toggle: ToggleReducer,
    payments: PaymentReducer,
  });

const createRootReducer = (history) => (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }
  return appReducer(history)(state, action);
};

export default createRootReducer;

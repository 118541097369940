export const SELECT_DATE_RANGE = 'SELECT_DATE_RANGE';

export const GET_STATISTICS_REQUEST = 'GET_STATISTICS_REQUEST';
export const GET_STATISTICS_SUCCESS = 'GET_STATISTICS_SUCCESS';
export const GET_STATISTICS_ERROR = 'GET_STATISTICS_ERROR';      

export const GET_CHART_REQUEST = 'GET_CHART_REQUEST';
export const GET_CHART_SUCCESS = 'GET_CHART_SUCCESS';
export const GET_CHART_ERROR = 'GET_CHART_ERROR';      

export const RESET_BLOCK_STATISTICS = 'RESET_BLOCK_STATISTICS';
export const RESET_FLAGS_STATISTICS = 'RESET_FLAGS_STATISTICS';

const block = {
  loading: false,
  error: '',
  success: false,
};
const today = new Date();
const initialState = {
  selectedDateRange: { range: [{ startDate: today, endDate: today }], val: 'Today' },
  getStatistic: { ...block },
  getChart: { ...block },
};

export const OverviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_DATE_RANGE:
      return { ...state, selectedDateRange: action.payload };
    //reset only flags(block)
    case GET_STATISTICS_REQUEST:
      return { ...state, getStatistic: { ...state.getStatistic, loading: true } };
    case GET_STATISTICS_SUCCESS:
      return {
        ...state,
        getStatistic: {
          ...state.getStatistic,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_STATISTICS_ERROR:
      return {
        ...state,
        getStatistic: {
          ...state.getStatistic,
          loading: false,
          error: action.error,
        },
      };

    case GET_CHART_REQUEST:
      return { ...state, getChart: { ...state.getChart, loading: true } };
    case GET_CHART_SUCCESS:
      return {
        ...state,
        getChart: {
          ...state.getChart,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_CHART_ERROR:
      return {
        ...state,
        getChart: {
          ...state.getChart,
          loading: false,
          error: action.error,
        },
      };

    
    //reset block with flag and data
    case RESET_BLOCK_STATISTICS:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...initialState[action.blockType],
        },
      };

    //reset only flags(block)
    case RESET_FLAGS_STATISTICS:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };


    default:
      return state;
  }
};

import {
  GET_COMPANY_REQUEST,
  GET_COUNTRY_REQUEST,
  GET_STATE_REQUEST,
  GET_COMPANY_STATS_REQUEST,
  GET_BANK_ACCOUNT_REQUEST,
  UPDATE_COMPANY_REQUEST,
  REVIEW_COMPANY_REQUEST,
  ONBOARDING_BUSINESS_INFORMATION_REQUEST,
  ONBOARDING_BUSINESS_ADDRESS_REQUEST,
  ONBOARDING_BUSINESS_DOC_REQUEST,
  GET_SINGLE_COMPANY_REQUEST,
  FUND_WIDTH_CARDS_REQUEST,
} from '../reducers/CompaniesReducer';

export const getCompanies = (payload) => ({ type: GET_COMPANY_REQUEST, payload });

export const getCountries = (payload) => ({ type: GET_COUNTRY_REQUEST, payload });

export const getState = (payload) => ({ type: GET_STATE_REQUEST, payload });

export const getCompanyStats = (payload) => ({
  type: GET_COMPANY_STATS_REQUEST,
  payload,
});

export const getSingleCompany = (payload) => ({
  type: GET_SINGLE_COMPANY_REQUEST,
  payload,
});


export const getBankAccount = (payload) => ({ type: GET_BANK_ACCOUNT_REQUEST, payload });
export const onboardingBusinessInformation = (payload) => ({ type: ONBOARDING_BUSINESS_INFORMATION_REQUEST, payload });
export const onboardingBusinessAddress = (payload) => ({ type: ONBOARDING_BUSINESS_ADDRESS_REQUEST, payload });
export const onboardingBusinessDoc = (payload) => ({ type: ONBOARDING_BUSINESS_DOC_REQUEST, payload });
export const updateCompany = (payload) => ({ type: UPDATE_COMPANY_REQUEST, payload });
export const reviewCompany = (payload) => ({ type: REVIEW_COMPANY_REQUEST, payload });
export const fundCreateAPI = (payload) => ({ type: FUND_WIDTH_CARDS_REQUEST, payload });

import CardButton from 'components/CardButton';
import CustomButton from 'components/UI/CustomButton';
import React from 'react';
import { Container, Row } from 'react-bootstrap';

import './styles.scss';

const NoData = ({
  headerText,
  bodyText,
  onClickHandler,
  buttonLabel,
  withButton = true,
}) => {
  return (
    <Container className="no-data">
      <Row>
        <span className="textHeader">{headerText}</span>
      </Row>
      <Row>
        <span className="subHeaderText">{bodyText}</span>
      </Row>
      {withButton && (
        <Row className="mt-4">
          <CustomButton fullWidth onClick={onClickHandler}>
            {buttonLabel}
          </CustomButton>
        </Row>
      )}
    </Container>
  );
};
export default NoData;

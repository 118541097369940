import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Axios } from 'api/axios';
import { getSimplifiedError } from 'utils/error';
import {
  CREATE_CARD_REQUEST,
  CREATE_CARD_SUCCESS,
  CREATE_CARD_ERROR,
  RESET_FLAGS_CARD,
  GET_CARD_REQUEST,
  GET_CARD_SUCCESS,
  GET_CARD_ERROR,
  DEACTIVATE_CARD_REQUEST,
  DEACTIVATE_CARD_SUCCESS,
  DEACTIVATE_CARD_ERROR,
  GET_SINGLE_CARD_ERROR,
  GET_SINGLE_CARD_SUCCESS,
  GET_SINGLE_CARD_REQUEST,
  FREEZE_UNFREEZE_CARD_REQUEST,
  FREEZE_UNFREEZE_CARD_SUCCESS,
  FREEZE_UNFREEZE_CARD_ERROR,
} from '../reducers/CardReducer';
import { toast } from 'react-toastify';

async function getCard(params = {}) {
  return await Axios.get('/cards/', { params });
}
function* handleGetCard({ query }) {
  try {
    const response = yield call(getCard, query);
    if (response) {
      yield put({
        type: GET_CARD_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_CARD,
        blockType: 'getCard',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_CARD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createCard(payload) {
  return await Axios.post('/cards', payload);
}
function* handleCreateCard({ payload }) {
  try {
    const response = yield call(createCard, payload);
    if (response) {
      yield put({
        type: CREATE_CARD_SUCCESS,
        data: response,
      });
      toast(response.data.message, { type: 'success' });
      yield put({
        type: RESET_FLAGS_CARD,
        blockType: 'createCard',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: CREATE_CARD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deactivateCard(payload) {
  return await Axios.post('/budgets/bdg_P96lAFthlYiVhAnvq', payload);
}
function* handleDeactivateCard({ payload }) {
  try {
    const response = yield call(deactivateCard, payload);
    if (response) {
      yield put({
        type: DEACTIVATE_CARD_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_CARD,
        blockType: 'deactivateCard',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: DEACTIVATE_CARD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function singleCard({ id }) {
  return await Axios.get(`/cards/${id}`);
}
function* handleSingleCard({ payload }) {
  try {
    const response = yield call(singleCard, payload);
    if (response) {
      yield put({
        type: GET_SINGLE_CARD_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_CARD,
        blockType: 'getSingleCard',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_SINGLE_CARD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function freezeUnfreeze({ code, type }) {
  return await Axios.post(`/cards/${code}/${type}`);
}
function* handleFreezeUnfreezeCard({ payload }) {
  try {
    const response = yield call(freezeUnfreeze, payload);
    if (response) {
      yield put({
        type: FREEZE_UNFREEZE_CARD_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_CARD,
        blockType: 'freezeUnfreezeCard',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: FREEZE_UNFREEZE_CARD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(GET_CARD_REQUEST, handleGetCard),
  takeLatest(CREATE_CARD_REQUEST, handleCreateCard),
  takeLatest(DEACTIVATE_CARD_REQUEST, handleDeactivateCard),
  takeLatest(GET_SINGLE_CARD_REQUEST, handleSingleCard),
  takeLatest(FREEZE_UNFREEZE_CARD_REQUEST, handleFreezeUnfreezeCard),
]);

import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Axios } from 'api/axios';
import { getSimplifiedError } from 'utils/error';
import {
  CREATE_BENEFICIARIES_REQUEST,
  CREATE_BENEFICIARIES_SUCCESS,
  CREATE_BENEFICIARIES_ERROR,
  RESET_FLAGS_BENEFICIARIE,
  GET_BENEFICIARIES_REQUEST,
  GET_BENEFICIARIES_SUCCESS,
  GET_BENEFICIARIES_ERROR,
  UPDATE_BENEFICIARIES_REQUEST,
  UPDATE_BENEFICIARIES_SUCCESS,
  UPDATE_BENEFICIARIES_ERROR,
  GET_INVITED_BENEFICIARIES_REQUEST,
  GET_INVITED_BENEFICIARIES_SUCCESS,
  GET_INVITED_BENEFICIARIES_ERROR,
  ADD_BENEFICIARIES_BANK_SUCCESS,
  ADD_BENEFICIARIES_BANK_ERROR,
  ADD_BENEFICIARIES_BANK_REQUEST,
  GET_BENEFICIARIES_BANK_SUCCESS,
  GET_BENEFICIARIES_BANK_ERROR,
  GET_BENEFICIARIES_BANK_REQUEST,
  SEARCH_RECIPIENTS_SUCCESS,
  SEARCH_RECIPIENTS_ERROR,
  SEARCH_RECIPIENTS_REQUEST,
} from '../reducers/BeneficiariesReducer';
import { toast } from 'react-toastify';
import {
  GET_SINGLE_BENEFICIARY_ERROR,
  GET_SINGLE_BENEFICIARY_REQUEST,
  GET_SINGLE_BENEFICIARY_SUCCESS,
  GET_SINGLE_TRANSACTION_ERROR,
  GET_SINGLE_TRANSACTION_REQUEST,
  GET_SINGLE_TRANSACTION_SUCCESS,
} from 'redux/reducers/BudgetsReducer';

async function getBeneficiaries(params = {}) {
  return await Axios.get('/beneficiaries/', { params });
}
function* handleGetBeneficiaries({ payload }) {
  try {
    const response = yield call(getBeneficiaries, payload);
    if (response) {
      yield put({
        type: GET_BENEFICIARIES_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_BENEFICIARIE,
        blockType: 'getBeneficiaries',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_BENEFICIARIES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function searchRecipients(params = {}) {
  return await Axios.get('/recipients/search', { params });
}
function* handlesearchRecipients({ payload }) {
  try {
    const response = yield call(searchRecipients, payload);
    if (response) {
      yield put({
        type: SEARCH_RECIPIENTS_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_BENEFICIARIE,
        blockType: 'searchRecipients',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: SEARCH_RECIPIENTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createBeneficiaries({ payload, code }) {
  return await Axios.post('/beneficiaries/', payload);
}
function* handleCreateBeneficiaries({ payload }) {
  try {
    const getPayload = { ...payload };
    delete payload.code;

    const response = yield call(createBeneficiaries, { payload, code: getPayload.code });
    if (response) {
      yield put({
        type: CREATE_BENEFICIARIES_SUCCESS,
        data: response,
      });
      toast(response.data.message, { type: 'success' });
      yield put({
        type: RESET_FLAGS_BENEFICIARIE,
        blockType: 'createBeneficiaries',
      });
      yield put({
        type: GET_BENEFICIARIES_REQUEST,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: CREATE_BENEFICIARIES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateBeneficiaries(payload) {
  return await Axios.put(`/beneficiaries/${payload.code}`, payload);
}
function* handleUpdateBeneficiaries({ payload }) {
  try {
    const response = yield call(updateBeneficiaries, payload);
    if (response) {
      yield put({
        type: UPDATE_BENEFICIARIES_SUCCESS,
        data: payload,
      });
      yield put({
        type: RESET_FLAGS_BENEFICIARIE,
        blockType: 'updateBeneficiaries',
      });
      yield put({ type: GET_BENEFICIARIES_REQUEST });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: UPDATE_BENEFICIARIES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSingleBeneficiaryAPI(params = {}) {
  return await Axios.get('/beneficiaries', { params });
}
function* handleGetSingleBeneficiaries({ payload }) {
  try {
    const response = yield call(getSingleBeneficiaryAPI, payload);
    if (response) {
      yield put({
        type: GET_SINGLE_BENEFICIARY_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_BENEFICIARIE,
        blockType: 'getSingleBeneficiary',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_SINGLE_BENEFICIARY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSingleTransactionAPI(params = {}) {
  return await Axios.get('/transactions/', { params });
}
function* handleGetSingleTransaction({ payload }) {
  try {
    const response = yield call(getSingleTransactionAPI, payload);
    if (response) {
      yield put({
        type: GET_SINGLE_TRANSACTION_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_BENEFICIARIE,
        blockType: 'getSingleTransaction',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_SINGLE_TRANSACTION_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getInvitedBeneficiaryDetails(code) {
  return await Axios.get('users/get-invited-user?hash=' + code);
}
function* handleGetInvitedBeneficiaryDetails({ payload }) {
  try {
    const response = yield call(getInvitedBeneficiaryDetails, payload);
    if (response) {
      yield put({
        type: GET_INVITED_BENEFICIARIES_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_BENEFICIARIE,
        blockType: 'getInvitedBeneficiaryDetails',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_INVITED_BENEFICIARIES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function addBeneficiaryBank(payload) {
  return await Axios.post(`/banks`, payload);
}
function* handleAddBeneficiaryBank({ payload }) {
  try {
    const response = yield call(addBeneficiaryBank, payload);
    if (response) {
      yield put({
        type: ADD_BENEFICIARIES_BANK_SUCCESS,
        data: response.data,
      });
      toast(response.message, { type: 'success' });
      yield put({
        type: RESET_FLAGS_BENEFICIARIE,
        blockType: 'addBeneficiaryBank',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: ADD_BENEFICIARIES_BANK_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getBeneficiaryBank() {
  return await Axios.get('/banks');
}
function* handleGetBeneficiaryBank({ payload }) {
  try {
    const response = yield call(getBeneficiaryBank, payload);
    if (response) {
      yield put({
        type: GET_BENEFICIARIES_BANK_SUCCESS,
        data: response.data,
      });
      yield put({
        type: RESET_FLAGS_BENEFICIARIE,
        blockType: 'getBeneficiaryBank',
      });
    }
  } catch (error) {
    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
    yield put({
      type: GET_BENEFICIARIES_BANK_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(GET_BENEFICIARIES_REQUEST, handleGetBeneficiaries),
  takeLatest(SEARCH_RECIPIENTS_REQUEST, handlesearchRecipients),
  takeLatest(GET_SINGLE_BENEFICIARY_REQUEST, handleGetSingleBeneficiaries),
  takeLatest(GET_SINGLE_TRANSACTION_REQUEST, handleGetSingleTransaction),
  takeLatest(CREATE_BENEFICIARIES_REQUEST, handleCreateBeneficiaries),
  takeLatest(UPDATE_BENEFICIARIES_REQUEST, handleUpdateBeneficiaries),
  takeLatest(GET_INVITED_BENEFICIARIES_REQUEST, handleGetInvitedBeneficiaryDetails),
  takeLatest(ADD_BENEFICIARIES_BANK_REQUEST, handleAddBeneficiaryBank),
  takeLatest(GET_BENEFICIARIES_BANK_REQUEST, handleGetBeneficiaryBank),
]);

import React, { useEffect, useState } from 'react';
import './style.less';
import TopBar from 'components/TopBar';
import NoData from 'components/NoData';
import Table from 'components/Table';
import { budgetsColumns, columns } from 'utils/mockData';
import { Col, Container, Row } from 'react-bootstrap';
import BudgetModal from 'components/BudgetModal';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'components/UI/Loading';
import { buildBudgetTableData } from '../../utils/helper';
import {
  CardType,
  CurrencyType, PaymentMethod, TransactionStatus,
} from 'components/FilterModal/FilterHelper';
import { getBudgets } from 'redux/actions/BudgetsAction';

const Budgets = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectBudget, setSelectBudget] = useState(null);
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const payerCode = query.get('search');
  const [filterData, setFilterData] = useState([
    { title: 'Amount', type: 'slider' },
    ...TransactionStatus,
    ...PaymentMethod,
    //...CurrencyType,
  ]);
  const [isFillData, setIsFillData] = useState(false);
  const {
    getBudget: { data: { meta = {} } = {}, data = [], loading },
  } = useSelector(({ budget }) => budget);
  const { page, total, hasMore, perPage } = meta;
  const { budgets = [] } = data;
  const {
    getBudget: { data: budgetData = {} },
  } = useSelector(({ budget }) => budget);

  const { user = {} } = useSelector(({ auth }) => auth);
  const role = user?.data?.user.role;

  const toggleHandler = () => {
    setIsOpen(!isOpen);
  };

  const handleRowClick = (row) => {
    setSelectBudget(row);
  };

  useEffect(() => {
    if (budgets.length > 0 && !isFillData) {
      setIsFillData(true);

      setTableData(buildBudgetTableData(budgets));

      const allBudgets = budgets.map(({ payer, }) => {
        return {
          value: payer ? payer.code : null,
          label: payer ? `${payer.firstName} ${payer.lastName}` : 'N/A',
          isSelected: false,
        };
      });
      const uniqueArray = allBudgets.filter(
        (v, i, a) => a.findIndex((v2) => v2.value === v.value) === i,
      );
      setFilterData([
        ...filterData,
        { title: 'Budget', list: data },
        { title: 'Owner/holder', list: uniqueArray },
      ]);
    }
  }, [budgets]);

  useEffect(() => {
    dispatch(getBudgets());
  }, []);

  const handleFilter = (query) => {
    dispatch(getBudgets(query));
  };
  

  if (loading) return <Loading isPage color="#D28B28" />;
  return (
    <div className="transactions-wrapper">
      <TopBar
        headerText={'Budgets'}
        number={tableData.length > 0 && tableData.length}
        addOnClick={toggleHandler}
        addIcon={false}
        inputPlaceholder="Search"
        filterData={filterData}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        handleFilterApply={handleFilter}
        withDate
      />
      {!tableData.length ? (
        <div className="innerWrapper">
          <NoData
            headerText="No budgets yet - We need to make some sales"
            withButton={false}
          />
        </div>
      ) : (
        <>
          <Container>
            <Row>
              <Col xs={12}>
                <Table
                  columns={budgetsColumns}
                  data={tableData}
                  // pagination
                  onRowClick={handleRowClick}
                  // hasMore={hasMore}
                  // currentPage={page}
                  // totalPage={Math.ceil(total / perPage)}
                />
              </Col>
            </Row>
          </Container>

          <BudgetModal
            selectBudget={selectBudget}
            setSelectBudget={setSelectBudget}
          />
        </>
      )}
    </div>
  );
};

export default Budgets;

import {
  ArrowLeftOutlined,
  CheckOutlined,
  CloseOutlined,
  CloudUploadOutlined,
} from '@ant-design/icons';
import { StatusType } from 'components/FilterModal/FilterHelper';
import ModalContainer from 'components/ModalContainer';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import Preview from 'components/UI/FileUpload/Preview';
import ItemInfo from 'components/UI/item-info/itemInfo';
import Loading from 'components/UI/Loading';
import CustomPills from 'components/UI/Pills';
import BadgeType from 'components/UI/Table/BadgeType';
import { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  approveDisapproveKYBRequests,
  getKybRequests,
  getSingleKybRequest,
  reviewCompanyDocuments,
} from 'redux/actions/KybRequestsAction';
import { RESET_BLOCK_COMPANY } from 'redux/reducers/CompaniesReducer';
import { buildDirectorsTableData, getFormattedDate } from 'utils/helper';
import { directorsColumns } from 'utils/mockData';
import BusinessOwner from './director-info';
import CustomPopover from 'components/UI/Popover';

const CompanyDetails = () => {
  const history = useHistory();

  const [search, setSearch] = useState('');
  const [filterData, setFilterData] = useState([...StatusType]);
  const [status, setStatus] = useState('verified');
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const [editCompany, setEditCompany] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isApprovePopoverOpen, setIsApprovePopoverOpen] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [directors, setDirectors] = useState(undefined);

  const { code } = useParams();

  const {
    getSingleKybRequest: { data: kybData, loading, success: getSuccess },
    approveDisapproveKYBRequest: {
      success: approved,
      loading: loadingApproval,
      error: rejected,
    },
    updateKybRequest: { success },
    reviewCompanyDocuments: { success: reviewSuccess, loading: reviewLoading },
  } = useSelector(({ Kybrequest }) => Kybrequest);

  useEffect(() => {
    if (getSuccess) {
      setIsApproving(false);
    }
  }, [getSuccess]);

  useEffect(() => {
    if (approved || reviewSuccess || code) {
      dispatch(getSingleKybRequest(code));
      if (approved || reviewSuccess) dispatch(getKybRequests());
    }
  }, [approved, rejected, reviewSuccess, code]);

  const approveDisapproveKYBRequest = (status) => {
    const payload = {
      decision: status,
      company: kybData.code,
    };
    dispatch(approveDisapproveKYBRequests(payload));
  };

  const edit = () => {
    setEditCompany(!editCompany);
  };

  useEffect(() => {
    if (success) {
      setEditCompany(false);
    }
  }, [success]);

  console.log(kybData);

  const handleDocumentUpload = () => {
    history.push('/compliance/document-upload');
  };

  const handleDirectors = (data) => {
    setDirectors(data);
  };

  const approveDocument = (documents, decision) => {
    setIsApproving(true);
    const payload = {
      documents,
      decision,
      reason: decision === 'rejected' ? 'Wrong document' : undefined,
    };

    dispatch(reviewCompanyDocuments(payload));
  };
  const handleButtonToggle = () => setIsPopoverOpen(!isPopoverOpen);
  const handleTogglePopover = () => setIsApprovePopoverOpen(!isApprovePopoverOpen);

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => {
            handleButtonToggle();
          }}
        >
          View
        </div>
      </div>
    );
  };

  const ListActions = () => {
    return (
      <div className="actions-dialog">
        {!kybData?.onboardingLevel && (
          <div
            className="actionLink"
            onClick={() => {
              handleTogglePopover();
              approveDisapproveKYBRequest('APPROVED');
            }}
          >
            Tier 1
          </div>
        )}
        {(!kybData?.onboardingLevel ||
          ['level_1'].includes(kybData?.onboardingLevel)) && (
          <div
            className="actionLink"
            onClick={() => {
              handleTogglePopover();
              approveDisapproveKYBRequest('ACCEPTED');
            }}
          >
            Tier 2
          </div>
        )}
        {['level_2'].includes(kybData?.onboardingLevel) && (
          <div
            className="actionLink"
            onClick={() => {
              handleTogglePopover();
              approveDisapproveKYBRequest('ACCEPTED');
            }}
          >
            Tier 3
          </div>
        )}
      </div>
    );
  };

  if (loading && !isApproving) return <Loading isPage color="#D28B28" />;

  return (
    <div className="px-3 pt-5 pb-3 company-details-wrapper">
      <div className="back-click pb-2" onClick={() => history.goBack(-1)}>
        <ArrowLeftOutlined />
        Go back
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <h1 className="headerText m-0">{kybData?.name}</h1>
        {kybData?.onboardingLevel !== 'level_3' && (
          <div className="d-flex gap-2">
            <button
              className="btn border"
              disabled={loadingApproval}
              onClick={() => approveDisapproveKYBRequest('REJECTED')}
            >
              Decline
            </button>

            <CustomPopover
              content={<ListActions />}
              showPopover={isApprovePopoverOpen}
              clickOutside={handleTogglePopover}
            >
              <button
                className="btn green"
                disabled={loadingApproval}
                onClick={handleTogglePopover}
              >
                Approve
              </button>
            </CustomPopover>
          </div>
        )}
      </div>

      <div className="details-container pt-4">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-8">
            <div className="details-section">
              <div className="mb-3">
                <ItemInfo
                  title="Status"
                  value={<BadgeType value={{ value: kybData?.status }} />}
                />
              </div>
              <div className="grid-cols-2 border-bottom pb-3">
                <ItemInfo title="Business Type" value={kybData?.businessType} />
                <ItemInfo title=" Website" value={kybData?.website} />
                <ItemInfo title="Email address" value={kybData?.contactEmail} />
                <ItemInfo
                  title="Phone  Number"
                  value={kybData?.phoneNumber?.localFormat}
                />
                <ItemInfo
                  title="Company Address"
                  value={
                    kybData?.address?.street
                      ? `${kybData?.address?.street}, ${kybData?.address?.city}, ${kybData?.address?.state}, ${kybData?.address?.country}`
                      : '-'
                  }
                />
                <ItemInfo
                  title="Registered Address"
                  value={
                    kybData?.registeredAddress?.street
                      ? `${kybData?.registeredAddress?.street}, ${kybData?.registeredAddress?.city}, ${kybData?.registeredAddress?.state}, ${kybData?.registeredAddress?.country}`
                      : '-'
                  }
                />
              </div>

              <div className="pt-3 grid-cols-4">
                {/* <ItemInfo title="BVN Number" value={kybData?.bvn} />
                <ItemInfo title="ID Type" value={kybData?.idType} />
                <ItemInfo title="ID Number" value={kybData?.idNumber} /> */}
                <ItemInfo
                  title="Sign up Date"
                  value={getFormattedDate(kybData?.created_at)}
                />
              </div>
            </div>
            <h6 className="mt-4 text-white">Directors</h6>
            <TopBar
              colClass
              showFilter
              showBarSearch
              withOutSearch
              inputPlaceholder="Search"
              filterData={filterData}
              handleFilterSelect={(updateVal) => {
                setFilterData(updateVal);
              }}
              searchVal={search}
              setSearchVal={setSearch}
            />
            <div
              className="details-section mt-3 text-white border border-dark rounded-3"
              style={{ backgroundColor: '#000' }}
            >
              <Table
                columns={directorsColumns}
                data={buildDirectorsTableData(kybData?.directors || [])}
                popoverAction={Actions}
                popoverState={isPopoverOpen}
                setPopoverState={setIsPopoverOpen}
                onRowClick={handleDirectors}
              />
            </div>
          </div>
          <div className="col-span-4">
            <div className="details-section text-white">
              <div className="d-flex align-items-center justify-content-between border-bottom pb-3">
                Documents{' '}
                <button className="btn border gap-2" onClick={handleDocumentUpload}>
                  <CloudUploadOutlined /> Upload
                </button>
              </div>

              <div className="pt-3">
                <div>
                  {kybData?.documents
                    ?.filter((item) => item.status === 'verifying')
                    ?.map((doc, index) => {
                      if (!doc?.submitted) return;
                      return (
                        <div
                          key={index}
                          className="d-flex align-items-center justify-content-between gap-4 mb-2"
                        >
                          <div className="w-100">
                            <Preview doc={doc} />
                          </div>
                          <p className="m-0 gap-3 d-flex">
                            <CheckOutlined
                              color="#039855"
                              style={{ color: '#039855' }}
                              className="cursor-pointer"
                              disabled={reviewLoading}
                              onClick={() => approveDocument([doc.document], 'approved')}
                            />{' '}
                            <CloseOutlined
                              color="#D92D20"
                              style={{ color: '#D92D20' }}
                              className="cursor-pointer"
                              disabled={reviewLoading}
                              onClick={() => approveDocument([doc.document], 'rejected')}
                            />
                          </p>
                        </div>
                      );
                    })}
                </div>
                <div className="my-3">
                  <CustomPills
                    labels={[
                      { label: 'Approved', value: 'verified' },
                      { label: 'Declined', value: 'rejected' },
                    ]}
                    status={status}
                    setStatus={setStatus}
                  />
                </div>

                <div>
                  {kybData?.documents
                    ?.filter((item) => item.status === status)
                    .map((doc, index) => {
                      if (!doc?.submitted) return;
                      return (
                        <div
                          key={index}
                          className="d-flex align-items-center justify-content-between gap-4 mb-2"
                        >
                          <div className="w-100">
                            <Preview doc={doc} />
                          </div>
                        </div>
                      );
                    })}

                  {kybData?.documents?.filter((item) => item.status === status).length ===
                    0 && <div className="text-center">No documents here.</div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BusinessOwner directors={directors} setDirectors={setDirectors} />
    </div>
  );
};

export default CompanyDetails;

import React, { useEffect, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import {
  PieChartOutlined,
  UserOutlined,
  CreditCardOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import AddIcon from 'assets/icons/AddIcon';
import { useHistory } from 'react-router-dom';
import DateRangeFilter from 'components/DateRangeFilter';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyStats } from 'redux/actions/CompaniesAction';

const SubHeader = ({ handleRefresh }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);
  const { selectedDateRange } = useSelector(({ overview }) => overview);
  const [isPopoverOpen, setIsPopoverOpen] = useState();
  const [selectedRange, setSelectedRange] = useState('Today');

  useEffect(() => {
    setSelectedRange(selectedDateRange?.val);
  }, [selectedDateRange.range]);

  const handleSelectRange = (title, date) => {
    const payload = {
      id: user?.data?.user?.company?.code,
      params: { from: date[0].startDate, to: date[0].endDate },
    };

    dispatch(getCompanyStats(payload));
    setSelectedRange(title);
  };

  const Actions = () => {
    const navigateHandler = (screenType) => () => {
      history.push({
        pathname: screenType,
        state: { isModal: true },
      });
    };
    return (
      <div className="actions-dialog">
        <div onClick={navigateHandler('/budgets')} className="actionLink">
          <PieChartOutlined /> Create a Budget
        </div>
        <div onClick={navigateHandler('/beneficiaries')} className="actionLink">
          <UserOutlined /> Add a Beneficiary
        </div>
        <div onClick={navigateHandler('/cards')} className="actionLink">
          <CreditCardOutlined /> Create a Card
        </div>
      </div>
    );
  };

  const handleTogglePopover = () => setIsPopoverOpen(!isPopoverOpen);

  return (
    <div className="d-md-flex align-items-center w-100 justify-content-between pt-5 pb-5">
      <h1 className="page-title">Overview</h1>
      <ul className="overview-action p-0">
        <li>
          <span className='secondary-color'>
            <DateRangeFilter
              handleSelectRange={handleSelectRange}
              selectedRange={selectedRange}
            />
          </span>
        </li>
        <li onClick={handleRefresh} className="cursor text-color">
          <UndoOutlined className="pe-2" />
          <span>Refresh data</span>
        </li>
      </ul>
    </div>
  );
};

export default SubHeader;

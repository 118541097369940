import {
  GET_TRANSACTIONS_REQUEST,
  GET_SINGLE_TRANSACTIONS_REQUEST,
} from '../reducers/TransactionsReducer';

export const getTransaction = (payload) => ({ type: GET_TRANSACTIONS_REQUEST, payload });

export const getSingleTransaction = (payload) => ({ type: GET_SINGLE_TRANSACTIONS_REQUEST, payload });

export const updateTransaction = (payload) => ({
  type: GET_SINGLE_TRANSACTIONS_REQUEST,
  payload,
});

export const SearchIcon = (props) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 13L10.1 10.1M11.6667 6.33333C11.6667 9.27885 9.27885 11.6667 6.33333 11.6667C3.38781 11.6667 1 9.27885 1 6.33333C1 3.38781 3.38781 1 6.33333 1C9.27885 1 11.6667 3.38781 11.6667 6.33333Z"
        stroke={props.stroke ? props.stroke : 'black'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SaveDiskIcon = (props) => {
  return (
    <svg
      width={props.width ?? '21'}
      height={props.height ?? '20'}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.33333 2.5V5.33333C6.33333 5.80004 6.33333 6.0334 6.42416 6.21166C6.50406 6.36846 6.63154 6.49594 6.78834 6.57584C6.9666 6.66667 7.19996 6.66667 7.66667 6.66667H13.3333C13.8 6.66667 14.0334 6.66667 14.2117 6.57584C14.3685 6.49594 14.4959 6.36846 14.5758 6.21166C14.6667 6.0334 14.6667 5.80004 14.6667 5.33333V3.33333M14.6667 17.5V12.1667C14.6667 11.7 14.6667 11.4666 14.5758 11.2883C14.4959 11.1315 14.3685 11.0041 14.2117 10.9242C14.0334 10.8333 13.8 10.8333 13.3333 10.8333H7.66667C7.19996 10.8333 6.9666 10.8333 6.78834 10.9242C6.63154 11.0041 6.50406 11.1315 6.42416 11.2883C6.33333 11.4666 6.33333 11.7 6.33333 12.1667V17.5M18 7.77124V13.5C18 14.9001 18 15.6002 17.7275 16.135C17.4878 16.6054 17.1054 16.9878 16.635 17.2275C16.1002 17.5 15.4001 17.5 14 17.5H7C5.59987 17.5 4.8998 17.5 4.36502 17.2275C3.89462 16.9878 3.51217 16.6054 3.27248 16.135C3 15.6002 3 14.9001 3 13.5V6.5C3 5.09987 3 4.3998 3.27248 3.86502C3.51217 3.39462 3.89462 3.01217 4.36502 2.77248C4.8998 2.5 5.59987 2.5 7 2.5H12.7288C13.1364 2.5 13.3402 2.5 13.5321 2.54605C13.7021 2.58688 13.8647 2.65422 14.0138 2.7456C14.182 2.84867 14.3261 2.9928 14.6144 3.28105L17.219 5.88562C17.5072 6.17387 17.6513 6.318 17.7544 6.48619C17.8458 6.63531 17.9131 6.79789 17.9539 6.96795C18 7.15976 18 7.36358 18 7.77124Z"
        stroke="#57534E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const HomeLine = (props) => {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.12"
        d="M2.5 8.80421C2.5 8.32558 2.5 8.08627 2.56169 7.86588C2.61633 7.67065 2.70614 7.48704 2.82669 7.32405C2.96278 7.14005 3.15168 6.99312 3.52949 6.69927L9.18141 2.30333C9.47418 2.07562 9.62057 1.96177 9.78221 1.918C9.92484 1.87938 10.0752 1.87938 10.2178 1.918C10.3794 1.96177 10.5258 2.07562 10.8186 2.30333L16.4705 6.69927C16.8483 6.99312 17.0372 7.14005 17.1733 7.32405C17.2939 7.48704 17.3837 7.67065 17.4383 7.86588C17.5 8.08627 17.5 8.32558 17.5 8.80421V14.8333C17.5 15.7667 17.5 16.2335 17.3183 16.59C17.1586 16.9036 16.9036 17.1585 16.59 17.3183C16.2335 17.5 15.7668 17.5 14.8333 17.5H5.16667C4.23325 17.5 3.76654 17.5 3.41002 17.3183C3.09641 17.1585 2.84144 16.9036 2.68166 16.59C2.5 16.2335 2.5 15.7667 2.5 14.8333V8.80421Z"
        fill={props.fill || '#79716B'}
      />
      <path
        d="M6.66667 14.1667H13.3333M9.18141 2.30336L3.52949 6.6993C3.15168 6.99315 2.96278 7.14008 2.82669 7.32408C2.70614 7.48707 2.61633 7.67068 2.56169 7.86591C2.5 8.0863 2.5 8.32561 2.5 8.80424V14.8334C2.5 15.7668 2.5 16.2335 2.68166 16.59C2.84144 16.9036 3.09641 17.1586 3.41002 17.3184C3.76654 17.5 4.23325 17.5 5.16667 17.5H14.8333C15.7668 17.5 16.2335 17.5 16.59 17.3184C16.9036 17.1586 17.1586 16.9036 17.3183 16.59C17.5 16.2335 17.5 15.7668 17.5 14.8334V8.80424C17.5 8.32561 17.5 8.0863 17.4383 7.86591C17.3837 7.67068 17.2939 7.48707 17.1733 7.32408C17.0372 7.14008 16.8483 6.99315 16.4705 6.6993L10.8186 2.30336C10.5258 2.07565 10.3794 1.9618 10.2178 1.91803C10.0752 1.87941 9.92484 1.87941 9.78221 1.91803C9.62057 1.9618 9.47418 2.07565 9.18141 2.30336Z"
        stroke={props.fill || '#79716B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const HamburgerIcon = (props) => {
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.5 6.99999L6.5 6.99999M16.5 1.99999L6.5 1.99999M16.5 12L6.5 12M3.16667 6.99999C3.16667 7.46023 2.79357 7.83332 2.33333 7.83332C1.8731 7.83332 1.5 7.46023 1.5 6.99999C1.5 6.53975 1.8731 6.16666 2.33333 6.16666C2.79357 6.16666 3.16667 6.53975 3.16667 6.99999ZM3.16667 1.99999C3.16667 2.46023 2.79357 2.83332 2.33333 2.83332C1.8731 2.83332 1.5 2.46023 1.5 1.99999C1.5 1.53975 1.8731 1.16666 2.33333 1.16666C2.79357 1.16666 3.16667 1.53975 3.16667 1.99999ZM3.16667 12C3.16667 12.4602 2.79357 12.8333 2.33333 12.8333C1.8731 12.8333 1.5 12.4602 1.5 12C1.5 11.5398 1.8731 11.1667 2.33333 11.1667C2.79357 11.1667 3.16667 11.5398 3.16667 12Z"
        stroke="#586068"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const GridIcon = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 2.5H3.83333C3.36662 2.5 3.13327 2.5 2.95501 2.59083C2.79821 2.67072 2.67072 2.79821 2.59083 2.95501C2.5 3.13327 2.5 3.36662 2.5 3.83333V7C2.5 7.46671 2.5 7.70007 2.59083 7.87833C2.67072 8.03513 2.79821 8.16261 2.95501 8.24251C3.13327 8.33333 3.36662 8.33333 3.83333 8.33333H7C7.46671 8.33333 7.70007 8.33333 7.87833 8.24251C8.03513 8.16261 8.16261 8.03513 8.24251 7.87833C8.33333 7.70007 8.33333 7.46671 8.33333 7V3.83333C8.33333 3.36662 8.33333 3.13327 8.24251 2.95501C8.16261 2.79821 8.03513 2.67072 7.87833 2.59083C7.70007 2.5 7.46671 2.5 7 2.5Z"
        stroke="#79716B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1667 2.5H13C12.5333 2.5 12.2999 2.5 12.1217 2.59083C11.9649 2.67072 11.8374 2.79821 11.7575 2.95501C11.6667 3.13327 11.6667 3.36662 11.6667 3.83333V7C11.6667 7.46671 11.6667 7.70007 11.7575 7.87833C11.8374 8.03513 11.9649 8.16261 12.1217 8.24251C12.2999 8.33333 12.5333 8.33333 13 8.33333H16.1667C16.6334 8.33333 16.8667 8.33333 17.045 8.24251C17.2018 8.16261 17.3293 8.03513 17.4092 7.87833C17.5 7.70007 17.5 7.46671 17.5 7V3.83333C17.5 3.36662 17.5 3.13327 17.4092 2.95501C17.3293 2.79821 17.2018 2.67072 17.045 2.59083C16.8667 2.5 16.6334 2.5 16.1667 2.5Z"
        stroke="#79716B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1667 11.6667H13C12.5333 11.6667 12.2999 11.6667 12.1217 11.7575C11.9649 11.8374 11.8374 11.9649 11.7575 12.1217C11.6667 12.2999 11.6667 12.5333 11.6667 13V16.1667C11.6667 16.6334 11.6667 16.8667 11.7575 17.045C11.8374 17.2018 11.9649 17.3293 12.1217 17.4092C12.2999 17.5 12.5333 17.5 13 17.5H16.1667C16.6334 17.5 16.8667 17.5 17.045 17.4092C17.2018 17.3293 17.3293 17.2018 17.4092 17.045C17.5 16.8667 17.5 16.6334 17.5 16.1667V13C17.5 12.5333 17.5 12.2999 17.4092 12.1217C17.3293 11.9649 17.2018 11.8374 17.045 11.7575C16.8667 11.6667 16.6334 11.6667 16.1667 11.6667Z"
        stroke="#79716B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 11.6667H3.83333C3.36662 11.6667 3.13327 11.6667 2.95501 11.7575C2.79821 11.8374 2.67072 11.9649 2.59083 12.1217C2.5 12.2999 2.5 12.5333 2.5 13V16.1667C2.5 16.6334 2.5 16.8667 2.59083 17.045C2.67072 17.2018 2.79821 17.3293 2.95501 17.4092C3.13327 17.5 3.36662 17.5 3.83333 17.5H7C7.46671 17.5 7.70007 17.5 7.87833 17.4092C8.03513 17.3293 8.16261 17.2018 8.24251 17.045C8.33333 16.8667 8.33333 16.6334 8.33333 16.1667V13C8.33333 12.5333 8.33333 12.2999 8.24251 12.1217C8.16261 11.9649 8.03513 11.8374 7.87833 11.7575C7.70007 11.6667 7.46671 11.6667 7 11.6667Z"
        stroke="#79716B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const FilterIcon = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
        stroke={props?.color ?? '#44403C'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const EditPencilIcon = (props) => {
  return (
    <svg
      width={props.width ? props.width : 16}
      height={props.height ? props.height : 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 11.9998L13.3332 12.7292C12.9796 13.1159 12.5001 13.3332 12.0001 13.3332C11.5001 13.3332 11.0205 13.1159 10.6669 12.7292C10.3128 12.3432 9.83332 12.1265 9.33345 12.1265C8.83359 12.1265 8.35409 12.3432 7.99998 12.7292M2 13.3332H3.11636C3.44248 13.3332 3.60554 13.3332 3.75899 13.2963C3.89504 13.2637 4.0251 13.2098 4.1444 13.1367C4.27895 13.0542 4.39425 12.9389 4.62486 12.7083L13 4.33316C13.5523 3.78087 13.5523 2.88544 13 2.33316C12.4477 1.78087 11.5523 1.78087 11 2.33316L2.62484 10.7083C2.39424 10.9389 2.27894 11.0542 2.19648 11.1888C2.12338 11.3081 2.0695 11.4381 2.03684 11.5742C2 11.7276 2 11.8907 2 12.2168V13.3332Z"
        stroke={props.stroke ? props.stroke : '#79716B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Download02 = (props) => {
  return (
    <svg
      width={props.width ? props.width : '20'}
      height={props.height ? props.height : '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.5 17.5H2.5M15 9.16667L10 14.1667M10 14.1667L5 9.16667M10 14.1667V2.5"
        stroke={props.stroke ? props.stroke : '#D28B28'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ChevronRight = (props) => {
  return (
    <svg
      width={props?.width ?? 20}
      height={props?.height ?? 20}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 18L15 12L9 6"
        stroke={props?.stroke ?? 'black'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ArrowUpIcon = (props) => {
  return (
    <svg
      width={props.width ? props.width : '20'}
      height={props.height ? props.height : '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.99999 15.8333V4.16666M9.99999 4.16666L4.16666 9.99999M9.99999 4.16666L15.8333 9.99999"
        stroke={props.stroke ? props.stroke : 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const StatusIcon = (props) => {
  return (
    <svg
      width={props.width ? props.width : '12'}
      height={props.height ? props.width : '12'}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99992 0.583008C3.45609 0.583008 0.583252 3.45585 0.583252 6.99967C0.583252 10.5435 3.45609 13.4163 6.99992 13.4163C10.5437 13.4163 13.4166 10.5435 13.4166 6.99967C13.4166 3.45585 10.5437 0.583008 6.99992 0.583008ZM10.0374 5.66215C10.2652 5.43435 10.2652 5.065 10.0374 4.8372C9.80959 4.60939 9.44024 4.60939 9.21244 4.8372L6.12492 7.92472L4.7874 6.5872C4.55959 6.35939 4.19025 6.35939 3.96244 6.5872C3.73463 6.815 3.73463 7.18435 3.96244 7.41215L5.71244 9.16215C5.94025 9.38996 6.30959 9.38996 6.5374 9.16215L10.0374 5.66215Z"
        fill={props.fill ?? '#12B76A'}
      />
    </svg>
  );
};

export const ClockIcon = (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2513_14228)">
        <path
          d="M6.99996 3.50033V7.00033L9.33329 8.16699M12.8333 7.00033C12.8333 10.222 10.2216 12.8337 6.99996 12.8337C3.7783 12.8337 1.16663 10.222 1.16663 7.00033C1.16663 3.77866 3.7783 1.16699 6.99996 1.16699C10.2216 1.16699 12.8333 3.77866 12.8333 7.00033Z"
          stroke="#57534E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2513_14228">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ArrowUpRightIcon = (props) => {
  return (
    <svg
      width={props.width ? props.width : 24}
      height={props.height ? props.height : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 18L18 6M18 6H10M18 6V14"
        stroke={props.stroke}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const TrashIcon = (props) => {
  return (
    <svg
      width={props.width ? props.width : '20'}
      height={props.height ? props.height : '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.3333 5.00008V4.33341C13.3333 3.39999 13.3333 2.93328 13.1517 2.57676C12.9919 2.26316 12.7369 2.00819 12.4233 1.8484C12.0668 1.66675 11.6001 1.66675 10.6667 1.66675H9.33333C8.39991 1.66675 7.9332 1.66675 7.57668 1.8484C7.26308 2.00819 7.00811 2.26316 6.84832 2.57676C6.66667 2.93328 6.66667 3.39999 6.66667 4.33341V5.00008M8.33333 9.58342V13.7501M11.6667 9.58342V13.7501M2.5 5.00008H17.5M15.8333 5.00008V14.3334C15.8333 15.7335 15.8333 16.4336 15.5608 16.9684C15.3212 17.4388 14.9387 17.8212 14.4683 18.0609C13.9335 18.3334 13.2335 18.3334 11.8333 18.3334H8.16667C6.76654 18.3334 6.06647 18.3334 5.53169 18.0609C5.06129 17.8212 4.67883 17.4388 4.43915 16.9684C4.16667 16.4336 4.16667 15.7335 4.16667 14.3334V5.00008"
        stroke={props.stroke ? props.stroke : '#586068'}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ArrowLeft = (props) => {
  return (
    <svg
      width={props?.width ?? '14'}
      height={props?.height ?? '14'}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.8333 6.99999H1.16663M1.16663 6.99999L6.99996 12.8333M1.16663 6.99999L6.99996 1.16666"
        stroke={props?.color ?? '#57534E'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CancelIcon = (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 1L1 13M1 1L13 13"
        stroke={props.stroke ?? '#586068'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DownloadArrowIcon = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.5 11.5V12.5C16.5 13.9001 16.5 14.6002 16.2275 15.135C15.9878 15.6054 15.6054 15.9878 15.135 16.2275C14.6002 16.5 13.9001 16.5 12.5 16.5H5.5C4.09987 16.5 3.3998 16.5 2.86502 16.2275C2.39462 15.9878 2.01217 15.6054 1.77248 15.135C1.5 14.6002 1.5 13.9001 1.5 12.5V11.5M13.1667 7.33333L9 11.5M9 11.5L4.83333 7.33333M9 11.5V1.5"
        stroke="#575855"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CheckIcon = (props) => {
  return (
    <svg
      width={props?.width ?? '10'}
      height={props?.height ?? '7'}
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 1L3.5 6.5L1 4"
        stroke={props.stroke ? props.stroke : '#586068'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Grid9Icon = (props) => {
  return (
    <svg
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 4.99998C14.4602 4.99998 14.8333 4.62688 14.8333 4.16665C14.8333 3.70641 14.4602 3.33331 14 3.33331C13.5398 3.33331 13.1667 3.70641 13.1667 4.16665C13.1667 4.62688 13.5398 4.99998 14 4.99998Z"
        fill="#A9A29D"
      />
      <path
        d="M14 10.8333C14.4602 10.8333 14.8333 10.4602 14.8333 9.99998C14.8333 9.53974 14.4602 9.16665 14 9.16665C13.5398 9.16665 13.1667 9.53974 13.1667 9.99998C13.1667 10.4602 13.5398 10.8333 14 10.8333Z"
        fill="#A9A29D"
      />
      <path
        d="M14 16.6666C14.4602 16.6666 14.8333 16.2935 14.8333 15.8333C14.8333 15.3731 14.4602 15 14 15C13.5398 15 13.1667 15.3731 13.1667 15.8333C13.1667 16.2935 13.5398 16.6666 14 16.6666Z"
        fill="#A9A29D"
      />
      <path
        d="M19.8333 4.99998C20.2936 4.99998 20.6667 4.62688 20.6667 4.16665C20.6667 3.70641 20.2936 3.33331 19.8333 3.33331C19.3731 3.33331 19 3.70641 19 4.16665C19 4.62688 19.3731 4.99998 19.8333 4.99998Z"
        fill="#A9A29D"
      />
      <path
        d="M19.8333 10.8333C20.2936 10.8333 20.6667 10.4602 20.6667 9.99998C20.6667 9.53974 20.2936 9.16665 19.8333 9.16665C19.3731 9.16665 19 9.53974 19 9.99998C19 10.4602 19.3731 10.8333 19.8333 10.8333Z"
        fill="#A9A29D"
      />
      <path
        d="M19.8333 16.6666C20.2936 16.6666 20.6667 16.2935 20.6667 15.8333C20.6667 15.3731 20.2936 15 19.8333 15C19.3731 15 19 15.3731 19 15.8333C19 16.2935 19.3731 16.6666 19.8333 16.6666Z"
        fill="#A9A29D"
      />
      <path
        d="M8.16666 4.99998C8.6269 4.99998 8.99999 4.62688 8.99999 4.16665C8.99999 3.70641 8.6269 3.33331 8.16666 3.33331C7.70642 3.33331 7.33333 3.70641 7.33333 4.16665C7.33333 4.62688 7.70642 4.99998 8.16666 4.99998Z"
        fill="#A9A29D"
      />
      <path
        d="M8.16666 10.8333C8.6269 10.8333 8.99999 10.4602 8.99999 9.99998C8.99999 9.53974 8.6269 9.16665 8.16666 9.16665C7.70642 9.16665 7.33333 9.53974 7.33333 9.99998C7.33333 10.4602 7.70642 10.8333 8.16666 10.8333Z"
        fill="#A9A29D"
      />
      <path
        d="M8.16666 16.6666C8.6269 16.6666 8.99999 16.2935 8.99999 15.8333C8.99999 15.3731 8.6269 15 8.16666 15C7.70642 15 7.33333 15.3731 7.33333 15.8333C7.33333 16.2935 7.70642 16.6666 8.16666 16.6666Z"
        fill="#A9A29D"
      />
      <path
        d="M14 4.99998C14.4602 4.99998 14.8333 4.62688 14.8333 4.16665C14.8333 3.70641 14.4602 3.33331 14 3.33331C13.5398 3.33331 13.1667 3.70641 13.1667 4.16665C13.1667 4.62688 13.5398 4.99998 14 4.99998Z"
        stroke="#A9A29D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 10.8333C14.4602 10.8333 14.8333 10.4602 14.8333 9.99998C14.8333 9.53974 14.4602 9.16665 14 9.16665C13.5398 9.16665 13.1667 9.53974 13.1667 9.99998C13.1667 10.4602 13.5398 10.8333 14 10.8333Z"
        stroke="#A9A29D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 16.6666C14.4602 16.6666 14.8333 16.2935 14.8333 15.8333C14.8333 15.3731 14.4602 15 14 15C13.5398 15 13.1667 15.3731 13.1667 15.8333C13.1667 16.2935 13.5398 16.6666 14 16.6666Z"
        stroke="#A9A29D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8333 4.99998C20.2936 4.99998 20.6667 4.62688 20.6667 4.16665C20.6667 3.70641 20.2936 3.33331 19.8333 3.33331C19.3731 3.33331 19 3.70641 19 4.16665C19 4.62688 19.3731 4.99998 19.8333 4.99998Z"
        stroke="#A9A29D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8333 10.8333C20.2936 10.8333 20.6667 10.4602 20.6667 9.99998C20.6667 9.53974 20.2936 9.16665 19.8333 9.16665C19.3731 9.16665 19 9.53974 19 9.99998C19 10.4602 19.3731 10.8333 19.8333 10.8333Z"
        stroke="#A9A29D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8333 16.6666C20.2936 16.6666 20.6667 16.2935 20.6667 15.8333C20.6667 15.3731 20.2936 15 19.8333 15C19.3731 15 19 15.3731 19 15.8333C19 16.2935 19.3731 16.6666 19.8333 16.6666Z"
        stroke="#A9A29D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.16666 4.99998C8.6269 4.99998 8.99999 4.62688 8.99999 4.16665C8.99999 3.70641 8.6269 3.33331 8.16666 3.33331C7.70642 3.33331 7.33333 3.70641 7.33333 4.16665C7.33333 4.62688 7.70642 4.99998 8.16666 4.99998Z"
        stroke="#A9A29D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.16666 10.8333C8.6269 10.8333 8.99999 10.4602 8.99999 9.99998C8.99999 9.53974 8.6269 9.16665 8.16666 9.16665C7.70642 9.16665 7.33333 9.53974 7.33333 9.99998C7.33333 10.4602 7.70642 10.8333 8.16666 10.8333Z"
        stroke="#A9A29D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.16666 16.6666C8.6269 16.6666 8.99999 16.2935 8.99999 15.8333C8.99999 15.3731 8.6269 15 8.16666 15C7.70642 15 7.33333 15.3731 7.33333 15.8333C7.33333 16.2935 7.70642 16.6666 8.16666 16.6666Z"
        stroke="#A9A29D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ShareIcon = (props) => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.59 12.51L13.42 16.49M13.41 5.51L6.59 9.49M19 4C19 5.65685 17.6569 7 16 7C14.3431 7 13 5.65685 13 4C13 2.34315 14.3431 1 16 1C17.6569 1 19 2.34315 19 4ZM7 11C7 12.6569 5.65685 14 4 14C2.34315 14 1 12.6569 1 11C1 9.34315 2.34315 8 4 8C5.65685 8 7 9.34315 7 11ZM19 18C19 19.6569 17.6569 21 16 21C14.3431 21 13 19.6569 13 18C13 16.3431 14.3431 15 16 15C17.6569 15 19 16.3431 19 18Z"
        stroke="#586068"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const HintAlertIcon = (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00004 0.583984C3.45621 0.583984 0.583374 3.45682 0.583374 7.00065C0.583374 10.5445 3.45621 13.4173 7.00004 13.4173C10.5439 13.4173 13.4167 10.5445 13.4167 7.00065C13.4167 3.45682 10.5439 0.583984 7.00004 0.583984ZM7.58337 4.66732C7.58337 4.34515 7.32221 4.08398 7.00004 4.08398C6.67787 4.08398 6.41671 4.34515 6.41671 4.66732V7.00065C6.41671 7.32282 6.67787 7.58398 7.00004 7.58398C7.32221 7.58398 7.58337 7.32282 7.58337 7.00065V4.66732ZM7.00004 8.75065C6.67787 8.75065 6.41671 9.01182 6.41671 9.33398C6.41671 9.65615 6.67787 9.91732 7.00004 9.91732H7.00587C7.32804 9.91732 7.58921 9.65615 7.58921 9.33398C7.58921 9.01182 7.32804 8.75065 7.00587 8.75065H7.00004Z"
        fill="#A9A29D"
      />
    </svg>
  );
};

export const ShieldAlertIcon = (props) => {
  return (
    <svg
      width={props.width ? props.width : '14'}
      height={props.height ? props.height : '14'}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.25001 6.70805L6.41668 7.87472L9.04168 5.24972M11.6667 6.99972C11.6667 9.86298 8.54353 11.9455 7.40717 12.6084C7.27803 12.6837 7.21345 12.7214 7.12232 12.741C7.0516 12.7561 6.94842 12.7561 6.8777 12.741C6.78657 12.7214 6.72199 12.6837 6.59285 12.6084C5.45649 11.9455 2.33334 9.86298 2.33334 6.99972V4.20999C2.33334 3.74361 2.33334 3.51042 2.40962 3.30997C2.477 3.13289 2.5865 2.97488 2.72864 2.84961C2.88955 2.70781 3.10789 2.62593 3.54458 2.46217L6.6723 1.28928C6.79357 1.2438 6.8542 1.22107 6.91658 1.21205C6.97191 1.20406 7.02811 1.20406 7.08344 1.21205C7.14582 1.22107 7.20645 1.2438 7.32773 1.28928L10.4554 2.46217C10.8921 2.62593 11.1105 2.70781 11.2714 2.84961C11.4135 2.97488 11.523 3.13289 11.5904 3.30997C11.6667 3.51042 11.6667 3.74361 11.6667 4.20999V6.99972Z"
        stroke={props.stroke ? props.stroke : '#A9A29D'}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

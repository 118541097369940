export const CREATE_ASSET_REQUEST = 'CREATE_ASSET_REQUEST';
export const CREATE_ASSET_SUCCESS = 'CREATE_ASSET_SUCCESS';
export const CREATE_ASSET_ERROR = 'CREATE_ASSET_ERROR';

export const GET_ASSET_REQUEST = 'GET_ASSET_REQUEST';
export const GET_ASSET_SUCCESS = 'GET_ASSET_SUCCESS';
export const GET_ASSET_ERROR = 'GET_ASSET_ERROR';

export const DOWNLOAD_ASSET_REQUEST = 'DOWNLOAD_ASSET_REQUEST';
export const DOWNLOAD_ASSET_SUCCESS = 'DOWNLOAD_ASSET_SUCCESS';
export const DOWNLOAD_ASSET_ERROR = 'DOWNLOAD_ASSET_ERROR';

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';

export const RESET_BLOCK_ASSET = 'RESET_BLOCK_ASSET';
export const RESET_FLAGS_ASSET = 'RESET_FLAGS_ASSET';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getAsset: { ...block },
  createAsset: { ...block },
  downloadAsset: { ...block },
  uploadFiles: { ...block },
};

export const AssetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSET_REQUEST:
      return { ...state, getAsset: { ...state.getAsset, loading: true } };
    case GET_ASSET_SUCCESS:
      return {
        ...state,
        getAsset: {
          ...state.getAsset,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_ASSET_ERROR:
      return {
        ...state,
        getAsset: { ...state.getAsset, loading: false, error: action.error },
      };

    case DOWNLOAD_ASSET_REQUEST:
      return { ...state, downloadAsset: { ...state.downloadAsset, loading: true } };
    case DOWNLOAD_ASSET_SUCCESS:
      return {
        ...state,
        downloadAsset: {
          ...state.downloadAsset,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case DOWNLOAD_ASSET_ERROR:
      return {
        ...state,
        downloadAsset: { ...state.downloadAsset, loading: false, error: action.error },
      };

    case CREATE_ASSET_REQUEST:
      return { ...state, createAsset: { ...state.createAsset, loading: true } };
    case CREATE_ASSET_SUCCESS:
      return {
        ...state,
        createAsset: {
          ...state.createAsset,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CREATE_ASSET_ERROR:
      return {
        ...state,
        createAsset: { ...state.createAsset, loading: false, error: action.error },
      };

    case UPLOAD_FILE_REQUEST:
      return { ...state, uploadFiles: { ...state.uploadFiles, loading: true } };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        uploadFiles: {
          ...state.uploadFiles,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case UPLOAD_FILE_ERROR:
      return {
        ...state,
        uploadFiles: { ...state.uploadFiles, loading: false, error: action.error },
      };

    //reset block with flag and data
    case RESET_BLOCK_ASSET:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };
    //reset only flags(block)
    case RESET_FLAGS_ASSET:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};

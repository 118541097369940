import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  TOGGLE_REQUEST, TOGGLE_SUCCESS,
} from 'redux/reducers/ToggleReducer';

function* toggle() {
  yield put({
    type: TOGGLE_SUCCESS,
  });
}

export default all([takeEvery(TOGGLE_REQUEST, toggle)]);
